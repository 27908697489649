import React from 'react';
import { RouteObject } from 'react-router-dom';
import withSuspense from 'utils/withSuspense'; // Ensure this file exists and works

const LoginPage = React.lazy(() => import('pages/LoginPage/LoginPage'));
const HomePageBlank = React.lazy(() => import('pages/HomePage/HomePageBlank'));
const ContactPage = React.lazy(() => import('pages/ContactPage/ContactPage'));
const JoinUsPage = React.lazy(() => import('pages/JoinUsPage/JoinUsPage'));
const RegisterPage = React.lazy(() => import('pages/Register/RegisterPage'));
const Membership = React.lazy(() => import('pages/MembershipPage/Membership'));
const SubscriptionPage = React.lazy(() => import('pages/SubscriptionPage/SubscriptionPage'));
const AboutPage = React.lazy(() => import('pages/AboutPage/AboutPage'));
const TermConditionPage = React.lazy(() => import('pages/TermConditionPage/TermConditionPage'));
const FrequenceQuestionPage = React.lazy(() => import('pages/FrequenceQuestionPage/FrequenceQuestionPage'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicyPage/PrivacyPolicy'));
const PowerPurchaseAgreementPublicPage = React.lazy(() => import('pages/PowerPurchaseAgreementPublicPage/PowerPurchaseAgreementPublicPage'));
const PowerPurchaseAgreementPublicSuccessPage = React.lazy(() => import('pages/PowerPurchaseAgreementPublicPage/PowerPurchaseAgreementPublicSuccessPage'));
const AfpRegisterPage = React.lazy(() => import('pages/afp/register/AfpRegisterPage'));
const AfpSuccessPage = React.lazy(() => import('pages/afp/register/AfpSuccessPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage/DashboardPage'));
const ErrorPage = React.lazy(() => import('pages/ErrorPage/ErrorPage'));

// Route configuration
export const unprotectedRoutes: RouteObject[] = [
    {
      path: '/login',
      element: withSuspense(LoginPage),
    },
    {
      path: '/',
      element: withSuspense(HomePageBlank),
    },
    {
      path: '/contact',
      element: withSuspense(ContactPage),
    },
    {
      path: '/launch',
      element: withSuspense(JoinUsPage),
    },
    {
      path: '/register',
      element: withSuspense(RegisterPage),
    },
    {
      path: '/membership',
      element: withSuspense(Membership),
    },
    {
      path: '/subscription',
      element: withSuspense(SubscriptionPage),
    },
    {
      path: '/about',
      element: withSuspense(AboutPage),
    },
    {
      path: '/term_condition',
      element: withSuspense(TermConditionPage),
    },
    {
      path: '/frequence_question',
      element: withSuspense(FrequenceQuestionPage),
    },
    {
      path: '/privacy_policy',
      element: withSuspense(PrivacyPolicy),
    },
    {
      path: '/ppa',
      element: withSuspense(PowerPurchaseAgreementPublicPage),
    },
    {
      path: '/ppa/success',
      element: withSuspense(PowerPurchaseAgreementPublicSuccessPage),
    },
    {
      path: '/afp/register',
      element: withSuspense(AfpRegisterPage),
    },
    {
      path: '/afp/register/success',
      element: withSuspense(AfpSuccessPage),
    },
  ];
  

// Protected routes (authentication required)
export const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: withSuspense(DashboardPage),
  },
];

// Fallback route for unmatched paths
export const fallbackRoute: RouteObject = {
  path: '*',
  element: withSuspense(ErrorPage),
};
