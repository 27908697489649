import UserContext from "Context/UserProvider";
import { ElectricVehicle } from "interfaces/ElectricVehicle";
import { useContext, useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import Select from "react-select";

function ElectricVehicleEditPage(props: any) {

    const defaultElectricVehicle: ElectricVehicle = {
        uuid: null, // Ideally, generate a UUID here or leave it empty if it's generated elsewhere
        registrationNumber: null, // Provide a default registration number or leave it empty
        vin: null, // Provide a default VIN or leave it empty
        username: null,
        password: null,
        ownershipStatus: "FLEET"
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);
    const [electricVehicle, setElectricVehicle] = useState<ElectricVehicle>(defaultElectricVehicle);
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);
    const [ownershipStatusValue, setOwnershipStatusValue] = useState({ label: "FLEET", value: "FLEET", name: "ownershipStatus" }) as any;

    const ownershipStatusOptions = [
        { value: 'FLEET', label: 'FLEET', name: "ownershipStatus" },
        { value: 'RENTAL', label: 'RENTAL', name: "ownershipStatus" },
    ];

    useEffect(() => {
        setElectricVehicle(defaultElectricVehicle);
        setLoading(false);
    }, []);

    function onChange(e: any) {

        if (e.name === "ownershipStatus") {

            const selectedOwnershipStatus = ownershipStatusOptions.find((i: any) => i.value === e.value);
            if (selectedOwnershipStatus != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                setOwnershipStatusValue(selectedOwnershipStatus);
            }
        } else if (e.target.name === "registrationNumber") {
            electricVehicle.registrationNumber = e.target.value;
        } else if (e.target.name === "vin") {
            electricVehicle.vin = e.target.value;
        } else if (e.target.name === "username") {
            electricVehicle.username = e.target.value;
        } else if (e.target.name === "password") {
            electricVehicle.password = e.target.value;
        }
    }


    async function updateElectricVehicle() {

        electricVehicle.ownershipStatus = ownershipStatusValue.value;

        console.log(ownershipStatusValue);

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        let chargingPartner: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["electricVehicle"] = electricVehicle;
        bodyData["businessUuid"] = userInfo.businessUuid;

        let fetch_link = `${rootUrl}/fleet/electric-vehicle/save`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {
            setIsDisabled(false);
            toast.success('Electric Vehicle Saved', {
                position: 'bottom-center',
                duration: 5000,
            });

            navigate("/share/business/electric-vehicles");

        }
    }

    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">local_shipping</span>
                                    Vehicle Details
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Vehicle Registration Number :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <input
                                                ref={currentInput}
                                                type="text"
                                                style={{ textAlign: "left", width: 120 }}
                                                className="textbox"
                                                defaultValue={electricVehicle.registrationNumber ?? ''}
                                                name="registrationNumber"
                                                id="registrationNumber"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            VIN :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <input
                                                ref={currentInput}
                                                type="text"
                                                style={{ textAlign: "left", width: 120 }}
                                                className="textbox"
                                                defaultValue={electricVehicle.vin ?? ''}
                                                name="vin"
                                                id="vin"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">signature</span>
                                    Authorisation
                                </div>
                                <div
                                    className="dashboard-card-content"
                                >
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Username :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <input
                                                ref={currentInput}
                                                type="text"
                                                style={{ textAlign: "left", width: 120 }}
                                                className="textbox"
                                                defaultValue={electricVehicle.username ?? ''}
                                                name="username"
                                                id="username"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="dashboard-card-content"
                                >
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Password :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <input
                                                ref={currentInput}
                                                type="text"
                                                style={{ textAlign: "left", width: 120 }}
                                                className="textbox"
                                                defaultValue={electricVehicle.password ?? ''}

                                                name="password"
                                                id="password"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card" >
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">car_rental</span>
                                    Ownership
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Ownership Status :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "246px" }}>
                                            <Select
                                                value={ownershipStatusValue}
                                                name="ownershipStatus"
                                                onChange={onChange}
                                                options={ownershipStatusOptions}
                                                styles={{ container: base => ({ ...base, width: "100%" }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-xs-12 col-md-12 col-xl-12">
                            <div className="">

                                <div
                                    className=""
                                    style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                    <button
                                        className="primary-btn"
                                        disabled={isDisabled}
                                        onClick={updateElectricVehicle}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default ElectricVehicleEditPage;