import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

function ChargingInfrastructureBarChart(props: any) {
  const data = props.data ? props.data : [];
  return (
    <div
      className="multi-color-container"
      style={{ height: 300 }}>
      {/* <h2 style={{ fontSize: 25, fontWeight: "bold" }}>Battery Usage Profile</h2> */}
      <ResponsiveContainer
        width={"100%"}
        height={"100%"}>
        <BarChart
          data={data}
          stackOffset="sign"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          {<XAxis
            axisLine={true}
            tickLine={false}
            tick={false}

          />}
          {<YAxis
            tick={true}
            ticks={props.nums[0]}
            
            />}
          <Tooltip />
          <Bar
            // className={"bar-skelton"}
            dataKey="aChargingRate"
            stackId="a"
            fill="rgb(100, 242, 19)"
          />
          <Bar
            // className={"bar-skelton"}
            dataKey="bChargingRate"
            stackId="a"
            fill="rgb(190, 242, 19)"
          />
          {/* <Bar dataKey="startingBatteryLevel" stackId="a" fill="#C1D1D6" /> */}
          {/* <Legend iconType="circle" formatter={(value, entry, index) => {
                        switch (value) {
                            case "homeCharge":
                                return "Home Charge"
                            case "endOfDayCharge":
                                return "Remaining Charge Level"
                        }
                    }} /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChargingInfrastructureBarChart;
