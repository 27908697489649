import DashboardCardItem from "../common/DashboardCardItem";
import DashboardCardTitle from "../common/DashboardCardTitle";
function AccountInformation(props: any) {

    const dashboardInfo = props.dashboardInfo;

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <DashboardCardTitle title="Account Information" />
                <div className="dashboard-card-content">
                    <DashboardCardItem itemTitle="Created :" itemValue={dashboardInfo.creationDate} />
                    <DashboardCardItem itemTitle="Number of Fleets :" itemValue={dashboardInfo.totalFleets} />
                    <DashboardCardItem itemTitle="Number of Vehicles :" itemValue={dashboardInfo.totalVehicles} />
                    <DashboardCardItem itemTitle="Last Accessed :" itemValue={dashboardInfo.lastAccessedDate} />

                </div>
                <div className="dashboard-card-actions">
                    {/* 
                    <div className="icon-and-text" >
                        <span className="icon material-symbols-outlined">person_add</span>Add People
                    </div>
                    <div className="icon-and-text has-left-border" onClick={() => navigate('/plan/add-people')}>
                        <span className="icon material-symbols-outlined">savings</span>Add Credit
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default AccountInformation;
