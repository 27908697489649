import { Button } from '@mui/material';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import UserContext from "Context/UserProvider";
import React, { useContext, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from "react-router";

interface DataRow {
    id: string; // or string, depending on your data
    registrationNumber: string;
    make: string;
    model: string;
    combinedRange: string;
    uuid: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    agreements: DataRow[];
    openTrade: (currentBalanceInKWh: number, endDate: string, uuid: string) => void;  // Add the openTrade method
    // displayVin: boolean;
    // displayRegLink: boolean;
    // makeModel: boolean;
}

const AgreementsTable: React.FC<DataGridChildProps> = ({ agreements, openTrade }) => {

    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const location = useLocation() as any;
    const [enableButton, setEnableButton] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [totalMonthlyPublicKWh, setTotalMonthlyPublicKWh] = useState<number>();
    const breadcrumbs = ['Vehicles', 'Home Locations', 'Summary', 'Quotes'];
    const activeIndex = 3; // Change this index to set the active step
    const { userInfo, setUserInfo } = useContext(UserContext);

    const columns: GridColDef[] = [

        {
            field: 'logo',
            headerName: 'Charge Point Operator',
            flex: 1, // Dynamically allocate width
            minWidth: 150, // Ensure a minimum width
            renderCell: (params) => (
                <img
                    src={params.row.chargePointOperator.logoUrl}
                    alt="Operator Logo"
                    style={{ width: 100, height: 40, objectFit: 'contain' }}
                    onClick={() =>
                        navigate("/agree/ppa/detail", {
                            state: {
                                ppaUuid: params.row.uuid,
                                rateCardUuid: params.row.rateCardUuid,
                            },
                        })
                    }>
                </img>
            ),
        },
        {
            field: "rateCardName",
            headerName: "Rate Card",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "right",
        },
        {
            field: "startDate",
            headerName: "Start Date",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "right",
        }, {
            field: "currentBalanceInGbp",
            headerName: "Current Balance",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) => {
                // Assuming the price is in a format that can be converted to a number
                const energyCostDcGbpPerKWhPeak = params;
                // Format the price as currency, e.g., '$123.45'
                // Adjust 'en-US' and 'USD' to fit the locale and currency you need
                return new Intl.NumberFormat('en-UK', {
                    style: 'currency',
                    currency: 'GBP',
                }).format(energyCostDcGbpPerKWhPeak);
            }
        },
        {
            field: "currentBalanceInKWh",
            headerName: "Remaining kW/h",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: number) => {
                const value = params;  // Directly use params since it holds the value
                return value !== undefined && value !== null
                    ? `${value.toFixed(2)} kW/h`  // Format to 2 decimal places
                    : "0.00 kW/h";  // Default if value is null or undefined
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                params.row.rateCardName !== null ? (
                    // <Button variant="contained" color="primary" onClick={() => { openTrade(params.row.chargePointOperatorUuid, params.row.rateCardUuid, params.row.rowId) }}>
                    //     Trade
                    // </Button>
                    <Button variant="contained" color="primary" onClick={() => { openTrade(params.row.currentBalanceInKWh, params.row.endDate, params.row.uuid) }}>
                        Trade
                    </Button>) :
                    <Button variant="contained" color="secondary">Request</Button>
            ),
        },

    ];

    return (
        <>
            <div className="table-box">
                {/* <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                    <>
                        <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IoMdClose className="close-btn" fontSize={30} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>

                            <UpdateSurveyPage currentSelected={currentSelected} fetchEmails={fetchEmails} />

                        </div>
                    </>

                </ModalComponent> */}

                <DataGrid
                    rows={agreements}
                    autoHeight
                    {...agreements}
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}></DataGrid>
            </div>
        </>
    );
}

export default AgreementsTable;
