import {
    isChargePointOperator,
    hasSharedInfrastructure
} from "utils/categoryUtils"

const fleetMenu = [{
        title: "Ice Vehicles",
        url: "/plan/scenario/configuration/ice-vehicles",
    },
    {
        title: "Electric Vehicles",
        url: "/plan/scenario/configuration/ev-vehicles",
    },
    {
        title: "Settings",
        url: "/plan/scenario/configuration/parameters",
    },
    {
        title: "Run Scenario",
        url: "/plan/scenario/configuration/run"
    },
]

const ctaButton = [{
        title: "Add Location",
        url: "business/locations/add-location",
    },
    {
        title: "Export Locations",
        action: true,
        url: "upload",
    },
    {
        title: "Import Locations",
        url: "business/locations/import-locations",
    },
]



export function getBusinessMenuOptions(mode, businessCategory) {

    console.log("MM " + mode + " "+ businessCategory);

    if (mode === 'PLAN') {
        return [{
                title: "Locations",
                url: "/plan/business/locations",
                icon: "pin_drop",

            },
            {
                title: "User Surveys",
                url: "/plan/business/surveys",
                icon: "sentiment_satisfied",
            },
            {
                title: "Operational Cost Models",
                url: "/plan/insight/operational-cost-model-summary",
                icon: "summarize",
            },
            {
                title: "Business Insight",
                url: "/plan/business/insight",
                icon: "search_insights",

            },
        ]
    } else if (mode === 'SHARE') {
        if (hasSharedInfrastructure(businessCategory)) {
            return [{
                    title: "Charging Network",
                    url: "/share/business/charging-partners/map",
                    pcn: true,
                    icon: "hive",
                },
                {
                    title: "Charging Infrastructure",
                    url: "/share/business/charging-infrastructure/dwellings",
                    pcn: true,
                    icon: "ev_station",
                }
            ]
        } else {
            return [{
                    title: "Charging Network",
                    url: "/share/business/charging-partners/map",
                    pcn: true,
                    icon: "hive",
                },
                {
                    title: "Electric Vehicles",
                    url: "/share/business/electric-vehicles",
                    pcn: true,
                    icon: "local_shipping",
                }
            ]
        }

    } else if (mode === 'AGREE' && !isChargePointOperator(businessCategory)) {
        console.log("MA " + mode + " "+ businessCategory);
        return [
            
            {
                title: "Build PPA Quote",
                url: "/agree/ppa/quote/build/vehicles",
                pcn: true,
            },
            {
                title: "Agreements",
                url: "/agree/ppa/list",
                pcn: true,
            },
            {
                title: "Trades",
                url: "/agree/ppa/trade/list",
                pcn: true,
            }
        ]
    } else if (mode === 'CHARGE' && isChargePointOperator(businessCategory)) {
        console.log("MC " + mode + " "+ businessCategory);
        return [{
                title: "PPA Rate Cards",
                url: "/charge/charge-point-operator/rate-cards",
                pcn: true,
            },
            {
                title: "PPA Businesses",
                url: "/charge/charge-point-operator/ppa/businesses",
                pcn: true,
            }

        ]
    }
    return []; 
}

export function getFleetMenuOptions() {

    return [{
            title: "Ice Vehicles",
            url: "/plan/scenario/configuration/ice-vehicles",
        },
        {
            title: "Electric Vehicles",
            url: "/plan/scenario/configuration/ev-vehicles",
        },
        {
            title: "Settings",
            url: "/plan/scenario/configuration/parameters",
        },
        {
            title: "Run Scenario",
            url: "/plan/scenario/configuration/run"
        },
    ]

}