import { useContext, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AreaChart from "../../../components/fleet/JourneyInsightComponent/AreaChart";
import MapWithRoute from "../../../components/fleet/JourneyInsightComponent/MapWithRoute";
import VerticalStackedChart from "../../../components/fleet/JourneyInsightComponent/VerticalStackedChart";
import UserContext from "../../../Context/UserProvider";

function BuildQuoteJourneyInsightPage(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const {userInfo, setUserInfo} = useContext(UserContext);
  const params = location.state as any;
  const [selectedJourney, setSelectedJourney] = useState() as any;
  const [selectedDistance, setSelectedDistance] = useState() as any;
  const [loading, setLoading] = useState(true);
  const [dailyBatteryUsage, setDailyBatteryUsage] = useState() as any;
  const [batteryUsageMap, setBatteryUsageMap] = useState() as any;
  const [mapPoints, setMapPoints] = useState() as any;
  
  async function fetchInsight() {
    setLoading(true);
    let bodyData: { [name: string]: string } = {};
    //Variable for fetch request
    let userID = userInfo.uuid;
    let fleetUuid = userInfo.fleetUuid;
    bodyData["uuid"] = userID;
    bodyData["fleetUuid"] = fleetUuid;
    bodyData["comparisonUuid"] = location.state.comparisonUuid;

    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/journey/insight/get`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };

    let response = await fetch(fetch_link, fetch_option);
    let res_json = await response.json();
    setDailyBatteryUsage(
      res_json.dailyBatteryUsageList.map((prev: any) => ({
        ...prev,
        startingBatteryLevel: +"1",
      }))
    );
    setBatteryUsageMap(res_json.batteryUsageMap);
    setMapPoints(res_json.mapPointsMap);
    setSelectedJourney(res_json.dailyBatteryUsageList[0].date);
    setSelectedDistance(res_json.dailyBatteryUsageList[0].distance);
    setLoading(false);
  }
  useLayoutEffect(() => {
    fetchInsight();
  }, []);
  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <div className="d-flex flex-column gap-3">
           <VerticalStackedChart
            data={dailyBatteryUsage}
            selectedJourney={[selectedJourney, setSelectedJourney]}
            selectedDistance={[selectedDistance, setSelectedDistance]}

          />
          {/* <h1>Daily Battery Usage for Journey</h1> */}
          <div className="d-flex flex-column flex-lg-row gap-3">
            <AreaChart
              data={batteryUsageMap}
              selectedJourney={selectedJourney}
            />
            {/* <h1>Journey Details</h1> */}
            <MapWithRoute
              data={mapPoints}
              selectedJourney={selectedJourney}
              selectedDistance={selectedDistance}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default BuildQuoteJourneyInsightPage;
