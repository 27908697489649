import { useNavigate } from "react-router";

function PartnerChargingNetwork(props: any) {

    const dashboardInfo = props.dashboardInfo;

    const navigate = useNavigate();

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">hive</span> Charging Network
                </div>
                <div className="dashboard-card-content">
                    <div className="box-info">
                        <span className="item">Account Balance :</span>
                        <span className="data">
                            £{dashboardInfo.currentBalance?.toFixed(2)}
                        </span>
                    </div>
                </div>
                <div className="dashboard-card-content">
                    <div className="box-info">
                        <span className="item">Partners in Network :</span>
                        <span className="data">
                            {dashboardInfo.businessesInPCN}
                        </span>
                    </div>
                    <div className="box-info">
                        <span className="item">Charge Points in Network :</span>
                        <span className="data">
                            {dashboardInfo.countChargePointsInNetwork}
                        </span>
                    </div>                   <div className="box-info">
                        <span className="item">Partner Since :</span>
                        <span className="data">
                            {dashboardInfo.partnerChargingNetworkSubscriptionSince}
                        </span>
                    </div>
                    <div className="box-info">
                        <span className="item">Business Code :</span>
                        <span className="data">
                            {dashboardInfo.businessCode}
                        </span>
                    </div>
                </div>
                <div className="dashboard-card-actions">
                    <div
                        className="icon-and-text"
                        onClick={() => navigate("/share/business/charging-partners/map")}>
                        <span className="icon material-symbols-outlined">hive</span>
                        View Network Map
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PartnerChargingNetwork;