import { Backdrop, CircularProgress } from '@mui/material';
import AgreementsTable from 'components/ppa/AgreementsTable';
import TradePopup from 'components/ppa/TradePopup';
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';

function PPAListPage(props: any) {
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [agreements, setAgreements] = useState([]) as any;
    const [isDisabled] = useState<boolean>(false);
    const [isTradePopupOpen, setIsTradePopupOpen] = useState(false);  // Controls TradePopup visibility
    const [currentBalanceInKWh, setCurrentBalanceInKWh] = useState(0);
    const [endDate, setEndDate] = useState('');
    const [currentPPA, setCurrentPPA] = useState('');
    // Method to open the TradePopup
    const openTrade = (balance: number, endDate: string, uuid: string) => {
        setCurrentBalanceInKWh(balance);
        setEndDate(endDate);
        setCurrentPPA(uuid);
        setIsTradePopupOpen(true);
    };

    // Method to close the TradePopup
    const closeTradePopup = () => {
        setIsTradePopupOpen(false);
    };
    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    async function fetchAgreements() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/ppa/advanced/agreement/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        res_json.agreements = checking_null_undefined(res_json.agreements);

        setAgreements(res_json.agreements.map((e: any) => ({ ...e, id: e.uuid })));

        console.log("AGGRES " + JSON.stringify(res_json.agreements));

        setLoading(false);
    }

    async function requestTrade(amountKWh: number) {

        setLoading(true);

        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["powerPurchaseAgreementUuid"] = currentPPA;
        bodyData["amountKWh"] = amountKWh.toString();

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/ppa/advanced/trade/create`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            toast.error('Unable to register Trade at this time.', {
                position: 'bottom-center',
                duration: 5000,
            });
            return;
        } else {
            toast.success('Trade register successful', {
                position: 'bottom-center',
                duration: 5000,
            });
            fetchAgreements();
        }

        setIsTradePopupOpen(false);

        setLoading(false);
    }

    useEffect(() => {
        fetchAgreements();
    }, []);

    return (
        <div>
            <TradePopup open={isTradePopupOpen} handleClose={closeTradePopup} handleContinue={requestTrade} currentBalanceInKWh={currentBalanceInKWh} endDate={endDate}/>

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (

                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">
                        <div className="drag-drop-container">
                            <AgreementsTable agreements={agreements} openTrade={openTrade} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default PPAListPage;