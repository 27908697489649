import { Backdrop, CircularProgress } from '@mui/material';
import UserContext from "Context/UserProvider";
import { reservationOptions } from "components/charging/BusinessChargingNetworkParameters/reservationOptions";
import ModalComponent from "components/common/Modal/Modal";
import { useContext, useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import {
    hasSharedInfrastructure
} from "utils/categoryUtils";
import WeeklyChargingReservation from "../../components/charging/BusinessChargingNetworkParameters/WeeklyChargerReservation";
import { fromHourValues } from "../../components/charging/BusinessChargingNetworkParameters/fromHourValues";
import { toHourValues } from "../../components/charging/BusinessChargingNetworkParameters/toHourValues";
import { weekDays as initialWeekDays, WeekDay } from "../../components/charging/BusinessChargingNetworkParameters/weekDays";
function BusinessChargingNetworkParametersPage(props: any) {

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [parameter, setParameter] = useState({}) as any;
    const location = useLocation();
    const params = location.state as any;
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);
    const [fromHour, setFromHour] = useState({ label: "00", value: 0, name: "fromHour" }) as any;
    const [toHour, setToHour] = useState({ label: "00", value: 0, name: "toHour" }) as any;
    const [subscribedValue, setSubscribedValue] = useState({ label: "No", value: false, name: "subscribed" }) as any;
    const [siteAccessRestrictionValue, setSiteAccessRestrictionValue] = useState({ label: "No", value: false, name: "siteAccessRestriction" }) as any;
    const subscribtion = [
        { label: "Yes", value: true, name: "subscribed" },
        { label: "No", value: false, name: "subscribed" },
    ];
    const siteAccessRestriction = [
        { label: "Yes", value: true, name: "siteAccessRestriction" },
        { label: "No", value: false, name: "siteAccessRestriction" },
    ];

    const [siteAccessPolicyOptions, setSiteAccessPolicyOptions] = useState({ label: "None", value: "NONE", name: "siteAccessPolicyField" }) as any;
    const [siteAccessPolicyValue, setSiteAccessPolicyValue] = useState({}) as any;

    const [frequencyOptions, setFrequencyOptions] = useState({ label: "None", value: "NONE", name: "frequencyField" }) as any;
    const [frequencyValue, setFrequencyValue] = useState({}) as any;

    const [businessCategoryOptions, setBusinessCategoryOptions] = useState({ label: "None", value: "NONE", name: "businessCategoryField" }) as any;
    const [businessCategoryValue, setBusinessCategoryValue] = useState({}) as any;


    const [reservation, setReservation] = useState({ label: "Default", value: "DEFAULT", name: "reservationOptionField" }) as any;
    const [weekDays, setWeekDays] = useState(initialWeekDays);

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalIsOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }


    const handleTimeChange = (dayIndex: any, field: any, value: any) => {
        setWeekDays(prevDays =>
            prevDays.map((day, index) =>
                index === dayIndex
                    ? { ...day, [field]: value }
                    : day
            )
        );
    };

    useEffect(() => {
        fetchBusiness();
    }, []);

    function onChange(e: any) {

        if (e.name === "fromHour") {
            setFromHour(fromHourValues.filter((i: any) => i.value === e.value)[0]);
        } else if (e.name === "toHour") {
            setToHour(toHourValues.filter((i: any) => i.value === e.value)[0]);
        } else if (e.name === "subscribed") {
            setSubscribedValue(subscribtion.filter((i: any) => i.value === e.value)[0]);
        } else if (e.name === "reservationOptionField") {
            setReservation(reservationOptions.filter((i: any) => i.value === e.value)[0]);
        } else if (e.name === "siteAccessRestriction") {
            setSiteAccessRestrictionValue(siteAccessRestriction.filter((i: any) => i.value === e.value)[0]);
        } else if (e.name === "siteAccessPolicy") {


            const selectedSurvey = siteAccessPolicyOptions.find((i: any) => i.uuid === e.uuid);

            if (selectedSurvey != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedSurvey.name, // assuming it has a 'name' field for the label
                    value: selectedSurvey.uuid, // the unique identifier, used as value
                    ...selectedSurvey, // spread the rest of the object properties if there are any others
                };
                setSiteAccessPolicyValue(selectedSurvey);

            }

        } else if (e.name === "frequency") {


            const selectedFrequency = frequencyOptions.find((i: any) => i === e);

            if (selectedFrequency != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedFrequency, // assuming it has a 'name' field for the label
                    value: selectedFrequency, // the unique identifier, used as value
                    ...selectedFrequency, // spread the rest of the object properties if there are any others
                };
                setFrequencyValue(selectedFrequency);

            }

        } else if (e.name === "businessCategory") {

            const selectedBusinessCategory = businessCategoryOptions.find((i: any) => i === e);

            if (selectedBusinessCategory != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedBusinessCategory.name, // assuming it has a 'name' field for the label
                    value: selectedBusinessCategory.name, // the unique identifier, used as value
                    ...selectedBusinessCategory, // spread the rest of the object properties if there are any others
                };
                setBusinessCategoryValue(selectedValue);

            }


        } else if (e.target.name === "energyCostAcSinglePhaseGbpPerKWhOffPeak") {
            parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak = e.target.value;
        } else if (e.target.name === "energyCostAcThreePhaseGbpPerKWhOffPeak") {
            parameter.energyCostAcThreePhaseGbpPerKWhOffPeak = e.target.value;
        } else if (e.target.name === "energyCostDcGbpPerKWhOffPeak") {
            parameter.energyCostDcGbpPerKWhOffPeak = e.target.value;
        } else if (e.target.name === "energyCostAcSinglePhaseGbpPerKWhPeak") {
            parameter.energyCostAcSinglePhaseGbpPerKWhPeak = e.target.value;
        } else if (e.target.name === "energyCostAcThreePhaseGbpPerKWhPeak") {
            parameter.energyCostAcThreePhaseGbpPerKWhPeak = e.target.value;
        } else if (e.target.name === "energyCostDcGbpPerKWhPeak") {
            parameter.energyCostDcGbpPerKWhPeak = e.target.value;

        } else if (e.target.name === "chargingPartnerFlatRateAcSinglePhaseOffPeak") {
            parameter.chargingPartnerFlatRateAcSinglePhaseOffPeak = e.target.value;
        } else if (e.target.name === "chargingPartnerFlatRateAcThreePhaseOffPeak") {
            parameter.chargingPartnerFlatRateAcThreePhaseOffPeak = e.target.value;
        } else if (e.target.name === "chargingPartnerFlatRateDcOffPeak") {
            parameter.chargingPartnerFlatRateDcOffPeak = e.target.value;
        } else if (e.target.name === "chargingPartnerFlatRateAcSinglePhasePeak") {
            parameter.chargingPartnerFlatRateAcSinglePhasePeak = e.target.value;
        } else if (e.target.name === "chargingPartnerFlatRateAcThreePhasePeak") {
            parameter.chargingPartnerFlatRateAcThreePhasePeak = e.target.value;
        } else if (e.target.name === "chargingPartnerFlatRateDcPeak") {
            parameter.chargingPartnerFlatRateDcPeak = e.target.value;


        }
    }

    async function fetchBusiness() {
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;

        if (params != undefined && params.businessUuid != undefined) {
            bodyData["businessUuid"] = params.businessUuid;
        } else {
            bodyData["businessUuid"] = userInfo.businessUuid;
        }


        let fetch_link = `${rootUrl}/business/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();



        setParameter(res_json.business);

        setFromHour(fromHourValues.filter((i: any) => i.value === res_json.business.chargersReservedFromHour)[0]);
        setToHour(toHourValues.filter((i: any) => i.value === res_json.business.chargersReservedToHour)[0]);
        setSubscribedValue(subscribtion.filter((i: any) => i.value === res_json.business.partnerChargingNetworkSubscription)[0]);
        setSiteAccessRestrictionValue(siteAccessRestriction.filter((i: any) => i.value === res_json.business.siteAccessRestriction)[0]);
        setReservation(reservationOptions.filter((i: any) => i.value === res_json.business.reservationOption)[0]);
        setWeekDays(res_json.business.dayOfWeekReservation);
        setSiteAccessPolicyOptions(res_json.business.siteAccessPolicies.map((e: any) => ({ ...e, label: e.name, value: e.uuid, name: "siteAccessPolicy" })));

        setFrequencyOptions(res_json.business.frequencies.map((e: any) => ({ ...e, label: e, value: e, name: "frequency" })));
        setBusinessCategoryOptions(res_json.business.businessCategories.map((e: any) => ({ ...e, label: e.name, value: e.name, name: "businessCategory" })));

        const selectedSurvey = res_json.business.siteAccessPolicies.find((i: any) => i.uuid === res_json.business.selectedSiteAccessPolicyUuid);

        if (selectedSurvey != null) {
            // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
            const selectedValue = {
                label: selectedSurvey.name, // assuming it has a 'name' field for the label
                value: selectedSurvey.uuid, // the unique identifier, used as value
                ...selectedSurvey, // spread the rest of the object properties if there are any others
            };
            setSiteAccessPolicyValue(selectedValue);

        } else {
            const selectedValue = {
                label: "", // assuming it has a 'name' field for the label
                value: "", // the unique identifier, used as value
            };
            setSiteAccessPolicyValue(selectedValue);
        }

        const selectedFrequency = res_json.business.frequencies.find((i: any) => i === res_json.business.selectedFrequency);


        if (selectedFrequency != null) {
            // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
            const selectedValue = {
                label: selectedFrequency, // assuming it has a 'name' field for the label
                value: selectedFrequency, // the unique identifier, used as value
                ...selectedFrequency, // spread the rest of the object properties if there are any others
            };
            setFrequencyValue(selectedValue);

        } else {
            const selectedValue = {
                label: "", // assuming it has a 'name' field for the label
                value: "", // the unique identifier, used as value
            };
            setFrequencyValue(selectedValue);
        }


        const selectedBusinessCategory = res_json.business.businessCategories.find((i: any) => i.name === res_json.business.chargingCategory);

        if (selectedBusinessCategory != null) {
            // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
            const selectedValue = {
                label: selectedBusinessCategory.name, // assuming it has a 'name' field for the label
                value: selectedBusinessCategory.name, // the unique identifier, used as value
                ...selectedBusinessCategory, // spread the rest of the object properties if there are any others
            };
            setBusinessCategoryValue(selectedValue);

        } else {
            const selectedValue = {
                label: "", // assuming it has a 'name' field for the label
                value: "", // the unique identifier, used as value
            };
            setBusinessCategoryValue(selectedValue);
        }

        setLoading(false);
    }

    async function updateBusiness() {
        setIsDisabled(true);
        setSaving(true);
        let bodyData: { [name: string]: any } = {};
        let business: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        bodyData["business"] = business;

        if (params != undefined && params.businessUuid != undefined) {
            business["uuid"] = params.businessUuid;
        } else {
            business["uuid"] = userInfo.businessUuid;
        }
        business["chargersReservedFromHour"] = fromHour == null ? "00" : fromHour.value;
        business["chargersReservedToHour"] = toHour == null ? "00" : toHour.value;
        business["partnerChargingNetworkSubscription"] = subscribedValue.value;
        business["siteAccessRestriction"] = siteAccessRestrictionValue.value;
        business["selectedSiteAccessPolicyUuid"] = siteAccessPolicyValue.value;
        console.log("FREQUENCY : " + frequencyValue.value);
        business["selectedFrequency"] = frequencyValue.value == "" ? null : frequencyValue.value;
        business["selectedBusinessCategory"] = businessCategoryValue.value;
        business["reservationOption"] = reservation.value;
        business["dayOfWeekReservation"] = weekDays;
        business["energyCostAcSinglePhaseGbpPerKWhOffPeak"] = Number(parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak);
        business["energyCostAcThreePhaseGbpPerKWhOffPeak"] = Number(parameter.energyCostAcThreePhaseGbpPerKWhOffPeak);
        business["energyCostDcGbpPerKWhOffPeak"] = Number(parameter.energyCostDcGbpPerKWhOffPeak);
        business["energyCostAcSinglePhaseGbpPerKWhPeak"] = Number(parameter.energyCostAcSinglePhaseGbpPerKWhPeak);
        business["energyCostAcThreePhaseGbpPerKWhPeak"] = Number(parameter.energyCostAcThreePhaseGbpPerKWhPeak);
        business["energyCostDcGbpPerKWhPeak"] = Number(parameter.energyCostDcGbpPerKWhPeak);

        business["chargingPartnerFlatRateAcSinglePhaseOffPeak"] = Number(parameter.chargingPartnerFlatRateAcSinglePhaseOffPeak);
        business["chargingPartnerFlatRateAcThreePhaseOffPeak"] = Number(parameter.chargingPartnerFlatRateAcThreePhaseOffPeak);
        business["chargingPartnerFlatRateDcOffPeak"] = Number(parameter.chargingPartnerFlatRateDcOffPeak);
        business["chargingPartnerFlatRateAcSinglePhasePeak"] = Number(parameter.chargingPartnerFlatRateAcSinglePhasePeak);
        business["chargingPartnerFlatRateAcThreePhasePeak"] = Number(parameter.chargingPartnerFlatRateAcThreePhasePeak);
        business["chargingPartnerFlatRateDcPeak"] = Number(parameter.chargingPartnerFlatRateDcPeak);

        let fetch_link = `${rootUrl}/business/update`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        let res_json = await response.json();

        setSaving(false);

        console.log(JSON.stringify(res_json));

        if (response.status === 200) {

            setUserInfo((prev: any) => ({
                ...prev,
                businessCategory: res_json.business.chargingCategory,
              }));

            setIsDisabled(false);
            toast.success('Configuration Updated Successfully', {
                position: 'bottom-center',
                duration: 5000,
            });
        } else if (response.status === 400) {
            console.log(JSON.stringify(res_json.code));
            switch (res_json.code) {
                case "BUSINESS_NO_RATE_CARDS":
                    setErrorMessage("No rate cards have been setup for this business.");
                    break;
                case "BUSINESS_NO_DEFAULT_RATE_CARD":
                    setErrorMessage("No default rate card has been selected for this business");
                    break;
                case "BUSINESS_NO_SITE_ACCESS_POLICIES":
                    setErrorMessage("No site access policies have been setup for this business.");
                    break; case "ELECTRIC_VEHICLES_EXIST_FOR_BUSINESS":
                    setErrorMessage("Cannot change category as business has electric vehicles");
                    break;
                case "SITE_RESTRICTION_APPLIES":
                    setErrorMessage("Site Access Restriction applied, but none selected");
                    break;
                case "BUSINESS_NO_SHARED_INFRA_NO_SAP_RESTRICTION_NEEDED":
                    setErrorMessage("Business has no shared infrastructure, site access policy restriction cannot be set.");
                    break;
                default:
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst saving this business.");
                    break;
            }

            setIsError(true);
            setIsDisabled(false);
            return;

        }

    }

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);

    }, [errorMessage]);


    return (
        <>
            <Backdrop open={saving} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                <>
                    <div
                        onClick={() => {
                            closeModal();
                        }}
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IoMdClose size={30}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            margin: 10,
                            padding: 50,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 30,
                        }}>
                        <>
                            {isError ? (
                                <h1> {errorMessage} </h1>
                            ) : (
                                <>
                                </>
                            )}
                        </>
                    </div>
                </>
            </ModalComponent>
            {loading ? (
                <>Loading ...</>
            ) : (
                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-12 col-xl-12">
                            <div className="dashboard-card" >
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Charging Network Subscription values for {parameter.name}
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 240 }}>
                                            Subscribe To Charging Network :

                                        </span>
                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "568px" }}>
                                            <Select
                                                value={subscribedValue}
                                                name="subscribed"
                                                onChange={onChange}
                                                options={subscribtion}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 240 }}>
                                            Business Category :

                                        </span>
                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "568px" }}>
                                            <Select
                                                
                                                value={businessCategoryValue}
                                                name="businessCategory"
                                                onChange={onChange}
                                                options={businessCategoryOptions}
                                                styles={{ container: (base) => ({ ...base, width: "240px" }) }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {hasSharedInfrastructure(businessCategoryValue.value) ? (
                            <>
                                <div className="col-xs-12 col-md-6 col-xl-6">
                                    <div className="dashboard-card" >
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Site Access Restrictions for visitors
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Site Access Restrictions :

                                                </span>
                                                <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "168px" }}>
                                                    <Select
                                                        value={siteAccessRestrictionValue}
                                                        name="subscribed"
                                                        onChange={onChange}
                                                        options={siteAccessRestriction}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {siteAccessRestrictionValue.value ? (

                                            <>
                                                <div
                                                    className="dashboard-card-content"
                                                    style={{ gap: 20 }}>
                                                    <div className="box-info">
                                                        <span
                                                            className="item"
                                                            style={{ width: 300 }}>
                                                            Selected Site Access Policy :
                                                        </span>
                                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "246px" }}>
                                                            <Select
                                                                value={siteAccessPolicyValue}
                                                                name="siteAccessPolicy"
                                                                onChange={onChange}
                                                                options={siteAccessPolicyOptions}
                                                                styles={{ container: (base) => ({ ...base, width: "240px" }) }}
/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        ) : (<div />)}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6 col-xl-6">
                                    <div className="dashboard-card" >
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Charger Reservation Settings
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Reservation Option :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "168px" }}>
                                                    <Select
                                                        value={reservation}
                                                        name="reservationOptionField"
                                                        onChange={onChange}
                                                        options={reservationOptions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            {reservation.value === "DEFAULT" ? (
                                                <div>
                                                    <div className="box-info">
                                                        <span
                                                            className="item"
                                                            style={{ width: 300 }}>
                                                            Reserved From Hour :
                                                        </span>
                                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "168px" }}>
                                                            <Select
                                                                value={fromHour}
                                                                name="fromHour"
                                                                onChange={onChange}
                                                                options={fromHourValues}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="box-info">
                                                        <span
                                                            className="item"
                                                            style={{ width: 300 }}>
                                                            Reserved To Hour :
                                                        </span>
                                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "168px" }}>
                                                            <Select
                                                                value={toHour}
                                                                name="toHour"
                                                                onChange={onChange}
                                                                options={toHourValues}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {
                                                        weekDays.map((dayInfo: WeekDay) => (
                                                            <WeeklyChargingReservation readOnly={false} key={dayInfo.dayIndex} dayOfWeekLabel={dayInfo.day} dayInfo={dayInfo} onTimeChange={handleTimeChange} />
                                                        ))
                                                    }
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6 col-xl-6">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Energy Cost to Business per kW/h (Off-Peak)
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    AC Single Phase :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={typeof parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak == "number" ? parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak.toFixed(2) : 0.00}
                                                        type="text"
                                                        style={{ textAlign: "right" }}
                                                        className="textbox"
                                                        name="energyCostAcSinglePhaseGbpPerKWhOffPeak"
                                                        id="energyCostAcSinglePhaseGbpPerKWhOffPeak"
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>£</span>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    AC Three Phase :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={typeof parameter.energyCostAcThreePhaseGbpPerKWhOffPeak == "number" ? parameter.energyCostAcThreePhaseGbpPerKWhOffPeak.toFixed(2) : 0.00}
                                                        type="text"
                                                        style={{ textAlign: "right" }}
                                                        className="textbox"
                                                        name="energyCostAcThreePhaseGbpPerKWhOffPeak"
                                                        id="energyCostAcThreePhaseGbpPerKWhOffPeak"
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>£</span>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    DC :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={typeof parameter.energyCostDcGbpPerKWhOffPeak == "number" ? parameter.energyCostDcGbpPerKWhOffPeak.toFixed(2) : 0.00}
                                                        type="text"
                                                        style={{ textAlign: "right" }}
                                                        className="textbox"
                                                        name="energyCostDcGbpPerKWhOffPeak"
                                                        id="energyCostDcGbpPerKWhOffPeak"
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>£</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6 col-xl-6">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Energy Cost to Business per kW/h (Peak)
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    AC Single Phase :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={typeof parameter.energyCostAcSinglePhaseGbpPerKWhPeak == "number" ? parameter.energyCostAcSinglePhaseGbpPerKWhPeak.toFixed(2) : 0.00}
                                                        type="text"
                                                        style={{ textAlign: "right" }}
                                                        className="textbox"
                                                        name="energyCostAcSinglePhaseGbpPerKWhPeak"
                                                        id="energyCostAcSinglePhaseGbpPerKWhPeak"
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>£</span>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    AC Three Phase :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={typeof parameter.energyCostAcThreePhaseGbpPerKWhPeak == "number" ? parameter.energyCostAcThreePhaseGbpPerKWhPeak.toFixed(2) : 0.00}
                                                        type="text"
                                                        style={{ textAlign: "right" }}
                                                        className="textbox"
                                                        name="energyCostAcThreePhaseGbpPerKWhPeak"
                                                        id="energyCostAcThreePhaseGbpPerKWhPeak"
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>£</span>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    DC :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={typeof parameter.energyCostDcGbpPerKWhPeak == "number" ? parameter.energyCostDcGbpPerKWhPeak.toFixed(2) : 0.00}
                                                        type="text"
                                                        style={{ textAlign: "right" }}
                                                        className="textbox"
                                                        name="energyCostDcGbpPerKWhPeak"
                                                        id="energyCostDcGbpPerKWhPeak"
                                                        onChange={onChange}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>£</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (<></>)}
                        <div className="col-xs-12 col-md-12 col-xl-12">
                            <div className="">

                                <div
                                    className=""
                                    style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                    <button
                                        className="primary-btn"
                                        disabled={isDisabled}
                                        onClick={updateBusiness}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default BusinessChargingNetworkParametersPage;