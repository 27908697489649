import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";

interface QuickQuotePanelProps {
    numberOfVehicles: number;
    chargesPerWeek: number;
    averageChargeSize: number;
    monthlyRequirementKWh: number;
    onQuoteChange: (updatedValues: any) => void; // Callback prop
}

const QuickQuotePanel: React.FC<QuickQuotePanelProps> = ({ 
    numberOfVehicles, 
    chargesPerWeek, 
    averageChargeSize, 
    monthlyRequirementKWh,
    onQuoteChange 
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const currentInput = useRef() as any;

    const onChange = (e: any) => {
        const { name, value } = e.target;
        const numericValue = value === '' ? null : parseFloat(value);

        let updatedValues: any = {};
        if (name === 'numberOfVehicles') {
            updatedValues = { numberOfVehicles: numericValue };
            calculateMonthlyRequirement(numericValue, chargesPerWeek, averageChargeSize);
        } else if (name === 'chargesPerWeek') {
            updatedValues = { chargesPerWeek: numericValue };
            calculateMonthlyRequirement(numberOfVehicles, numericValue, averageChargeSize);
        } else if (name === 'averageChargeSize') {
            updatedValues = { averageChargeSize: numericValue };
            calculateMonthlyRequirement(numberOfVehicles, chargesPerWeek, numericValue);
        }

        onQuoteChange(updatedValues); // Pass the updated values back to parent
    };

    const calculateMonthlyRequirement = (vehicles: number | null, charges: number | null, chargeSize: number | null) => {
        if (vehicles !== null && charges !== null && chargeSize !== null) {
            const result = (vehicles * charges * chargeSize * 52) / 12;
            onQuoteChange({ monthlyRequirementKWh: result });
        } else {
            onQuoteChange({ monthlyRequirementKWh: 0 });
        }
    };

    return (
        <div className="col-xs-12 col-md-6 col-xl-6">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">view_timeline</span>
                    Power Requirements
                </div>
                <div className="dashboard-card-content" style={{ gap: 20 }}>
                    <div className="box-info">
                        <span className="item" style={{ width: 300 }}>Number of Vehicles :</span>
                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                            <input
                                ref={currentInput}
                                defaultValue={numberOfVehicles !== null ? numberOfVehicles : ""}
                                type="text"
                                style={{ textAlign: "left" }}
                                className="textbox"
                                name="numberOfVehicles"
                                id="numberOfVehicles"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="box-info">
                        <span className="item" style={{ width: 300 }}>Charges per week :</span>
                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                            <input
                                ref={currentInput}
                                defaultValue={chargesPerWeek !== null ? chargesPerWeek : ""}
                                type="text"
                                style={{ textAlign: "left" }}
                                className="textbox"
                                name="chargesPerWeek"
                                id="chargesPerWeek"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="box-info">
                        <span className="item" style={{ width: 300 }}>Average Charge size (kW/h) :</span>
                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                            <input
                                ref={currentInput}
                                defaultValue={averageChargeSize !== null ? averageChargeSize : ""}
                                type="text"
                                style={{ textAlign: "left" }}
                                className="textbox"
                                name="averageChargeSize"
                                id="averageChargeSize"
                                onChange={onChange}
                            />
                        </div>
                    </div>

                    <div className="box-info">
                        <span className="item" style={{ width: 300 }}>Monthly Requirement: {monthlyRequirementKWh?.toFixed(2)} kW/h</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuickQuotePanel;
