import { useContext } from "react";

import UserContext from "Context/UserProvider";

import { isAgreeProduct, isPlanProduct, isShareProduct } from "utils/productUtils";
import { isBusinessRole } from "utils/roleUtils";
import Bookings from "./business/Bookings";
import ChargingSessions from "./business/ChargingSessions";
import PartnerChargingNetwork from "./business/PartnerChargingNetwork";
import ChargingSummaryBarChart from "./business/savings/ChargingSummaryBarChart";
import ChargingSummaryPieChart from "./business/savings/ChargingSummaryPieChart";
import Billing from "./common/Billing";
import News from "./common/News";


type BusinessDashboardProps = {
    dashboardInfo: any;
};

function BusinessDashboard({ dashboardInfo }: BusinessDashboardProps) {

    const { userInfo, selectedProduct } = useContext(UserContext);
    const isBusinessOnly = isBusinessRole(userInfo.userType);

    const inboundTitle = "Recent Inbound Charging Sessions";


    if (!isBusinessOnly) {
        return null;
    }

    return (
        <>
            {selectedProduct !== null && isAgreeProduct(selectedProduct) && (
                <>
                    <News />
                    <Billing />
                </>
            )}
            {selectedProduct !== null && isPlanProduct(selectedProduct) && (
                <>
                    <News />
                    <Billing />
                </>
            )}
            {selectedProduct !== null && isShareProduct(selectedProduct) && (
                <>
                    <PartnerChargingNetwork dashboardInfo={dashboardInfo} />
                    <Bookings dashboardInfo={dashboardInfo} />
                    <ChargingSessions title={inboundTitle} chargingSessions={dashboardInfo.inboundChargingSessions} incomeCostTitle="Income" />
                    <div className="col-xs-12 col-md-6 col-xl-4">
                        <div className="dashboard-card">
                            <ChargingSummaryBarChart
                                fleetScenarioUuid=""
                                data={[
                                    { name: "Partner Charging Cost", price: Math.round(dashboardInfo.currentMonth?.partnerChargingCost) },
                                    { name: "Est. Public Charging Cost", price: Math.round(dashboardInfo.currentMonth?.publicChargingCost) },
                                ]}
                                dashboardInfo={dashboardInfo}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 col-xl-4">
                        <div className="dashboard-card">

                            <ChargingSummaryPieChart
                                total={dashboardInfo.countChargePointsUsedByYourBusiness + "/" + dashboardInfo.countChargePointsInNetwork}
                                unit={"Charge Points"}
                                fleetScenarioUuid={""}
                                fleetScenarioNumber={""}
                                title={"Percentage of Network Charge Points used"}
                                height={400}
                                width={400}
                                data={dashboardInfo.outboundChargePointUsage}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 col-xl-4">
                        <div className="dashboard-card">
                            <ChargingSummaryPieChart
                                total={dashboardInfo.countBusinessesServedByYourChargePoints + "/" + dashboardInfo.businessesInPCN}
                                unit={"Businesses"}
                                fleetScenarioUuid={""}
                                fleetScenarioNumber={""}
                                title={"Percentage of Businesses using your charge points"}
                                height={400}
                                width={400}
                                data={dashboardInfo.inboundBusinessUsage}
                            />
                        </div>
                    </div>
                </>
            )}

        </>


    )
}
export default BusinessDashboard;
