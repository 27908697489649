import { NotificationContext } from "components/common/Notifications/NotificationProvider";
import ScenarioConfigContext from "Context/ScenarioConfigContext";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";
import IceVehicleTable from "./components/IceVehicleTable";
import UploadProgressTable from "./components/UploadProgressTable";

function IceVehiclePage(props: any) {
    const [scenarioConfigInfo, setScenarioConfigInfo] = useContext(ScenarioConfigContext) as any;
    const location = useLocation() as any;
    const fleetScenarioUuid = scenarioConfigInfo.draftScenario?.uuid;
    const [allVehicles, setAllVehicles] = useState([] as any);
    const [notificationStatus, setNotificationStatus] = useState([]) as any;
    const [incompleteFiles, setIncompleteFiles] = useState([]) as any;
    const [vehicleStatus, setVehicleStatus] = useState([]) as any;
    const notificationContext = useContext(NotificationContext);
    const [statusPolling, setStatusPolling] = useState(false);
    const [initialising, setInitialising] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { userInfo, setUserInfo } = useContext(UserContext);
    const [activeMenu, setActiveMenu] = useState(location.state?.showCategory ? (location.state?.showCategory[0] ? "Current Fleet" : "Progress") : "Current Fleet");
    // const [previousFile, setPreviousFile] = useState([] as any);
    const [loading, setLoading] = useState(true);

    const [showSecondaryCategory, setShowSecondaryCategory] = useState(location.state?.showCategory ? location.state.showCategory : [true, false]);
    const [isError, setIsError] = useState(false);

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    //******************** Important function for getting the access token and authorization api *************************//

    function updatedStatusNotification(a1: [any], a2: [any]) {
        a1 = checking_null_undefined(a1);
        a2 = checking_null_undefined(a2);
        let result = a1.filter(({ id: id1, status: status1 }) => !a2.some(({ id: id2, status: status2 }) => id2 === id1 && status1 === status2));

        if (JSON.stringify(result) !== JSON.stringify(notificationStatus)) {
            if (result.length > 0) {
                if (result.length < notificationStatus.length) {
                    notificationStatus
                        .filter((e: any) => result.includes(e))
                        .map((e: any) =>
                            notificationContext.dispatch({
                                type: "ADD_NOTIFICATION",
                                payload: {
                                    id: v4(),
                                    type: "SUCCESS",
                                    message: `Vehicle ${e.registrationNumber} is completed`,
                                },
                            })
                        );
                } else {
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].statusDescription === "Not an N1 Category Vehicle" || result[i].statusDescription === "DVLA Vehicle Details Not Found") {
                            notificationContext.dispatch({
                                type: "ADD_NOTIFICATION",
                                payload: {
                                    id: v4(),
                                    type: "ERROR",
                                    message: `Vehicle ${result[i].registrationNumber} is deleted`,
                                },
                            });
                        } else {
                            // setNotifications((prev: any) => [...prev, { message: `Vehicle ${result[i].registrationNumber} is currently ${result[i].statusDescription}` }])
                            notificationContext.dispatch({
                                type: "ADD_NOTIFICATION",
                                payload: {
                                    id: v4(),
                                    type: "SUCCESS",
                                    message: `Vehicle ${result[i].registrationNumber} is currently ${result[i].statusDescription}`,
                                },
                            });
                        }
                    }
                }
            }
            setNotificationStatus((prev: any) => result);
        }
        // return result;
    }

    async function fetchVehicleList() {
        setLoading(true);
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        
        console.log("fetching..");

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/DragDrop/fleet/icevehicle/list?t='${Math.random()}'`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });

        let res_json = await response.json();

        console.log(JSON.stringify(res_json));

        if (response.status === 200) {
            // setIsDisabled(false);
            // toast.success('Charging Partner Submit Successful', {
            //     position: 'bottom-center',
            //     duration: 5000,
            // });

            // navigate("/share/business/charging-partners");

        } else if (response.status === 400) {

            switch (res_json.code) {
                case "FLEET_SCENARIO_INITIALISING":
                case "FLEET_SCENARIO_NOT_DRAFT":
                    setInitialising(true);
                    break;
                default:
                    console.log("fs " + fleetScenarioUuid);
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst during data retrieval.");
                    break;
            }


        }


        let iceVehicleList = checking_null_undefined(res_json.iceVehicleList);
        let iceVehicles = checking_null_undefined(
            iceVehicleList.map((e: any) => ({
                ...e,
                id: e.uuid,
                selected: e.selected,
            }))
        );
        // let iceVehicles = res_json.iceVehicles
        iceVehicles = checking_null_undefined(iceVehicles);
        setAllVehicles(iceVehicles);
        setLoading(false);
    }

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);


    async function checkStatus() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/status`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        let json_res = await response.json();
        let files = checking_null_undefined(json_res.files).map((e: any) => ({ ...e, id: e.uuid }));
        let unfinishedFile = files.filter((e: any) => e.status !== "DONE");
        let currentRunningFile = files.filter((e: any) => {
            return e.status !== "DONE" && e.status !== "EXTRACT_CSV_FILES_FAILED";
        });
        // let failedFile = json_res.files.filter((e:any)=> e === "FAILED")

        //For notification
        updatedStatusNotification(vehicleStatus, unfinishedFile);
        setVehicleStatus(unfinishedFile);

        //Set current status

        setIncompleteFiles((prev: any) => {
            if (currentRunningFile.length === 0) {
                setStatusPolling(false);
                fetchVehicleList();
                return unfinishedFile;
            }
            if (prev.length !== unfinishedFile.length) {
                fetchVehicleList();
                return unfinishedFile;
            }
            if (JSON.stringify(prev) !== JSON.stringify(unfinishedFile)) {
                return unfinishedFile;
            }
            return prev;
        });
    }

    async function isPollingRequired() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/status`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        let json_res = await response.json();
        let files = checking_null_undefined(json_res.files).map((e: any) => ({ ...e, id: e.uuid }));
        let unfinishedFile = files.filter((e: any) => (e.status !== "DONE" && e.status !== "DONE_WITH_ERRORS"));
        let failedFiles = files.filter((e: any) => e.status === "FAILED");
        if (unfinishedFile.length === 0) {
            setIncompleteFiles(files);
            setStatusPolling(false);
        } else {
            setVehicleStatus(unfinishedFile);
            setIncompleteFiles(files);
            setStatusPolling(true);
        }
    }

    const componentMounted = useRef(true); // (3) component is mounted
    // ...
    useEffect(() => { }, [incompleteFiles]);
    useEffect(() => {
        // When request is finished:
        // if (componentMounted.current) { // (5) is component still mounted?
        isPollingRequired();
        // fetchVehicleList();

        setLoading(false); // (2) write some value to state
        // }

        // return () => { // This code runs when component is unmounted
        //     componentMounted.current = false; // (4) set it to false when we leave the page
        // }
    }, []);

    //UseInterval(checkStatus, 1000, statusPolling);
    useEffect(() => { }, [statusPolling]);

    // const yourFleetHeader = <h1><b>Telematics</b></h1>
    return (
        <>

            {initialising ? (
                <>There is currently a Scenario in Progress, please refresh to try again</>
            ) : (
                <div className="drag-drop-container">
                    <>
                        <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                            <div className="vl"></div>
                            <button
                                className={`tab-btn ${activeMenu === "Current Fleet" ? "active" : ""}`}
                                onClick={() => (setShowSecondaryCategory([true, false]), setActiveMenu("Current Fleet"))}>
                                Current Fleet
                            </button>
                            <div className="vl"></div>
                            <button
                                className={`tab-btn ${activeMenu === "Progress" ? "active" : ""}`}
                                onClick={() => (setShowSecondaryCategory([false, true]), setActiveMenu("Progress"))}>
                                Progress
                            </button>
                            <div className="vl"></div>
                        </div>
                        {showSecondaryCategory[0] && (
                            <>
                                <h1></h1>
                                <IceVehicleTable
                                    allVehicles={[allVehicles, setAllVehicles]}
                                    userInfo={userInfo}
                                    fetchVehicleList={fetchVehicleList}
                                    // previousFile={[previousFile, setPreviousFile]}
                                    fleetScenarioUuid={fleetScenarioUuid}></IceVehicleTable>
                            </>
                        )}
                        {showSecondaryCategory[1] && (
                            <>
                                <h1></h1>
                                <UploadProgressTable
                                    statusPolling={[statusPolling, setStatusPolling]}
                                    incompleteFiles={[incompleteFiles, setIncompleteFiles]}
                                    // previousFile={[previousFile, setPreviousFile]}
                                    userInfo={userInfo}
                                />
                            </>
                        )}
                    </>
                </div>
            )
            }
        </>
    )
}

export default IceVehiclePage;
