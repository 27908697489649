import { FC, useState } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import { fromHourValues } from "./fromHourValues";
import { toHourValues } from "./toHourValues";
import { WeekDay } from "./weekDays";

// Define types for your props
interface WeeklyChargerReservationProps {
    readOnly: boolean;
    dayOfWeekLabel: string;
    dayInfo: {
        dayIndex: number;
        fromHour: string;
        toHour: string;
        day: string;
    };
    onTimeChange: (dayIndex: number, type: keyof WeekDay, value: string) => void;

}
interface OptionType {
    label: string;
    value: string;
    name: string;
}

const WeeklyChargerReservation: FC<WeeklyChargerReservationProps> = ({ readOnly, dayOfWeekLabel, dayInfo, onTimeChange }) => {
    const [fromHour, setFromHour] = useState<OptionType>({ label: dayInfo.fromHour, value: dayInfo.fromHour, name: "fromHour" });
    const [toHour, setToHour] = useState<OptionType>({ label: dayInfo.toHour, value: dayInfo.toHour, name: "toHour" });

    // const [fromHour, setFromHour] = useState({ label: "00", value: 0, name: "fromHour" }) as any;
    // const [toHour, setToHour] = useState({ label: "00", value: 0, name: "toHour" }) as any;

    const handleChange = (selectedOption: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {


        if (selectedOption === null || selectedOption == null) return;

        // Considering the possibility of 'name' being undefined.
        if (actionMeta.name === undefined) return;

        if (actionMeta.name === "fromHour") {
            setFromHour(selectedOption);
            onTimeChange(dayInfo.dayIndex, 'fromHour', selectedOption.value);
        } else if (actionMeta.name === "toHour") {
            setToHour(selectedOption);
            onTimeChange(dayInfo.dayIndex, 'toHour', selectedOption.value);
        }
    };






    return (
        <div className="box-info">
            <span className="item" style={{ width: 300 }}>
                {dayOfWeekLabel} :
            </span>
            {readOnly ? (
                <div style={{ display: "flex", alignItems: "right", textAlign: "right", width: "40px" }}>
                    <span className="data">{fromHour.value}</span>
                    <div style={{ width: 8 }} />
                    <span className="data">{toHour.value}</span>
                </div>
            ) : (

                <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "260px" }}>
                    <Select
                        value={fromHour}
                        name="fromHour"
                        onChange={handleChange}
                        options={fromHourValues}
                    />
                    <div style={{ width: 8 }} />
                    <Select
                        value={toHour}
                        name="toHour"
                        onChange={handleChange}
                        options={toHourValues}
                    />
                </div>
            )}
        </div>
    );
}

export default WeeklyChargerReservation;