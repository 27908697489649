import ScenarioConfigContext from "Context/ScenarioConfigContext";
import UserContext from "Context/UserProvider";
import {
    useContext,
    useEffect,
    useState
} from "react";
import { Outlet } from "react-router";

const ScenarioContextProvider = (props: any) => {
  const [scenarioConfigInfo, setScenarioConfigInfo] = useState({
    runningScenario: {} as any,
    draftScenario: {} as any,
  });
  const [loading, setLoading] = useState(true);
  const {userInfo} = useContext(UserContext);

  async function fetchDraft() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    const rootUrl =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_BACKEND_URL
        : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/draft`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      return;
    }
    let res_json = await response.json();
    setScenarioConfigInfo((prev) => ({
      ...prev,
      draftScenario: res_json.draftScenario,
    }));
    setScenarioConfigInfo((prev) => ({
      ...prev,
      runningScenario: res_json.runningScenario,
    }));
    setLoading(false);
  }

  useEffect(() => {
    fetchDraft();
  }, []);
  return (
    <ScenarioConfigContext.Provider
      value={[scenarioConfigInfo, setScenarioConfigInfo]}
    >
      <>{loading ? <>Loading...</> : <Outlet />}</>
    </ScenarioConfigContext.Provider>
  );
};

export default ScenarioContextProvider;
