import "./HelpPanel.scss";

function HelpPanel(props: any) {
  const title = props.title;

  return (
    <aside className="help-panel">
      <div className="help-panel-title">
        <span className="name">Evata</span>
        <span className="tag-line">{title} Help</span>
      </div>

      <div className="help-panel-content">{props.content}</div>
    </aside>
  );
}

export default HelpPanel;
