import GoogleMapReact from "google-map-react";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import "./static/fonts/Qualy/Qualy.ttf";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
// const domain: any = process.env.REACT_APP_AUTH0_DOMAIN;
// const clientId: any = process.env.REACT_APP_AUTH0_CLIENT_ID;
// const audience: any = process.env.REACT_APP_AUTH0_AUDIENCE;

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID as string });
root.render(
  <BrowserRouter>
    <link
      rel="preconnect"
      href="https://fonts.googleapis.com"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;1,100&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
      rel="stylesheet"
    />

    <App />
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY as string }}
      yesIWantToUseGoogleMapApiInternals
    />
  </BrowserRouter>,
);
