import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import AuthService from "service/AuthService/AuthService";
import { useNavigate } from "react-router";

function LoginPage(props: any) {
  // document.location.href = `${document.location.origin + "/login"}`;
  localStorage.clear();
  sessionStorage.clear();
  const [toggle, setToggle] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  async function signIn() {
    let passwordInput = document.getElementById("password") as HTMLInputElement;
    let emailInput = document.getElementById("email") as HTMLInputElement;
    let rememberMe = toggle;
    await AuthService.login(emailInput.value, passwordInput.value, setErrorMessage, rememberMe);
  }
  // useEffect(() => {
  //   document.location.href = `${document.location.origin + "/login"}`;
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  }, [errorMessage]);
  return (
    <div className="login-container">
      <div className="login-content-container">
        
        <div className="form-login-container">
          <span
            className="login-logo"
            onClick={() => navigate("/")}>
            Evata
          </span>
          <div className="form-login-content">
            <div className="form-login-inner-content">
              <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: 5 }}>
                <span style={{ color: "grey", fontSize: 15 }}>Please enter your details</span>
              </div>
              {errorMessage && <div style={{ color: "red", textAlign: "center" }}>{errorMessage}</div>}
              <form autoComplete="off">
                <input
                  type="email"
                  required={true}
                  //   onChange={onChange}
                  placeholder="email"
                  autoComplete="off"
                  name="email"
                  id="email"
                />

                <input
                  type="password"
                  required={true}
                  //   onChange={onChange}
                  placeholder="password"
                  autoComplete="off"
                  name="password"
                  id="password"
                />

                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e) => setToggle((prev) => !prev)}
                    defaultChecked={toggle}
                  />
                  <span>Remember me.</span>
                </div>
              </form>
              <div className="form-login-button-container">
                <div
                  className="login-button"
                  onClick={signIn}>
                  Login
                </div>
                <span>Don't have account? Contact Us2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
