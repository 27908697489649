import React, { useContext, useEffect, useState } from "react";
import DashboardCardItem from "../common/DashboardCardItem";
import DashboardCardTitle from "../common/DashboardCardTitle";
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

function ChargingNetwork(props: any) {

    const dashboardInfo = props.dashboardInfo;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const isPcn = props.isPcn;

    if (!isPcn) {
        return null;
    }

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">settings_account_box</span> Charging Network
                </div>
                <div className="dashboard-card-content">
                    <div className="box-info">
                        <span className="item">Subscriptions :</span>
                        <span className="data">
                            {dashboardInfo.businessesInPCN}
                        </span>
                    </div>
                    <div className="box-info">
                        <span className="item">Virtual Charging Hubs :</span>
                        <span className="data">
                            {dashboardInfo.countChargePointsInNetwork}
                        </span>
                    </div>
                </div>
                <div className="dashboard-card-actions">
                    <div
                        className="icon-and-text"
                        onClick={() => navigate("/plan/private-charging-network/map")}>
                        <span className="icon material-symbols-outlined">hive</span>
                        View Network Map
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChargingNetwork;