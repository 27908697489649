import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { useNavigate } from "react-router";

interface Agreement {
    id: number;
    uuid: string;
    siteAccessPolicyUuid: string;
    workflowState: string;
    chargePointOperator: {
        uuid: string;
        name: string;
    };
    rateCardName: string;
    ppaPricePerKWh: number;
    monthlyCommitmentInGbp: number;
    annualCommitmentInGbp: number;
    publicSavingInGbp: number;
    rowId: any;
}

interface DataGridChildProps {
    agreements: Agreement[];
}

const formatToGBP = (value: number) => {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
};



const PowerPurchaseAgreementTable: React.FC<DataGridChildProps> = ({ agreements }) => {
    const [includeVAT, setIncludeVAT] = useState(true);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<GridColDef[]>([]);


    const toggleVAT = () => {
        setIncludeVAT(!includeVAT);
    };

    const formatPrice = (cost: number) => {
        if (cost === null || cost === 0) {
            return "";
        }
        const vatRate = 0.20; // Assuming 20% VAT
        const price = includeVAT ? cost : cost / (1 + vatRate);
        return formatToGBP(price);
    };

    const formatKWh = (kWh: number) => {
        // if (kWh === null || kWh === 0) {
        //     return "";
        // }

        return kWh.toFixed(2) + " kW/h";
    };

    const renderHeader = (title: string) => (
        <div>
            <b>{title}</b>
            <br />
            <span style={{ fontSize: 'smaller', fontWeight: 'normal' }}>
                {includeVAT ? "(incl VAT)" : "(excl VAT)"}
            </span>
        </div>
    );

    useEffect(() => {
        const updatedColumns: GridColDef[] = [
            {
                field: "businessName",
                headerName: "Business",
                sortable: false,
                width: 180,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "rateCardName",
                headerName: "Agreement Name",
                sortable: false,
                width: 140,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "ppaPricePerKWh",
                renderHeader: () => renderHeader("Price per kW/h"),
                sortable: false,
                width: 140,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "startDate",
                headerName: "Start Date",
                sortable: false,
                width: 140,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "endDate",
                headerName: "End Date",
                sortable: false,
                width: 140,
                align: "left",
                headerAlign: "left",
            },           {
                field: "currentBalanceInGbp",
                renderHeader: () => renderHeader("Current Balance"),
                sortable: false,
                width: 140,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "currentBalanceInKWh",
                headerName: "Remaining Energy",
                sortable: false,
                width: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatKWh(params as number),
            },


        ];
        setColumns(updatedColumns);
    }, [includeVAT, navigate]);

    return (
        <>
            <div className="table-box">
                <Button onClick={toggleVAT}>
                    {includeVAT ? "Show Prices Excluding VAT" : "Show Prices Including VAT"}
                </Button>
                <DataGrid
                    rows={agreements}
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15} } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}
                    getRowId={(row) => row.uuid}
                />
            </div>
        </>
    );
};

export default PowerPurchaseAgreementTable;
