import { Backdrop, CircularProgress } from '@mui/material';
import TradesTable from 'components/ppa-trade/TradesTable';
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';


function TradeListPage(props: any) {
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [trades, setTrades] = useState([]) as any;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    async function fetchTrades() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/ppa/advanced/trade/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        res_json.trades = checking_null_undefined(res_json.trades);

        setTrades(res_json.trades.map((e: any) => ({ ...e, id: e.uuid })));

        setLoading(false);
    }

    async function adoptTrade(tradeUuid: string) {

        setLoading(true);

        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["powerPurchaseAgreementTradeUuid"] = tradeUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/ppa/advanced/trade/adopt`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        let res_json = await response.json();

        if (response.status !== 200) {

            switch (res_json.code) {
                case "CHARGING_PARTNER_NOT_FOUND":
                    setErrorMessage("No existing Power Purchase Agreement in place for the Charge Point Operator.");
                    break;
                default:
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst attempting to process the Trade.");
                    break;
            }
            setLoading(false);

            return;
        } else {
            toast.success('Trade adoption successful', {
                position: 'bottom-center',
                duration: 5000,
            });
            fetchTrades();

        }
        setLoading(false);
    }

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);


    useEffect(() => {
        fetchTrades();
    }, []);

    return (
        <div>
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (

                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">
                        <div className="drag-drop-container">
                            <TradesTable trades={trades} adoptTrade={adoptTrade} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default TradeListPage;