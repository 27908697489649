import { NotificationContext } from 'components/common/Notifications/NotificationProvider';
import UserContext from 'Context/UserProvider';
import { useContext, useState } from 'react';
import { v4 } from 'uuid';
import Map from '../../common/MapComponent/Map';

function AddNewLocationPage(props: any) {
    const [enableButton, setEnableButton] = useState(false)
    const [enableChangeLocation, setEnableChangeLocation] = useState(false)
    const [selectedLocationDwelling, setSelectedLocationDwelling] = useState() as any
    const notificationContext = useContext(NotificationContext)
    const {userInfo} = useContext(UserContext)
    const [selectGeoLocation] = useState() as any
    async function createLocation(fleetDwelling: any, name: any) {
        let bodyData: { [name: string]: string } = {}
        //Variable for fetch request
        let userID = userInfo.uuid
        let fleetUuid = userInfo.fleetUuid
        bodyData['uuid'] = userID
        bodyData['fleetUuid'] = fleetUuid
        bodyData['fleetDwelling'] = { ...fleetDwelling, name: name }
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
        let fetch_link = `${rootUrl}/infrastructure/dwelling/create`
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        }

        let response = (await fetch(fetch_link, fetch_option))
        let res_json = await response.json()
        // setShowCategory([true, false])
    }
    async function saveLocation() {
        let inputEl = document.getElementById('location-name')
        let inputVal = (inputEl as HTMLInputElement)!.value as string

        setSelectedLocationDwelling((prev: any) => ({ ...prev, name: inputVal }))
        createLocation(selectedLocationDwelling, inputVal)
        notificationContext.dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
                id: v4(),
                type: 'SUCCESS',
                message: `${inputVal} has been added to your infrastructure location`
            }
        })
        inputVal = ''
        setEnableChangeLocation(prev => !prev)
    }
    return (
        <div>
            <h1>Add Location</h1>
            <Map selectedLocationDwelling={[selectedLocationDwelling, setSelectedLocationDwelling]} enableChangeLocation={enableChangeLocation} enableButton={[enableButton, setEnableButton]} vehicleMapData={[]} partnerLocations={[]} height={'65vh'} >
                {enableChangeLocation ?
                    <div className="alter_location_container">
                        {enableButton ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: 20 }} >
                                    <input type="text" placeholder="Enter your location name" name="location-name" id="location-name" />
                                </div>
                                <div className="alter_location_content">
                                    <div className="location_container">
                                        <h2>New Location</h2>
                                        <p>{selectGeoLocation}</p>
                                    </div>
                                </div>

                            </>
                            :
                            <></>}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button className="primary-btn" disabled={!enableButton} onClick={() => saveLocation()}>Save Location</button>
                            <button className="primary-btn" onClick={() => setEnableChangeLocation(prev => !prev)}>Cancel</button>
                        </div>
                    </div>
                    :
                    <button className="primary-btn" onClick={() => setEnableChangeLocation(prev => !prev)}>Add New Location</button>}
            </Map>
        </div>
    );
}

export default AddNewLocationPage;