import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';


function BuildQuotePage(props: any) {

    const [agreement, setAgreement] = useState([]) as any
    const [loading, setLoading] = useState<boolean>(true);
 
    useEffect(() => {
       setAgreement(null);
       setLoading(false);
    }, []);

    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                agreement == null ? (
                    <div><span>No Power Purchase Agreement has been set up - please click <Link to="/agree/ppa/quote/build/vehicles">Build PPA Quote</Link> to start</span></div>
                ) : (
                <div>
asdfasdf
                </div >
                )
            )
            }
        </>
    );
}

export default BuildQuotePage;
