import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import UserContext from "../../Context/UserProvider";
import ScenarioSummaryBarChart from "../../components/fleet/FleetInsightComponent/ScenarioOverviewCompnents/ScenarioSummaryBarChart";
import ScenarioSummaryPieChart from "../../components/fleet/FleetInsightComponent/ScenarioOverviewCompnents/ScenarioSummaryPieChart";

function FleetScenarioSummaryPage(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const location = useLocation() as any;
  const fleetScenarioUuid = location.state.fleetScenarioUuid;
  const fleetScenarioNumber = location.state.fleetScenarioNumber;
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [parameter, setParameter] = useState({ evParameters: {} as any, iceParameters: {} as any }) as any;
  const [fleetScenarioSummaryData, setFleetScenarioSummaryData] = useState() as any;

  async function fetchParameter() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/parameters/get`;
    let response = await fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    setParameter({ evParameters: res_json.evParameters, iceParameters: res_json.iceParameters });

    // dispatch({ input: "evCombinedRangeFactor", value: +res_json.evParameters.evCombinedRangeFactor * 100 })
    // dispatch({ input: "circuitCostSinglePhaseInGBP", value: +res_json.evParameters.circuitCostSinglePhaseInGBP })
    // dispatch({ input: "circuitCost3PhaseInGBP", value: +res_json.evParameters.circuitCost3PhaseInGBP })
    // dispatch({ input: "circuitCostDCInGBP", value: +res_json.evParameters.circuitCostDCInGBP })
    // dispatch({ input: "operationalCostPerHourInGbp", value: +res_json.evParameters.operationalCostPerHourInGbp })
    // dispatch({ input: "pricePerLitreDiesel", value: +res_json.iceParameters.pricePerLitreDiesel })
    // dispatch({ input: "pricePerLitrePetrol", value: +res_json.iceParameters.pricePerLitrePetrol })
    // dispatch({ input: "comparisonType", value: res_json.evParameters.comparisonType })

    // setIsLoading(false)
  }
  async function fetchScenario() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/summary/get`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    // res_json.scenarios.map((e: any) => {
    //     if (e.status === "IN_PROGRESS") {
    //         setRunningScenarioUuid(e.uuid)
    //         setStatusPolling(true)
    //     }
    // }
    // )
    res_json.categoryPercentages = res_json.categoryPercentages.map((e: any) => ({ ...e, percentage: Math.round(e.percentage * 100) }));
    setFleetScenarioSummaryData(res_json);
    setLoading(false);
  }
  useEffect(() => {
    fetchScenario();
    fetchParameter();
  }, []);
  return (
    <>
      {/* <BackButton /> */}
      {loading ? (
        <>Loading..... </>
      ) : (
        <div
          style={{ overflowX: "hidden", padding: 20, gap: 5, justifyContent: "space-between" }}
          className="row">
          {/* <BackButton /> */}
            
          <div
            className="row"
            style={{ padding: 0, margin: 0, justifyContent: "space-between" }}>
            <div className="col-xs-12 col-md-6 col-xl-4">
              <div
                className="dashboard-card"
                style={{ flex: 1 }}>
                <div className="dashboard-card-title">
                  <span className="icon material-symbols-outlined">summarize</span>Scenario Overview
                </div>
                <div
                  className="dashboard-card-content"
                  style={{ gap: 10 }}>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{width: 240 }}>
                      Number of Vehicles :
                    </span>
                    <span className="data">{Math.floor(fleetScenarioSummaryData.numberOfVehicles)} </span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{width: 240 }}>
                      Total distance driven :{" "}
                    </span>
                    <span className="data">
                      {Math.floor(fleetScenarioSummaryData.totalDistance).toLocaleString()} {userInfo.userProfile.distanceUnitShortDisplayName}{" "}
                    </span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{width: 240 }}>
                      Average distance per day :{" "}
                    </span>
                    <span className="data">
                      {Math.floor(fleetScenarioSummaryData.averageDistancePerDay).toLocaleString()} {userInfo.userProfile.distanceUnitShortDisplayName}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-xl-4">
              <div
                className="dashboard-card"
                style={{ flex: 1 }}>
                <div className="dashboard-card-title">
                  <span className="icon material-symbols-outlined">
                    <span className="material-symbols-outlined">location_on</span>
                  </span>
                  Top 5 Potential Charging Locations
                </div>
                <div
                  className="dashboard-card-content"
                  style={{ gap: 5 }}>
                  {fleetScenarioSummaryData.potentialLocations.map((e: any, index: any) => {
                    return (
                      <div className="box-info">
                        <span
                          className="item"
                          key={index}>
                          {e}{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-xl-4">
              <div
                className="dashboard-card"
                style={{ flex: 1 }}>
                <div className="dashboard-card-title">
                  <span className="icon material-symbols-outlined">summarize</span>Scenario Parameters
                </div>
                <div
                  className="dashboard-card-content"
                  style={{ gap: 10 }}>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      WLTP Factor :
                    </span>
                    <span className="data"> {`${parameter.evParameters.evCombinedRangeFactor * 100}%`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      Single Phase Cost Per kWh :
                    </span>
                    <span className="data"> {`£${parameter.evParameters.publicSinglePhaseCost.toFixed(2)}`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      Three Phase Cost Per kWh :
                    </span>
                    <span className="data"> {`£${parameter.evParameters.publicThreePhaseCost.toFixed(2)}`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      DC Cost Per kWh :
                    </span>
                    <span className="data"> {`£${parameter.evParameters.publicDcCost.toFixed(2)}`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      Operational Cost Of EnRoute Charging Per Hour :
                    </span>
                    <span className="data"> {`£${parameter.evParameters.operationalCostPerHourInGbp.toFixed(2)}`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      Diesel Price Cost Per Litre :
                    </span>
                    <span className="data"> {`£${parameter.iceParameters.pricePerLitreDiesel.toFixed(2)}`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      Petrol Price Cost Per Litre :
                    </span>
                    <span className="data"> {`£${parameter.iceParameters.pricePerLitrePetrol.toFixed(2)}`}</span>
                  </div>
                  <div className="box-info">
                    <span
                      className="item"
                      style={{ width: 330 }}>
                      Include Locations :
                    </span>
                    <span className="data"> {`${parameter.evParameters.comparisonTypeDisplayName}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ScenarioSummaryPieChart
            total={fleetScenarioSummaryData.numberOfVehicles}
            unit={"Total Vehicles"}
            fleetScenarioUuid={fleetScenarioUuid}
            fleetScenarioNumber={fleetScenarioNumber}
            title={"Percentage of ICE Vehicles in each Recommendation Category"}
            height={400}
            width={400}
            data={fleetScenarioSummaryData.categoryPercentages}
          />
          <ScenarioSummaryPieChart
            total={fleetScenarioSummaryData.totalJourneys}
            fleetScenarioUuid={fleetScenarioUuid}
            unit={"Annual Journeys"}
            title={"Percentage of Journeys not requiring an enroute charge"}
            height={400}
            width={400}
            data={[
              { name: "Zero Charges", percentage: Math.round(fleetScenarioSummaryData.percentageJourneysNotRequiringEnRouteCharge * 100) },
              { name: "Multiple Charges", percentage: Math.round(fleetScenarioSummaryData.percentageJourneysRequiringMultiCharge * 100) },
              { name: "Single Charges", percentage: Math.round(fleetScenarioSummaryData.percentageJourneysRequiringSingleCharge * 100) },
            ]}
          />
          <ScenarioSummaryBarChart
            fleetScenarioUuid={fleetScenarioUuid}
            data={[
              { name: "Electricity", price: Math.round(fleetScenarioSummaryData.annualCostSavingElectricity) },
              { name: "Fuel", price: Math.round(fleetScenarioSummaryData.annualCostSavingFuel) },
            ]}
          />
        </div>
      )}
    </>
  );
}

export default FleetScenarioSummaryPage;
