import { useNavigate } from 'react-router';
import "./BackButton.scss";
function BackButton(props: any) {
    const navigate = useNavigate()
    return (
        <span className="material-symbols-outlined back-button" onClick={() => navigate(-1)}>
            arrow_circle_left
        </span>
    );
}

export default BackButton;


