import Modal from 'react-modal';

// import 'semantic-ui-css/semantic.min.css'

function LoaderModal(props: any) {
    // const [modalIsOpen, setIsOpen] = props.modalIsOpen;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            backgroundColor: 'transparent',
            zIndex: 999999,
            fontFamily: 'Poppins , sans-serif',
            transform: 'translate(-50%, -50%)',
            minWidth: '500px',
            borderWidth: 0,
            display: 'flex',
            justifyContent: "center",
            // alignContent: "center",
            borderRadius: 20,
            paddingBottom: 80,

            // boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)",
        },
    };
    // function openModal() {
    //     setIsOpen(true);
    // }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    // function closeModal() {
    //     setIsOpen(false);
    // }
    return (
        <div style={{ position: 'absolute' }}>
            <Modal
                isOpen={true}
                onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                appElement={document.getElementById('root') || undefined}
                contentLabel="Example Modal"
                style={customStyles}
            >

                {/* <Spinner animation="border" /> */}
            </Modal>
        </div>
    );
}

export default LoaderModal;