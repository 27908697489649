import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { checking_null_undefined } from "utils";

function ChargePointListTable(props: any) {

    const data = checking_null_undefined(props.chargePointData.properties.connectors);

    const columns: GridColDef[] = [
        {
            field: "uuid",
            renderHeader: (params) => {
                // Your custom render logic here
                return (
                    <div style={{ fontWeight: 'bold' }}>
                        {`Charge Point : ${props.chargePointData.properties.label}`}
                    </div>
                );
            }, sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
            renderCell: (cell) => {
                return (
                    <span>
                        {cell.row.connectorTypeAsString}
                    </span>
                );
            },
        },
        {
            field: "ocppConnectorId",
            headerName: "Connector ID",
            sortable: false,
            width: 150,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "rateKW",
            headerName: "Rate (KW)",
            sortable: false,
            width: 150,
            align: "center",
            headerAlign: "center",
        },

        {
            field: "status",
            headerName: "Connector Status",
            sortable: false,
            width: 150,
            align: "center",
            headerAlign: "center",
        },
        //    {
        //   field: "speed",
        //   headerName: "Speed",
        //   sortable: false,
        //   width: 150,
        //   align: "left",
        //   headerAlign: "center",
        // },

        // {
        //   field: "status",
        //   headerName: "Status",
        //   sortable: false,
        //   width: 150,
        //   align: "center",
        //   headerAlign: "center",
        // },
        // {
        //   field: "availability",
        //   headerName: "See Availability",
        //   sortable: false,
        //   width: 150,
        //   align: "right",
        //   headerAlign: "center",
        // },


    ];

    return (
        <div className="table-box">
            <DataGrid
                width={"100%"}
                rows={data}
                autoHeight
                {...data}
                columns={columns}
                getRowId={(row) => row.uuid}
                pageSize={15}
                disableColumnMenu
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center">
                            <span>No Connectors have been configured.</span>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center">
                            Selected filter returned no results
                        </Stack>
                    ),
                }}
                fontFamily={"Arvo"}></DataGrid>
        </div>
    );
}

export default ChargePointListTable;
