import ScenarioConfigContext from "Context/ScenarioConfigContext";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import Select from "react-select";
import { isReadOnlyRole } from "utils/roleUtils";
import UserContext from "../../../../Context/UserProvider";
import "./ConfigureTabScreen.css";

const initialState = {
    businessHomeSinglePhaseCost: 0,
    businessDwellingDcCost: 0,
    businessDwellingThreePhaseCost: 0,
    businessDwellingSinglePhaseCost: 0,
    publicSinglePhaseCost: 0,
    publicThreePhaseCost: 0,
    publicDcCost: 0,
    evCombinedRangeFactor: 0,
    virtualChargingHubDcCost: 0,
    virtualChargingHubSinglePhaseCost: 0,
    virtualChargingHubThreePhaseCost: 0,
    pricePerLitrePetrol: 0,
    pricePerLitreDiesel: 0,
    operationalCostPerHourInGbp: 0,
    comparisonType: "",
};

function reducer(state: any, action: any) {
    switch (action.input) {
        case "businessHomeSinglePhaseCost":
            return { ...state, businessHomeSinglePhaseCost: +action.value };
        case "businessDwellingDcCost":
            return { ...state, businessDwellingDcCost: +action.value };
        case "businessDwellingThreePhaseCost":
            return { ...state, businessDwellingThreePhaseCost: +action.value };
        case "businessDwellingSinglePhaseCost":
            return { ...state, businessDwellingSinglePhaseCost: +action.value };
        case "publicDcCost":
            return { ...state, publicDcCost: +action.value };
        case "publicThreePhaseCost":
            return { ...state, publicThreePhaseCost: +action.value };
        case "publicSinglePhaseCost":
            return { ...state, publicSinglePhaseCost: +action.value };
        case "virtualChargingHubDcCost":
            return { ...state, virtualChargingHubDcCost: +action.value };
        case "virtualChargingHubThreePhaseCost":
            return { ...state, virtualChargingHubThreePhaseCost: +action.value };
        case "virtualChargingHubSinglePhaseCost":
            return { ...state, virtualChargingHubSinglePhaseCost: +action.value };
        case "evCombinedRangeFactor":
            return { ...state, evCombinedRangeFactor: +action.value };
        case "operationalCostPerHourInGbp":
            return { ...state, operationalCostPerHourInGbp: +action.value };
        case "pricePerLitrePetrol":
            return { ...state, pricePerLitrePetrol: +action.value };
        case "pricePerLitreDiesel":
            return { ...state, pricePerLitreDiesel: +action.value };
        case "comparisonType":
            return { ...state, comparisonType: action.value };
        default:
            return state;
    }
}

function ConfigureTabScreen(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [scenarioConfigInfo, setScenarioConfigInfo] = useContext(ScenarioConfigContext) as any;
    const fleetScenarioUuid = scenarioConfigInfo.draftScenario?.uuid;
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isError, setIsError] = useState(false);
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const reference = [
        { label: "None", value: "INFRASTRUCTURE_NONE", name: "comparisonType" },
        { label: "Actual", value: "INFRASTRUCTURE_ACTUAL", name: "comparisonType" },
    ];

    function onChange(e: any) {
        let action: any;
        if (e.target) {
            action = {
                input: e.target.name,
                value: e.target.value,
            };
        } else {
            action = {
                input: e.name,
                value: e.value,
            };
        }
        dispatch(action);
    }

    async function fetchParameters() {
        setIsLoading(true);
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/parameters/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        dispatch({ input: "evCombinedRangeFactor", value: +res_json.evParameters.evCombinedRangeFactor * 100 });
        dispatch({ input: "publicDcCost", value: +res_json.evParameters.publicDcCost });
        dispatch({ input: "publicThreePhaseCost", value: +res_json.evParameters.publicThreePhaseCost });
        dispatch({ input: "publicSinglePhaseCost", value: +res_json.evParameters.publicSinglePhaseCost });
        dispatch({ input: "virtualChargingHubDcCost", value: +res_json.evParameters.virtualChargingHubDcCost });
        dispatch({ input: "virtualChargingHubThreePhaseCost", value: +res_json.evParameters.virtualChargingHubThreePhaseCost });
        dispatch({ input: "virtualChargingHubSinglePhaseCost", value: +res_json.evParameters.virtualChargingHubSinglePhaseCost });
        dispatch({ input: "businessDwellingDcCost", value: +res_json.evParameters.businessDwellingDcCost });
        dispatch({ input: "businessDwellingThreePhaseCost", value: +res_json.evParameters.businessDwellingThreePhaseCost });
        dispatch({ input: "businessDwellingSinglePhaseCost", value: +res_json.evParameters.businessDwellingSinglePhaseCost });
        dispatch({ input: "businessHomeSinglePhaseCost", value: +res_json.evParameters.businessHomeSinglePhaseCost });
        dispatch({ input: "operationalCostPerHourInGbp", value: +res_json.evParameters.operationalCostPerHourInGbp });
        dispatch({ input: "pricePerLitreDiesel", value: +res_json.iceParameters.pricePerLitreDiesel });
        dispatch({ input: "pricePerLitrePetrol", value: +res_json.iceParameters.pricePerLitrePetrol });
        dispatch({ input: "comparisonType", value: res_json.evParameters.comparisonType });

        setIsLoading(false);
    }
    async function resetParameters() {
        fetchParameters();
        // setInputFactor(res_json.parameters["evCombinedRangeFactor"] * 100)
    }

    async function updateParameters() {
        let bodyData: { [name: string]: any } = {};
        setIsDisabled(true);
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
        bodyData["evParameters"] = {
            evCombinedRangeFactor: state.evCombinedRangeFactor / 100,
            publicDcCost: state.publicDcCost,
            publicThreePhaseCost: state.publicThreePhaseCost,
            publicSinglePhaseCost: state.publicSinglePhaseCost,
            virtualChargingHubDcCost: state.virtualChargingHubDcCost,
            virtualChargingHubThreePhaseCost: state.virtualChargingHubThreePhaseCost,
            virtualChargingHubSinglePhaseCost: state.virtualChargingHubSinglePhaseCost,
            businessDwellingDcCost: state.businessDwellingDcCost,
            businessDwellingThreePhaseCost: state.businessDwellingThreePhaseCost,
            businessDwellingSinglePhaseCost: state.businessDwellingSinglePhaseCost,
            businessHomeSinglePhaseCost: state.businessHomeSinglePhaseCost,
            operationalCostPerHourInGbp: state.operationalCostPerHourInGbp,
            comparisonType: state.comparisonType,
        };
        bodyData["iceParameters"] = {
            pricePerLitrePetrol: state.pricePerLitrePetrol,
            pricePerLitreDiesel: state.pricePerLitreDiesel,
        };
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/parameters/update`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();
        setIsDisabled(false);
    }

    // useEffect(() => {
    // }, [state])
    // useEffect(() => {
    //     if (currentInput !== undefined) {

    //         currentInput.current.value = inputFactor * 100
    //     }

    // }, [inputFactor])
    useEffect(() => {
        fetchParameters();
    }, []);
    return (
        <>
            {isLoading ? (
                <>Loading ...</>
            ) : (
                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Scenario Parameters
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            WLTP Factor :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.evCombinedRangeFactor}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="evCombinedRangeFactor"
                                                id="evCombinedRangeFactor"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>%</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Operational Cost of EnRoute Charging per Hour (GBP) :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "right", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.operationalCostPerHourInGbp.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="operationalCostPerHourInGbp"
                                                id="operationalCostPerHourInGbp"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Diesel Price Cost Per Litre (GBP):
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.pricePerLitreDiesel.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="pricePerLitreDiesel"
                                                id="pricePerLitreDiesel"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Petrol Price Cost Per Litre (GBP):
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.pricePerLitrePetrol.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="pricePerLitrePetrol"
                                                id="pricePerLitrePetrol"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Include Locations :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "168px" }}>
                                            <Select
                                                defaultValue={reference.filter((i: any) => i.value === state.comparisonType)[0]}
                                                name="comparisonType"
                                                // label="Single select"
                                                onChange={onChange}
                                                options={reference}
                                            // styles={colourStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Home Energy Costs
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Single Phase Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.businessHomeSinglePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="businessHomeSinglePhaseCost"
                                                id="businessHomeSinglePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Virtual Charging Hub Energy Costs
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Single Phase Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.virtualChargingHubSinglePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="virtualChargingHubSinglePhaseCost"
                                                id="virtualChargingHubSinglePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Three Phase Cost Per kWh
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.virtualChargingHubThreePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="virtualChargingHubThreePhaseCost"
                                                id="virtualChargingHubThreePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            DC Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.virtualChargingHubDcCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="virtualChargingHubDcCost"
                                                id="virtualChargingHubDcCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Business Energy Costs (En Route and Inter-journey)
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Single Phase Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.businessDwellingSinglePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="businessDwellingSinglePhaseCost"
                                                id="businessDwellingSinglePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Three Phase Cost Per kWh
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.businessDwellingThreePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="businessDwellingThreePhaseCost"
                                                id="businessDwellingThreePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            DC Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.businessDwellingDcCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="businessDwellingDcCost"
                                                id="businessDwellingDcCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Public Charging Energy Costs
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Single Phase Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.publicSinglePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="publicSinglePhaseCost"
                                                id="publicSinglePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            Three Phase Cost Per kWh
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.publicThreePhaseCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="publicThreePhaseCost"
                                                id="publicThreePhaseCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 400 }}>
                                            DC Cost Per kWh:
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <input
                                                ref={currentInput}
                                                defaultValue={state.publicDcCost.toFixed(2)}
                                                type="text"
                                                style={{ textAlign: "right" }}
                                                className="textbox"
                                                name="publicDcCost"
                                                id="publicDcCost"
                                                onChange={onChange}
                                            />
                                            <span style={{ marginLeft: 10 }}>£</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isReadOnlyRole(userInfo.userType) ?

                            <div
                                    className="dashboard-card-actions"
                                    style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                    <button
                                        className="primary-btn"
                                        onClick={() => resetParameters()}>
                                        Reset
                                    </button>
                                    <button
                                        className="primary-btn"
                                        disabled={isDisabled}
                                        onClick={updateParameters}>
                                        Save All
                                    </button>
                                </div>
                            :""}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ConfigureTabScreen;
