import UserContext from "Context/UserProvider";
import { ChargingPartner } from "components/charging/BusinessChargingNetworkParameters/chargingPartner";
import DriverInstructionsPanel from "components/charging/ChargingPartnerComponent/DriverInstructionsPanel";
import PartnershipContractualDocumentsPanel from "components/charging/ChargingPartnerComponent/PartnershipContractualDocumentsPanel";
import { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from "react-router-dom";
import WeeklyChargingReservation from "../../components/charging/BusinessChargingNetworkParameters/WeeklyChargerReservation";
import { WeekDay, weekDays as initialWeekDays } from "../../components/charging/BusinessChargingNetworkParameters/weekDays";

function ChargingPartnerReviewOfferPage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);

    const defaultOffer: ChargingPartner = {
        uuid: "",
        siteAccessRestriction: false,
        ramsDocuments: [],
        questions: [],
        reservationOption: "",
        usageRestriction: false,
        chargersReservedFromHour: "",
        chargersReservedToHour: "",
        energyCostAcSinglePhaseGbpPerKWhOffPeak: 0,
        energyCostAcThreePhaseGbpPerKWhOffPeak: 0,
        energyCostDcGbpPerKWhOffPeak: 0,
        energyCostAcSinglePhaseGbpPerKWhPeak: 0,
        energyCostAcThreePhaseGbpPerKWhPeak: 0,
        energyCostDcGbpPerKWhPeak: 0,
        partnerUuid: "",
        businessUuid: "",
        businessName: "",
        chargingPartnerBusinessUuid: "",
        siteAccessPolicyUuid: "",
        siteAccessPolicyName: "",
        workflowState: "",
        visitorWorkflowState: "",
        energyPriceDcGbpPerKWhOffPeak: 0,
        energyPriceAcThreePhaseGbpPerKWhOffPeak: 0,
        energyPriceAcSinglePhaseGbpPerKWhOffPeak: 0,
        energyPriceDcGbpPerKWhPeak: 0,
        energyPriceAcThreePhaseGbpPerKWhPeak: 0,
        energyPriceAcSinglePhaseGbpPerKWhPeak: 0,
        dayOfWeekReservation: [],
        selectedRateCard: {

            chargingPartnerCount: 0,
            energyCostAcSinglePhaseGbpPerKWhOffPeak: 0,
            energyCostAcSinglePhaseGbpPerKWhPeak: 0,
            energyCostAcThreePhaseGbpPerKWhOffPeak: 0,
            energyCostAcThreePhaseGbpPerKWhPeak: 0,
            energyCostDcGbpPerKWhOffPeak: 0,
            energyCostDcGbpPerKWhPeak: 0,
            name: "",
            uuid: "",
            initial: false

        },
        rateCards: [],
        selectedFrequency: "",
        approved: false,
        requested: false,
        chargingPartnerLocations: [],
        businessLocations: [],


    };

    const [chargingPartnerOffer, setChargingPartnerOffer] = useState<ChargingPartner>(defaultOffer);
    const location = useLocation();
    const params = location.state as any;
    const [isDisabled, setIsDisabled] = useState(false);

   const [weekDays, setWeekDays] = useState(initialWeekDays);

    // Accessing the state passed through navigation
    const state = location.state as { chargingPartnerUuid: "", chargingPartnerBusinessName: string, workflowState: string, chargingPartnerBusinessUuid: string, businessUuid: string, requestType: string };

    // Now you can use state.chargingPartnerUuid and state.chargingPartnerBusinessName
    // For example:
    const chargingPartnerUuid = state?.chargingPartnerUuid;
    const workflowState = state?.workflowState;
    const chargingPartnerBusinessUuid = state?.chargingPartnerBusinessUuid;
    const businessUuid = state?.businessUuid;
    const requestType = state?.requestType;
    const navigate = useNavigate();



    const handleTimeChange = (dayIndex: any, field: any, value: any) => {
        setWeekDays(prevDays =>
            prevDays.map((day, index) =>
                index === dayIndex
                    ? { ...day, [field]: value }
                    : day
            )
        );
    };

    useEffect(() => {
        fetchOffer(businessUuid, chargingPartnerBusinessUuid, chargingPartnerUuid);
    }, []);

    async function fetchOffer(businessUuid: string, chargingPartnerBusinessUuid: string, chargingPartnerUuid: string) {
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["workflowState"] = workflowState;
        bodyData["businessUuid"] = businessUuid;
        bodyData["chargingPartnerBusinessUuid"] = chargingPartnerBusinessUuid;
        bodyData["chargingPartnerUuid"] = chargingPartnerUuid;



        let fetch_link = `${rootUrl}/charging-partner/offer/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();
        
        setChargingPartnerOffer(res_json.chargingPartner.supplier);
        setWeekDays(res_json.chargingPartner.supplier.dayOfWeekReservation);

        setLoading(false);
    }




    async function approve() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = businessUuid;
        bodyData["chargingPartnerBusinessUuid"] = chargingPartnerBusinessUuid;


        let fetch_link = `${rootUrl}/charging-partner/offer/decision/approve`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {
            setIsDisabled(false);
            toast.success('Charging Partner Approved', {
                position: 'bottom-center',
                duration: 5000,
            });

            navigate("/share/business/charging-partners");

        }


    }

    async function reject() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = businessUuid;
        bodyData["chargingPartnerUuid"] = chargingPartnerUuid;

        let fetch_link = `${rootUrl}/charging-partner/offer/decision/reject`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {
            setIsDisabled(false);
            toast.success('Charging Partner Rejected', {
                position: 'bottom-center',
                duration: 5000,
            });

            navigate("/share/business/charging-partners");
        }
    }

    async function withdraw() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = businessUuid;
        bodyData["chargingPartnerBusinessUuid"] = chargingPartnerBusinessUuid;

        let fetch_link = `${rootUrl}/charging-partner/offer/withdraw`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {
            setIsDisabled(false);
            toast.success('Charging Partner Offer Withdrawn', {
                position: 'bottom-center',
                duration: 5000,
            });

            navigate("/share/business/charging-partners");

        }
    }
    return (
        <>

            {loading ? (
                <>Loading ...</>
            ) : (
                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Charging Restrictions
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    {!chargingPartnerOffer.usageRestriction ? (
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                No charging time restrictions
                                            </span>
                                            <span className="data">{chargingPartnerOffer.chargersReservedFromHour}</span>

                                        </div>

                                    ) : (
                                        chargingPartnerOffer.reservationOption === "DEFAULT" ? (


                                            <>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        Reservation Option :
                                                    </span>
                                                    <span className="data">{chargingPartnerOffer.reservationOption}</span>

                                                </div>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        No Charging From :
                                                    </span>
                                                    <span className="data">{chargingPartnerOffer.chargersReservedFromHour}</span>

                                                </div>

                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        No Charging Until :
                                                    </span>
                                                    <span className="data">{chargingPartnerOffer.chargersReservedToHour}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                {
                                                    weekDays.map((dayInfo: WeekDay) => (
                                                        <WeeklyChargingReservation readOnly={true} key={dayInfo.dayIndex} dayOfWeekLabel={dayInfo.day} dayInfo={dayInfo} onTimeChange={handleTimeChange} />
                                                    ))
                                                }
                                            </div>
                                        ))}

                                </div>
                            </div>
                        </div>


                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    {state.requestType === 'FROM'
                                        ? `Charging Cost at ${params.chargingPartnerBusinessName} locations per kW/h (Off-Peak)`
                                        : 'Off-Peak prices'}
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            AC Single Phase :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>
                                            <span className="data">{chargingPartnerOffer.energyCostAcSinglePhaseGbpPerKWhOffPeak}</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            AC Three Phase :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data">{chargingPartnerOffer.energyCostAcThreePhaseGbpPerKWhOffPeak}</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            DC :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data">{chargingPartnerOffer.energyCostDcGbpPerKWhOffPeak}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                            <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    {state.requestType === 'FROM'
                                        ? `Charging Cost at ${params.chargingPartnerBusinessName} locations per kW/h (Off-Peak)`
                                        : 'Peak prices'}
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            AC Single Phase :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data">{chargingPartnerOffer.energyCostAcSinglePhaseGbpPerKWhPeak}</span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            AC Three Phase :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data">{chargingPartnerOffer.energyCostAcThreePhaseGbpPerKWhPeak}</span>

                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            DC :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data">{chargingPartnerOffer.energyCostDcGbpPerKWhPeak}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12">
                            <PartnershipContractualDocumentsPanel chargingPartnerOffer={chargingPartnerOffer} panelTitle=" Partnership / Contractual Documents" type="DOCUMENT" />
                        </div>

                        <div className="col-xs-12">
                            <PartnershipContractualDocumentsPanel chargingPartnerOffer={chargingPartnerOffer} panelTitle="Instructional Videos" type="VIDEO" />
                        </div>

                        <div className="col-xs-12">
                            <DriverInstructionsPanel chargingPartnerOffer={chargingPartnerOffer} />
                        </div>


                        {requestType === "TO" ? (


                            <div className="col-xs-12 col-md-12 col-xl-12">
                                <div className="">

                                    <div
                                        style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                        <button
                                            className="primary-btn"
                                            disabled={isDisabled}
                                            onClick={withdraw}
                                        >
                                            Withdraw
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : requestType === "FROM" ? (<div className="col-xs-12 col-md-12 col-xl-12">
                            <div className="">

                                <div
                                    style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                    <button
                                        className="primary-btn"
                                        disabled={isDisabled}
                                        onClick={approve}
                                    >
                                        Approve
                                    </button>

                                    <button
                                        className="primary-btn"
                                        disabled={isDisabled}
                                        onClick={reject}
                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                        ) : (<></>)}

                    </div>
                </div>
            )
            }
        </>
    );

}
export default ChargingPartnerReviewOfferPage;