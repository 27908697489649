import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import Map from "../../components/common/MapComponent/Map";

function ChargingPartnerMapPage(props: any) {
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    interface LatLng {
        lat: number;
        lng: number;
    }


    interface InsightData {
        virtualChargingHubsData: []; // Replace 'SomeType' with the actual type for this data
        chargingPartnerPolygons: [];
        businessPolygons: [],
        businessLocations: [];
        diverts: [];
    }

    const [insightData, setInsightData] = useState<InsightData>({
        virtualChargingHubsData: [],
        chargingPartnerPolygons: [],
        businessPolygons: [],
        businessLocations: [],
        diverts: [],
    });
    const [enableButton, setEnableButton] = useState(false);

    async function fetchChargingPartnerMap() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/charging-partner/map/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        res_json.virtualChargingHubList = isNull(res_json.chargingPartnerDwellings);

        setVirtualChargingHubs(res_json);

        setLoading(false);
    }

    function isNull(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    function setVirtualChargingHubs(obj: any) {

        let charging_partner_network_map_data = isNull(obj.chargingPartnerDwellings);
        let chargingPartnerPolygons = obj.chargingPartnerPolygons;
        let businessPolygons = obj.businessPolygons;

        setInsightData({
            virtualChargingHubsData: charging_partner_network_map_data,
            chargingPartnerPolygons: chargingPartnerPolygons,
            businessPolygons: businessPolygons,
            businessLocations: isNull(obj.businessDwellings),
            diverts: obj.diverts,
        });

    }

    useEffect(() => {
        fetchChargingPartnerMap();
    }, []);


    if (loading) return <>loading...</>;
    return (

        <Map
            selectedLocationDwelling={[]}
            enableButton={[enableButton, setEnableButton]}
            vehicleMapData={[]}
            partnerLocations={insightData.virtualChargingHubsData}
            chargingPartnerPolygons={insightData.chargingPartnerPolygons}
            businessPolygons={insightData.businessPolygons}
            showButtons={true}
            businessLocations={insightData.businessLocations}
            diverts={insightData.diverts}
        />
    );
}

export default ChargingPartnerMapPage;