import BusinessContext from "Context/BusinessContext";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";
import UserContext from "../../Context/UserProvider";
import { NotificationContext } from "../../components/common/Notifications/NotificationProvider";
import HourHeatMap from "../../components/fleet/HeatMap/HourHeatMap";
import "./DwellingDetailPage.scss";

function DwellingDetailPage(props: any) {
  const [hourHeatMapData, setHourHeatMapData] = useState([]) as any;
  const [selectWeekData, setSelectWeekData] = useContext(BusinessContext) as any;
  const notificationContext = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState([]) as any;
  const [daysLabels, setDayLabels] = useState([]) as any;
  const [selectGeoLocation, setSelectGeoLocation] = useState("") as any;
  const location = useLocation();
  const [selectedLocationDwelling] = useState() as any;
  const params: any = location.state;
  const {userInfo} = useContext(UserContext);
  const [activeMenu, setActiveMenu] = useState(selectWeekData ? selectWeekData.weekNumber : 0);

  async function getGeoCode(fleetDwelling: any) {
    let bodyData: { [name: string]: string } = {};
    //Variable for fetch request
    let userID = userInfo.uuid;
    let fleetUuid = userInfo.fleetUuid;
    bodyData["uuid"] = userID;
    bodyData["fleetUuid"] = fleetUuid;
    bodyData["fleetDwelling"] = fleetDwelling;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/infrastructure/dwelling/address/geocode`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };

    let response = await fetch(fetch_link, fetch_option);
    let res_json = await response.json();
    setSelectGeoLocation(res_json.addressLine);
  }
  async function updateLocation(fleetDwelling: any) {
    let bodyData: { [name: string]: string } = {};
    //Variable for fetch request
    let userID = userInfo.uuid;
    let fleetUuid = userInfo.fleetUuid;
    let dwellingID = params.value.uuid;
    fleetDwelling = { ...fleetDwelling, uuid: dwellingID };
    bodyData["uuid"] = userID;
    bodyData["fleetUuid"] = fleetUuid;
    bodyData["fleetDwelling"] = fleetDwelling;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/infrastructure/dwelling/update`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };

    let response = await fetch(fetch_link, fetch_option);
    let res_json = await response.json();
    notificationContext.dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        id: v4(),
        type: "SUCCESS",
        message: `Dwelling of address ${params.value.addressLine} has been changed to ${selectGeoLocation}`,
      },
    });
    params.value = {
      ...params.value,
      centreLatitude: fleetDwelling.centreLatitude,
      centreLongitude: fleetDwelling.centreLongitude,
      addressLine: selectGeoLocation,
    };
  }


  function fetchHourHeatMapData() {
    setLoading(true);
    let bodyData: { [name: string]: string } = {};
    let data_heat;
    let day_labels;
    bodyData["uuid"] = userInfo.uuid;
    bodyData["businessUuid"] = userInfo.businessUuid;
    bodyData["fleetDwellingUuid"] = params.fleetDwellingUuid;
    bodyData["operationalCostModelUuid"] = params.operationalCostModelUuid;
    bodyData["startDayKey"] = params.startDayKey;
    bodyData["fleetSnapshotDwellingUuid"] = params.fleetSnapshotDwellingUuid;
    
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/infrastructure/dwelling/detail/get`;
    fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      res.json().then((res_obj) => {
        //   data_heat = res_obj.heatMap['20211101'].map((e:any)=>{return e.hours.map((e:any)=>e.kwh)});
        setHourHeatMapData(res_obj.heatMap);

        data_heat = res_obj.heatMap[Object.keys(res_obj.heatMap)[0]].days.map((e: any) => {
          return e.hours.map((e: any) => ({
            kw: e.kw,
            vehicleCount: e.vehicleCount,
          }));
        });
        day_labels = res_obj.heatMap[Object.keys(res_obj.heatMap)[0]].daysOfWeek;
        setSelectedWeek(data_heat);
        setDayLabels(day_labels);
        setSelectWeekData({ dateOfWeek: Object.keys(res_obj.heatMap)[0], weekNumber: 0, dwellingUuid: params.fleetDwellingUuid, operationalCostModelUuid: params.operationalCostModelUuid, startDayKey: params.startDayKey, fleetSnapshotDwellingUuid: params.fleetSnapshotDwellingUuid });
        setLoading(false);
      })
    );
  }
  useEffect(() => {
    selectedLocationDwelling && getGeoCode(selectedLocationDwelling);
  }, [selectedLocationDwelling]);
  useEffect(() => {
    fetchHourHeatMapData();
  }, []);

  return (
    <div className="d-flex flex-column gap-5">
      {/* <BackButton /> */}
      {/* <Welcome /> */}
      {/* <Map
                selectedLocationDwelling={[selectedLocationDwelling, setSelectedLocationDwelling]}
                enableChangeLocation={enableChangeLocation}
                enableButton={[enableButton, setEnableButton]}
                partnerLocations={[{
                    addressLine: params.value.addressLine,
                    centreLatitude: params.value.centreLatitude,
                    centreLongitude: params.value.centreLongitude
                }]}
                vehicleMapData={[]}
                height={1000}
                center={{ lat: params.value.centreLatitude, lng: params.value.centreLongitude }}
                zoom={23}
            >

                {enableChangeLocation ?
                    <div className="alter_location_container">
                        {enableButton ? <div className="alter_location_content">
                            <div className="location_container">
                                <h2>Current Location</h2>
                                <p>{params.value.addressLine}</p>
                            </div>
                            to
                            <div className="location_container">
                                <h2>New Location</h2>
                                <p>{selectGeoLocation}</p>
                            </div>
                        </div> :
                            <></>}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button className="primary-btn" disabled={!enableButton} onClick={() => updateLocation(selectedLocationDwelling)}>Save Location</button>
                            <button className="primary-btn" onClick={() => setEnableChangeLocation(prev => !prev)}>Cancel</button>
                        </div>
                    </div>
                    :
                    <button className="primary-btn" onClick={() => (setEnableChangeLocation(prev => !prev))}>Change Location</button>}
            </Map> */}
      {loading ? (
        <>Loading ...</>
      ) : (
        <>
          <h1>Dwelling Time Heatmap</h1>
          <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
            {Object.keys(hourHeatMapData).map((key, i) => {
              return (
                <div
                  key={i}
                  style={{ display: "flex", gap: 10 }}>
                  <div className="vl"></div>
                  <button
                    className={`tab-btn ${activeMenu === i ? "active" : ""}`}
                    onClick={() => (
                      setSelectedWeek(
                        hourHeatMapData[key].days.map((e: any) => {
                          return e.hours.map((e: any) => ({
                            kw: e.kw,
                            vehicleCount: e.vehicleCount,
                          }));
                        })
                      ),
                      setSelectWeekData((prev: any) => ({ ...prev, dateOfWeek: key, weekNumber: i })),
                      setActiveMenu(i),
                      setDayLabels(hourHeatMapData[key].daysOfWeek)
                    )}>
                    {`${hourHeatMapData[key].days[0].day} | ${hourHeatMapData[key].days[0].weekCommencing}`}
                  </button>
                </div>
              );
            })}
            <div className="vl"></div>
          </div>

          <HourHeatMap data={selectedWeek} labels={daysLabels}/>
        </>
      )}
    </div>
  );
}

export default DwellingDetailPage;
