import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";

interface Question {
    text: string;
    type: string;
    id: string;
}

interface QuestionTableProps {
    data: Question[];
    onUpdate: (updatedData: Question[]) => void;
}

function QuestionTable(props: QuestionTableProps) {
    const { data, onUpdate } = props;

    const handleRowEdit = (updatedRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedData = data.map((question) =>
            question.id === updatedRow.id ? { ...question, text: updatedRow.text } : question
        );
        onUpdate(updatedData);
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: "text",
            headerName: "Instruction",
            editable: true,
            sortable: false,
            width: 800,
            align: "left",
            headerAlign: "left",
        },
    ];

    return (
        <div className="table-box">
            <DataGrid
                rows={data}
                getRowId={(row) => row.id}
                autoHeight
                columns={columns}
                disableColumnMenu
                initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15} } }}
                pageSizeOptions={[15]}
                disableRowSelectionOnClick
                checkboxSelection
                processRowUpdate={handleRowEdit} // Ensure updates are processed
            />
        </div>
    );
}

export default QuestionTable;
