import BusinessLocationsTable from "components/business/InfrastructureComponent/BusinessLocationsTable";
import HomeLocationsTable from "components/business/InfrastructureComponent/HomeLocationsTable";
import OpportunityLocationsTable from "components/business/InfrastructureComponent/OpportunityLocationsTable";
import VirtualChargingHubsTable from "components/business/InfrastructureComponent/VirtualChargingHubsTable";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";

function isNull(array: any) {
    if (array === null || array === undefined) {
      return [];
    }
    return array;
  }

function InfrastructurePage(props: any) {
    const {userInfo} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [businessLocationsData, setBusinessLocationsData] = useState([]) as any;
    const [virtualChargingHubsData, setVirtualChargingHubsData] = useState([]) as any;
    const [homeLocationsData, setHomeLocationsData] = useState([]) as any;
    const [opportunityLocationsData, setOpportunityLocationsData] = useState([]) as any;
    const [showCategory, setShowCategory] = useState([false, true, false, false]);
    const [activeMenu, setActiveMenu] = useState("Business Locations");
    
    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }


    async function fetchLocationData() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/infrastructure/dwelling/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();


        res_json.businessLocations = checking_null_undefined(res_json.businessLocations);
        console.log(res_json.businessLocations);
        res_json.virtualChargingHubs = checking_null_undefined(res_json.virtualChargingHubs);
        res_json.homeLocations = checking_null_undefined(res_json.homeLocations);
        res_json.opportunityLocations = checking_null_undefined(res_json.opportunityLocations);
        res_json.companyDwellings = isNull(res_json.companyDwellings);

        setBusinessLocationsData(res_json.businessLocations.map((e: any) => ({ ...e, id: e.uuid })));
        setVirtualChargingHubsData(res_json.virtualChargingHubs.map((e: any) => ({ ...e, id: e.uuid })));
        setHomeLocationsData(res_json.homeLocations.map((e: any) => ({ ...e, id: e.uuid, nearestPublicChargingDistance: e.nearestPublicCharging != null ? e.nearestPublicChargingDistance < 0.1 ? "< 0.1 " + userInfo.userProfile.distanceUnitShortDisplayName : parseInt(e.nearestPublicChargingDistance).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName : "" })));
        setOpportunityLocationsData(res_json.opportunityLocations.map((e: any) => ({ ...e, id: e.uuid, minDistance: e.minDistance != null ? e.minDistance < 1 ? "< 1 " + userInfo.userProfile.distanceUnitShortDisplayName : parseInt(e.minDistance).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName : "", totalTimeInMins: parseInt(e.totalTimeInMins).toLocaleString() + " mins", averageDwellTime: parseInt(e.averageDwellTime).toLocaleString() + " mins" })));
        setLoading(false);
    }

    useEffect(() => {
        fetchLocationData();
    }, []);

    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                    onClick={() => (setShowCategory([true, false, false, false, false, false]), setActiveMenu("All"))}>
                    All
                </button>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "Business Locations" ? "active" : ""}`}
                    onClick={() => (setShowCategory([false, true, false, false, false, false]), setActiveMenu("Business Locations"))}>
                    Business Locations
                </button>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "Virtual Charging Hubs" ? "active" : ""}`}
                    onClick={() => (setShowCategory([false, false, true, false, false, false]), setActiveMenu("Virtual Charging Hubs"))}>
                    Virtual Charging Hubs
                </button>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "Home Locations" ? "active" : ""}`}
                    onClick={() => (setShowCategory([false, false, false, true, false, false]), setActiveMenu("Home Locations"))}>
                    Home Locations
                </button>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "Opportunity Locations" ? "active" : ""}`}
                    onClick={() => (setShowCategory([false, false, false, false, true, false]), setActiveMenu("Opportunity Locations"))}>
                    Opportunity Locations
                </button>
                <div className="vl"></div>
            </div>

            {showCategory[0] && (
                <>
                    {/* {fleetSummaryData.map((e: any, index: any) => {
            return (
              <FleetSummaryContainer
                key={index}
                data={e}
              />
            );
          })} */}

                    <BusinessLocationsTable data={businessLocationsData} />
                    <HomeLocationsTable data={homeLocationsData} />
                    <OpportunityLocationsTable data={opportunityLocationsData} />
                </>
            )}
            {showCategory[1] && (
                <>
                    <BusinessLocationsTable data={businessLocationsData} />
                </>
            )}
            {showCategory[2] && (
                <>
                    <VirtualChargingHubsTable data={virtualChargingHubsData} />
                </>
            )}
            {showCategory[3] && (
                <>
                    <HomeLocationsTable data={homeLocationsData} />
                </>
            )}
            {showCategory[4] && (
                <>
                    <OpportunityLocationsTable data={opportunityLocationsData} />
                </>
            )}
 

        </div>
    );
}

export default InfrastructurePage;
