import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid";
import { RamsDocumentTableProps } from "interfaces/RamsDocumentTableProps";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { CloudStorageObject } from "interfaces/CloudStorageObject";


const DocumentsTable: React.FC<RamsDocumentTableProps> = ({ data, onRemove, onUpdate }) => {
    const navigate = useNavigate();
    const ramsDocuments = data;
    const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);


    const handleMarkAsDeleted = () => {
        const updatedDocuments = data.map((doc) => {
            if (selectedRowIds.includes(doc.id)) {
                // Save current status to previousStatus before marking as 'Deleted'
                return { ...doc, previousStatus: doc.status, status: 'Deleted' as const };
            }
            return doc;
        });

        onUpdate(updatedDocuments);
        setSelectedRowIds([]);
    };

    const handleRestoreDocument = (documentId: string) => {
        const updatedDocuments = data.map((doc) => {
            if (doc.id === documentId && doc.status === 'Deleted') {
                const newStatus = doc.previousStatus ? doc.previousStatus : 'Not Saved'; // Fallback to 'Not Saved' if previousStatus is undefined
                return { ...doc, status: newStatus, previousStatus: undefined }; // Clear previousStatus after restore
            }
            return doc;
        });

        onUpdate(updatedDocuments); // Make sure this function correctly updates the state
    };

    const columns: GridColDef[] = [

        {
            field: "name",
            headerName: "Name",
            sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
            renderCell: (cell) => {
                return (
                    <span
                        className="hyper-link"
                        onClick={() =>
                            navigate("/share/business/site-access-policies/edit", {
                                state: {
                                    siteAccessPolicyUuid: cell.row.blobId,
                                },
                            })
                        }>
                        {cell.row.name}
                    </span>
                );
            }
        },


        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return params.value === 'Not Saved' || params.value === 'Deleted' ? (
                    <i style={{ fontStyle: 'italic' }}>{params.value}</i>
                ) : (
                    <span>{params.value}</span>
                );
            },
        },
        {
            field: 'restore',
            headerName: 'Restore',
            sortable: false,
            width: 130,
            renderCell: (params: GridRenderCellParams<CloudStorageObject>) => {
                const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.stopPropagation(); // Prevent click event from bubbling up to the row

                    // Call your restore function here
                    handleRestoreDocument(params.row.id);
                };

                return params.row.status === 'Deleted' ? (
                    <span className="hyper-link" onClick={onClick}
                    >
                        Restore
                    </span>
                ) : null;
            },
        },
    ];

    return (
        <>
            <div className="table-box">

                <DataGrid
                    // width={"100%"}
                    rows={ramsDocuments}
                    autoHeight
                    {...ramsDocuments}
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15} } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}
                    onRowSelectionModelChange={(newSelectionModel: any) => {
                        setSelectedRowIds(newSelectionModel);
                    }}
                    rowSelectionModel={selectedRowIds}></DataGrid>
                <button className="action-button" onClick={handleMarkAsDeleted}>Delete</button>

            </div>
        </>
    );
}

export default DocumentsTable;
