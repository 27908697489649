
function Billing(props: any) {

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">credit_card</span>
                    Billing (Coming Soon)
                </div>
                <div className="dashboard-card-content"></div>
                <div className="dashboard-card-actions">
                    <div
                        className="icon-and-text"
                        onClick={() => console.log("click")}>
                        <span className="icon material-symbols-outlined">draft</span>
                        Invoice
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Billing;
