import UserContext from 'Context/UserProvider';
import { useContext, useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { hasSharedInfrastructureOnly, isChargePointOperator } from 'utils/categoryUtils';
import './BusinessInfoDropList.css';

function BusinessInfoDropList(props: any) {
    const [active, setActive] = useState(false);
    const { userInfo, setUserInfo, selectedProduct, setSelectedProduct } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const closeModal = props.closeModal;

    async function selectFleet(businessUuid: string, fleetUuid: any, fleetName: any) {

        let bodyData: { [name: string]: string } = {};
        bodyData['uuid'] = userInfo.uuid;
        bodyData['fleetUuid'] = fleetUuid;
        bodyData['businessUuid'] = businessUuid;
        bodyData['accountUuid'] = userInfo.accountUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/fleet/active/set`;
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            console.log(JSON.stringify(response));
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        console.log("SP " + JSON.stringify(res_json));

        setUserInfo((prev: any) => ({
            ...prev,
            businessName: props.title,
            businessUuid: props.businessUuid,
            fleetUuid: fleetUuid,
            currentFleetName: fleetName,
            businessProducts: props.businessProducts,
            businessCategory: res_json.fleet?.businessCategory
        }));

        setSelectedProduct(res_json.selectedProduct);

        navigate(`/${res_json.selectedProduct?.toLowerCase()}`);
        closeModal();
    }

    useEffect(() => {
        // console.log("PROPS : " + JSON.stringify(props));
    }, [props]);

    return (
        <div className="business-drop-list-box">
            {/* Check if chargingCategory is CHARGE_POINT_OPERATOR */}
            {isChargePointOperator(props.chargingCategory) || hasSharedInfrastructureOnly(props.chargingCategory) ? (
                // Render the title as a clickable link
                <div className="business-drop-list-title">
                    <a
                        href="#"
                        onClick={() => selectFleet(props.businessUuid, props.uuid, props.title)}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        {props.title}
                    </a>
                </div>
            ) : (
                // Default rendering when not CHARGE_POINT_OPERATOR
                <>
                    <div className="business-drop-list-title" onClick={() => setActive(pre => !pre)}>
                        <AiOutlinePlusCircle />
                        <div style={{marginLeft: 8, marginTop: -4}}>{props.title}</div>
                    </div>
                    {active && (
                        props.list.length > 0 ? (
                            props.list.map((item: any, index: any) => (
                                <div
                                    onClick={() => selectFleet(props.businessUuid, item.uuid, item.name)}
                                    key={index}
                                    className="business-drop-list-element"
                                >
                                    <span>{item.name}</span>
                                </div>
                            ))
                        ) : (
                            <div className="business-drop-list-element">
                                <br />
                                <p>There is not yet any fleet in this business </p>
                                <button
                                    onClick={() => (
                                        navigate('/common/add-business-fleet', {
                                            state: {
                                                optionCategory: [false, true],
                                                business: { uuid: props.uuid, name: props.title }
                                            }
                                        }),
                                        closeModal()
                                    )}
                                >
                                    Add Fleet
                                </button>
                            </div>
                        )
                    )}
                </>
            )}
        </div>
    );
}

export default BusinessInfoDropList;
