import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router";

function HomeLocationsTable(props: any) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "addressLine",
      headerName: "Location",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
    //   renderCell: (cell) => {
    //     return (
    //       <span
    //         className="hyper-link"
    //         onClick={() =>
    //           navigate("/plan/business/location/detail/location-insight", {
    //             state: {
    //               fleetDwellingUuid: cell.row.dwellingUuid,
    //               fleetDwellingName: cell.row.dwellingName,
    //             },
    //           })
    //         }>
    //         {cell.row.addressLine}
    //       </span>
    //     );
    //   },
    },

    {
      field: "propertyTypeDisplayName",
      headerName: "Property Type",
      sortable: false,
      width: 120,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "homeChargingSuitabilityDisplayName",
      headerName: "Home Charging",
      sortable: false,
      width: 150,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "nearestPublicCharging",
      headerName: "Nearest Public Charging",
      sortable: false,
      width: 400,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "nearestPublicChargingDistance",
      headerName: "Nearest Public Charging Distance",
      sortable: false,
      width: 240,
      align: "right",
      headerAlign: "right",
    },

  ];

  return (
    <div className="table-box">
      <DataGrid
        width={"100%"}
        rows={props.data}
        autoHeight
        {...props.data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>No Infrastructure Locations exist.</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Selected filter returns no result
            </Stack>
          ),
        }}
        fontFamily={"Arvo"}></DataGrid>
    </div>
  );
}

export default HomeLocationsTable;
