import AppRoutes from "AppRoutes";
import { Toaster } from 'react-hot-toast';

// page imports
// import Home from './pages/Home/Home'

/* TEMPORARY */

// component imports
import ScrollToTop from "components/ScrollToTop";

import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";

import { UserProvider } from "Context/UserProvider";
import NotificationProvider from "./components/common/Notifications/NotificationProvider";

export default function App(props: any) {

    return (
        <NotificationProvider>
            <>
                {
                    <ScrollToTop>
                        <UserProvider>
                            <Toaster />
                            <AppRoutes />

                        </UserProvider>
                    </ScrollToTop>
                }
            </>
       </NotificationProvider>
    );
}
