import UserContext from "Context/UserProvider";
import { Business } from "components/charging/BusinessChargingNetworkParameters/chargingPartner";
import { RateCardOption, RateCardValue } from "components/charging/BusinessChargingNetworkParameters/rateCards";
import DetailsPanel from "components/charging/ChargingPartnerComponent/DetailsPanel";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { fromHourValues } from "../../components/charging/BusinessChargingNetworkParameters/fromHourValues";
import { toHourValues } from "../../components/charging/BusinessChargingNetworkParameters/toHourValues";
import { weekDays as initialWeekDays } from "../../components/charging/BusinessChargingNetworkParameters/weekDays";
import DwellingChargePointListTable from "../../components/charging/ChargePointList/DwellingChargePointListTable";
import Map from "../../components/common/MapComponent/Map";

interface ChargingPartnerParams {
    chargingPartnerBusinessUuid: string;
    chargingPartnerBusinessName: string;
    mode: 'VIEW' | 'EDIT';  // Assuming mode has specific string values
}

interface InsightData {
    partnerLocations: []; // Replace 'SomeType' with the actual type for this data
    polygons: [];
    businessLocations: [],
}



function ChargingPartnerEditPage(props: any) {
    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const [activeMenu, setActiveMenu] = useState("Fleet");

    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);
    const [chargingPartner, setChargingPartner] = useState({}) as any;
    const location = useLocation();
    const params = location.state as ChargingPartnerParams;
    const [isDisabled, setIsDisabled] = useState(false);
    const [chargingPartnerLocations, setChargingPartnerLocations] = useState([]) as any;


    const [enableButton, setEnableButton] = useState(false);

    const [rateCardOptions, setRateCardOptions] = useState<RateCardOption[]>([{
        label: "None",
        value: "NONE",
        name: "rateCardField"
    }]);
    const [rateCardValue, setRateCardValue] = useState<RateCardValue>({});

    const [weekDays, setWeekDays] = useState(initialWeekDays);

    const [insightData, setInsightData] = useState<InsightData>({
        partnerLocations: [],
        polygons: [],
        businessLocations: [],
    });

    const handleTimeChange = (dayIndex: number, field: string, value: string) => {
        setWeekDays(prevDays =>
            prevDays.map((day, index) =>
                index === dayIndex
                    ? { ...day, [field]: value }
                    : day
            )
        );
    };

    useEffect(() => {
        fetchChargingPartner();
    }, []);

    function onChange(e: any) {

        if (e.name === "rateCard") {
            const selectedRateCard = rateCardOptions.find((i: any) => i.uuid === e.uuid);

            if (selectedRateCard != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    ...selectedRateCard, // spread the rest of the object properties if there are any others

                    label: selectedRateCard.name, // assuming it has a 'name' field for the label
                    value: selectedRateCard.value, // the unique identifier, used as value
                };
                setRateCardValue(selectedValue);

            }
        }
    }

    async function fetchChargingPartner() {
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = params.chargingPartnerBusinessUuid;
        bodyData["accountUuid"] = userInfo.accountUuid;



        let fetch_link = `${rootUrl}/business/get?chargePoints`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();

        console.log("RES22 " + JSON.stringify(res_json));

        setChargingPartner(res_json.business);
        setWeekDays(res_json.business.dayOfWeekReservation);
        setRateCardOptions(res_json.business.rateCards?.map((e: any) => ({ ...e, label: e.name, value: e.uuid, name: "rateCard" })));
        setChargingPartnerLocations(res_json.business.locations);
        const selectedRateCard = res_json.business.rateCards?.find((i: any) => i.uuid === res_json.business.selectedRateCard?.uuid);

        if (selectedRateCard != null) {
            // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
            const selectedValue = {
                label: selectedRateCard.name, // assuming it has a 'name' field for the label
                value: selectedRateCard.uuid, // the unique identifier, used as value
                ...selectedRateCard, // spread the rest of the object properties if there are any others
            };
            setRateCardValue(selectedValue);

        } else {
            const selectedValue = {
                label: "", // assuming it has a 'name' field for the label
                value: "", // the unique identifier, used as value
            };
            setRateCardValue(selectedValue);
        }

        setVirtualChargingHubs(res_json.business);


        setLoading(false);
    }

    function isNull(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    function setVirtualChargingHubs(chargingPartner: Business) {

        let chargingPartnerLocations = isNull(chargingPartner.locations);

        setInsightData({
            partnerLocations: chargingPartnerLocations,
            businessLocations: [],
            polygons: [],

        });

    }

    async function updateChargingPartner() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        let chargingPartner: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["chargingPartner"] = chargingPartner;
        bodyData["chargingPartnerUuid"] = chargingPartner.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["selectedRateCardUuid"] = rateCardValue.uuid;

        let fetch_link = `${rootUrl}/charging-partner/save`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {
            setIsDisabled(false);
        }


    }

    return (
        <>

            {loading ? (
                <>Loading ...</>
            ) : (

                <div>

                    {showCategory[0] && (
                        <>
                            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Fleet" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([true, false, false]), setActiveMenu("Map"))}>
                                    Locations
                                </button>
                                <div className="vl" />
                                <button
                                    className={`tab-btn ${activeMenu === "Rental" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([false, true, false]), setActiveMenu("Locations"))}>
                                    Charging Infrastructure
                                </button>
                                <div className="vl" />
                                <button
                                    className={`tab-btn ${activeMenu === "Rental" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([false, false, true]), setActiveMenu("Details"))}>
                                    Charging Partnership Details
                                </button>
                                <div className="vl" />
                            </div>
                            <br />

                            {showSecondaryCategory[0] && (
                                <>
                                    <div className="drag-drop-container">
                                        {/* <h1>Charging Partner Locations</h1> */}
                                        {/* {loading ? <TextSkelton /> : <p>{insightData.mapText[0]?.text}</p>} */}
                                        <Map
                                            selectedLocationDwelling={[]}
                                            enableButton={[enableButton, setEnableButton]}
                                            vehicleMapData={[]}
                                            partnerLocations={insightData.partnerLocations}
                                            businessLocations={insightData.businessLocations}
                                            polygons={insightData.polygons}
                                            height={800}
                                        />
                                        {/* {loading ? <TextSkelton /> : <p>{insightData.mapText[1]?.text}</p>} */}
                                    </div>
                                </>
                            )}
                            {showSecondaryCategory[1] && (
                                <>
                                    {chargingPartnerLocations === null ? (
                                        <div>No Charging Infrastructure</div>
                                    ) : (
                                        chargingPartnerLocations.map((location: any) => (
                                            <div className="container-fluid g-0" key={location.id}>
                                                <div className="row gy-2">
                                                    <div className="col-xs-12">
                                                        <div className="dashboard-card">
                                                            <div className="dashboard-card-title">
                                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                                {location.addressLine}
                                                            </div>
                                                            <div
                                                                className="dashboard-card-content"
                                                                style={{ gap: 20 }}
                                                            >
                                                                <div className="box-info">
                                                                    <span
                                                                        className="item"
                                                                        style={{ width: 300 }}
                                                                    >
                                                                        Amenities :
                                                                    </span>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <span className="item">
                                                                            {location.amenitiesListAsString}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <DwellingChargePointListTable dwelling={location} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            )}


                            {showSecondaryCategory[2] && (
                                <>
                                    <DetailsPanel
                                        onChange={onChange}
                                        weekDays={weekDays}
                                        handleTimeChange={handleTimeChange}
                                        rateCardOptions={rateCardOptions}
                                        rateCardValue={rateCardValue}
                                        updateChargingPartner={updateChargingPartner}
                                        isDisabled={isDisabled}
                                        params={params}
                                        chargingPartner={chargingPartner} />
                                </>
                            )}
                        </>

                    )}
                    {/* {showCategory[1] &&
            <>
                <br />
                <h1>Email Address</h1>
                <br />
                <SurveyEmailTable />
            </>
        } */}
                </div>

            )
            }
        </>
    );

}
export default ChargingPartnerEditPage;