import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import SurveyEmailTable from "../../components/user/UserSurveyComponent/SurveyEmailTable";

function UserSurveyPage(props: any) {
    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false, false]);
    const { userInfo } = useContext(UserContext);
    const [emails, setEmails] = useState() as any;
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState("All");

    const fetchEmails = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/survey/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        setEmails(res_json.successRecords.map((e: any) => ({ ...e, id: e.uuid, sentOnDate: new Date(Date.parse(e.sentOnDate)).toLocaleDateString() })));
        setLoading(false);
    };
    useEffect(() => {
        fetchEmails();
    }, []);
    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    {/* <BackButton /> */}
                    {/* <div style={{ display: 'flex', gap: '10px', fontWeight: 'bold' }} >
                            <div className="vl"></div>
                            <button className="tab-btn" onClick={() => setShowCategory([true, false, false])}>Survey Responses</button>
                            <div className="vl"></div>
                            <button className="tab-btn" onClick={() => setShowCategory([false, true, false])}>Send out Survey Emails</button>
                            <div className="vl"></div>
                            <button className="tab-btn" onClick={() => setShowCategory([false, false, true])}>Add Survey Response</button>
                            <div className="vl"></div>
                        </div> */}
                    {showCategory[0] && (
                        <>
                            <h1>Survey Responses</h1>
                            <br />
                            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([true, false, false]), setActiveMenu("All"))}>
                                    All
                                </button>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Completed" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([false, true, false]), setActiveMenu("Completed"))}>
                                    Completed
                                </button>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Requested" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([false, false, true]), setActiveMenu("Requested"))}>
                                    Requested
                                </button>
                                <div className="vl"></div>
                            </div>
                            <br />
                            {showSecondaryCategory[0] && (
                                <>
                                    <SurveyEmailTable emails={emails} />
                                </>
                            )}
                            {showSecondaryCategory[1] && (
                                <>
                                    <SurveyEmailTable emails={emails.filter((e: any) => e.status === "COMPLETED")} />
                                </>
                            )}
                            {showSecondaryCategory[2] && (
                                <>
                                    <SurveyEmailTable emails={emails.filter((e: any) => e.status === "REQUESTED")} />
                                </>
                            )}
                        </>
                    )}
                    {/* {showCategory[1] &&
                <>
                    <br />
                    <h1>Email Address</h1>
                    <br />
                    <SurveyEmailTable />
                </>
            } */}
                </div>
            )}
        </>
    );
}

export default UserSurveyPage;
