import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { checking_null_undefined } from "utils";
import ElectricVehicleTable from "../../components/fleet/ElectricVehicleComponent/ElectricVehicleTable";

function ElectricVehicleListPage(props: any) {
    const [showCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const { userInfo } = useContext(UserContext);
    const [rented, setRented] = useState() as any;
    const [fleet, setFleet] = useState() as any;
    const [loading, setLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState("Fleet");

    const fetchElectricVehicles = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/fleet/electric-vehicle/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            return;
        }
        let res_json = await response.json();

        const connectedFleet = checking_null_undefined(res_json.connectedFleet);

        // Filter for fleet vehicles (ownershipStatus === 'FLEET')
        setFleet(
          connectedFleet
            .filter((e: any) => e.ownershipStatus === 'FLEET')
            .map((e: any) => ({
              ...e,
              id: e.uuid,
              registrationNumber: e.registrationNumber,
              vin: e.vin,
              make: e.make,
              model: e.model,
            }))
        );
        
        // Filter for rented vehicles (ownershipStatus === 'RENTED')
        setRented(
          connectedFleet
            .filter((e: any) => e.ownershipStatus === 'RENTED')
            .map((e: any) => ({
              ...e,
              id: e.uuid,
              registrationNumber: e.registrationNumber,
              vin: e.vin,
              make: e.make,
              model: e.model,
            }))
        );
        
 
 
        setLoading(false);
    };

    useEffect(() => {
        fetchElectricVehicles();
    }, []);
    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>

                    {showCategory[0] && (
                        <>
                            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Fleet" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([true, false]), setActiveMenu("Fleet"))}>
                                    Fleet
                                </button>
                                <div className="vl" />
                                <button
                                    className={`tab-btn ${activeMenu === "Rental" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([false, true]), setActiveMenu("Rental"))}>
                                    Rental
                                </button>
                                <div className="vl" />
                            </div>
                            <br />
                            {showSecondaryCategory[0] && (
                                <>
                                    <ElectricVehicleTable electricVehicles={fleet} />
                                </>
                            )}
                            {showSecondaryCategory[1] && (
                                <>
                                    <ElectricVehicleTable electricVehicles={rented} />
                                </>
                            )}

                        </>
                    )}
                    {/* {showCategory[1] &&
                <>
                    <br />
                    <h1>Email Address</h1>
                    <br />
                    <SurveyEmailTable />
                </>
            } */}
                </div>
            )}
        </>
    );
}

export default ElectricVehicleListPage;
