import { useContext, useEffect, useState } from "react";
import UserContext from "../../Context/UserProvider";
import ScenarioSummaryBarChart from "../../components/fleet/FleetInsightComponent/ScenarioOverviewCompnents/ScenarioSummaryBarChart";
import ScenarioSummaryPieChart from "../../components/fleet/FleetInsightComponent/ScenarioOverviewCompnents/ScenarioSummaryPieChart";

function BusinessInsightPage(props: any) {
    const {userInfo} = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [businessInsightData, setBusinessInsightData] = useState() as any;

    async function fetchBusinessInsight() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/business/insight/get`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();
        // res_json.scenarios.map((e: any) => {
        //     if (e.status === "IN_PROGRESS") {
        //         setRunningScenarioUuid(e.uuid)
        //         setStatusPolling(true)
        //     }
        // }
        // )
        res_json.categoryPercentages = res_json.categoryPercentages.map((e: any) => ({ ...e, percentage: Math.round(e.percentage * 100) }));
        setBusinessInsightData(res_json);
        setLoading(false);
    }
    useEffect(() => {
        fetchBusinessInsight();
    }, []);
    return (
        <>
            {/* <BackButton /> */}
            {loading ? (
                <>Loading..... </>
            ) : (
                <div
                    style={{ overflowX: "hidden", padding: 20, gap: 5, justifyContent: "space-between" }}
                    className="row">
                    {/* <BackButton /> */}

                    <div
                        className="row"
                        style={{ padding: 0, margin: 0, justifyContent: "space-between" }}>
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div
                                className="dashboard-card"
                                style={{ flex: 1 }}>
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">summarize</span>Sampled Data
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 10 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{width: 240 }}>
                                            Number of vehicles:
                                        </span>
                                        <span className="data">{Math.floor(businessInsightData.numberOfVehicles)} </span>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{width: 240 }}>
                                            Number of days :
                                        </span>
                                        <span className="data">{Math.floor(businessInsightData.totalSampleDays)} </span>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{width: 240 }}>
                                            Total distance of ICE journeys :{" "}
                                        </span>
                                        <span className="data">
                                            {Math.floor(businessInsightData.totalDistance).toLocaleString()} {userInfo.userProfile.distanceUnitShortDisplayName}{" "}
                                        </span>
                                    </div>
                                  </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div
                                className="dashboard-card"
                                style={{ flex: 1 }}>
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">
                                        <span className="material-symbols-outlined">location_on</span>
                                    </span>
                                    Journeys
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 5 }}>
                                        <div className="box-info">
                                        <span
                                            className="item"
                                            style={{width: 240 }}>
                                            Total EV Journey Distance :{" "}
                                        </span>
                                        <span className="data">
                                            {Math.floor(businessInsightData.totalEvDistance).toLocaleString()} {userInfo.userProfile.distanceUnitShortDisplayName}{" "}
                                        </span>
                                    </div>
                                     <div className="box-info">
                                        <span
                                            className="item"
                                            style={{width: 240 }}>
                                            Average distance per day :{" "}
                                        </span>
                                        <span className="data">
                                            {Math.floor(businessInsightData.averageDistancePerDay).toLocaleString()} {userInfo.userProfile.distanceUnitShortDisplayName}{" "}
                                        </span>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{width: 240 }}>
                                            Average distance per journey :{" "}
                                        </span>
                                        <span className="data">
                                            {Math.floor(businessInsightData.averageJourneyDistance).toLocaleString()} {userInfo.userProfile.distanceUnitShortDisplayName}{" "}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div
                                className="dashboard-card"
                                style={{ flex: 1 }}>
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">summarize</span>Locations
                                </div>
                            </div>
                        </div>
                    </div>

                    <ScenarioSummaryPieChart
                        total={businessInsightData.numberOfVehicles}
                        unit={"Total Vehicles"}
                        fleetScenarioUuid=""
                        fleetScenarioNumber=""
                        title={"Percentage of ICE Vehicles in each Recommendation Category"}
                        height={400}
                        width={400}
                        data={businessInsightData.categoryPercentages}
                    />
                    <ScenarioSummaryPieChart
                        total={businessInsightData.totalJourneys}
                        fleetScenarioUuid=""
                        unit={"Annual Journeys"}
                        title={"Percentage of Journeys not requiring an enroute charge"}
                        height={400}
                        width={400}
                        data={[
                            { name: "Zero Charges", percentage: Math.round(businessInsightData.percentageJourneysNotRequiringEnRouteCharge * 100) },
                            { name: "Multiple Charges", percentage: Math.round(businessInsightData.percentageJourneysRequiringMultiCharge * 100) },
                            { name: "Single Charges", percentage: Math.round(businessInsightData.percentageJourneysRequiringSingleCharge * 100) },
                        ]}
                    />
                    <ScenarioSummaryBarChart
                        fleetScenarioUuid=""
                        data={[
                            { name: "Electricity", price: Math.round(businessInsightData.annualCostSavingElectricity) },
                            { name: "Fuel", price: Math.round(businessInsightData.annualCostSavingFuel) },
                        ]}
                    />
                </div>
            )}
        </>
    );
}

export default BusinessInsightPage;
