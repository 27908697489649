export const CHARGE_POINT_OPERATOR = "CHARGE_POINT_OPERATOR";
export const CHARGE = "CHARGE";
export const SHARE = "SHARE";
export const SHARE_AND_CHARGE = "SHARE_AND_CHARGE";

export const isChargePointOperator = (chargingCategory: string): boolean => {
    return CHARGE_POINT_OPERATOR === chargingCategory;
};

export const hasSharedInfrastructure = (category: string): boolean => {
    return SHARE === category || SHARE_AND_CHARGE === category;
};

export const hasSharedInfrastructureOnly = (category: string): boolean => {
    return SHARE === category;
};

export const hasChargingRequirement = (category: string): boolean => {
    return CHARGE === category || SHARE_AND_CHARGE === category;
};

export const displayProductButton = (product: string, businessProducts: string[]): boolean => {

    return businessProducts.includes(product);

    return false;
}

