import { DataGrid, GridAlignment, GridColDef } from "@mui/x-data-grid";
import React from "react";

interface DataRow {
    id: number; // or string, depending on your data
    partnerName: string;
    partnerUuid: string;
    transactionDate: string;
    transactionAmount: string;
    chargeAmountKWh: string;
    chargeTimeInMins: string;
    addressLine: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    chargingTransactions: DataRow[];
    transactionDirection: 'INBOUND' | 'OUTBOUND'; // Add this line
}

const ChargingTransactionTable: React.FC<DataGridChildProps> = ({ chargingTransactions, transactionDirection }) => {

    const columns: GridColDef[] = [

        {
            field: "partnerName",
            headerName: "Partner Name",
            sortable: false,
            width: 180,
            align: "left",
            headerAlign: "left",

        },
        {
            field: "registrationNumber",
            headerName: "Vehicle Registration",
            sortable: false,
            width: 160,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "transactionDate",
            headerName: "Transaction Date",
            sortable: false,
            width: 140,
            align: "left",
            headerAlign: "left",
        },
        ...(transactionDirection === 'INBOUND' ? [{

            field: "partnerCost",
            headerName: "Cost to Partner",
            sortable: false,
            width: 140,
            align: "right" as GridAlignment,
            headerAlign: "left" as GridAlignment,
        }] : []),

        ...(transactionDirection === 'INBOUND' ? [{
            field: "income",
            headerName: "Income",
            sortable: false,
            width: 100,
            align: "right" as GridAlignment,
            headerAlign: "left" as GridAlignment,
        }] : []),

        ...(transactionDirection === 'OUTBOUND' ? [{
            field: "partnerCost",
            headerName: "Charging Cost",
            sortable: false,
            width: 140,
            align: "right" as GridAlignment,
            headerAlign: "left" as GridAlignment,
        }] : []),
        {
            field: "chargeAmountKWh",
            headerName: "Charge Amount (kWh)",
            sortable: false,
            width: 180,
            align: "right",
            headerAlign: "left",
        },
        {
            field: "chargeTimeInMins",
            headerName: "Charge Time (mins)",
            sortable: false,
            width: 180,
            align: "right",
            headerAlign: "left",
        }


    ];

    return (
        <>
            <div className="table-box">

                <DataGrid
                    rows={chargingTransactions}
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15} } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={false}
                />

            </div>
        </>
    );
}

export default ChargingTransactionTable;
