import LoaderModal from "components/common/Modal/LoaderModal";
import ModalComponent from "components/common/Modal/Modal";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useReducer, useState } from "react";
import toast from 'react-hot-toast';
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";

function reducer(state: any, action: any) {
    switch (action.input) {
        case "businessUuid":
            return { ...state, businessUuid: action.value };
        case "fleetName":
            return { ...state, fleetName: action.value };
        default:
            return state;
    }
}
function checking_null_undefined(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function AddFleetTabScreen(props: any) {
    const businesses = checking_null_undefined(props.businesses);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(false);
    const location = useLocation() as any;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    let options = [];
    options = businesses.map((e: any) => ({
        label: e.name,
        value: e.uuid,
        name: "businessUuid",
    }));
    const initialChoice = location.state.business
        ? {
            label: location.state.business.name,
            value: location.state.business.uuid,
        }
        : options[0];
    const initialState = {
        businessUuid: initialChoice.value,
        fleetName: "",
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    function onChange(e: any) {
        let action: any;
        if (e.target) {
            action = {
                input: e.target.name,
                value: e.target.value,
            };
        } else {
            action = {
                input: e.name,
                value: e.value,
            };
        }
        dispatch(action);
    }

    function toggleButton() {
        setToggle((prev) => !prev);
    }

    const saveFleet = async () => {
        // const validationStatus = validateForm()
        // if (!validationStatus) {
        //     return
        // }
        setLoading(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        bodyData["businessUuid"] = state.businessUuid;
        bodyData["name"] = state.fleetName;
        bodyData["makeActiveOnceCreated"] = toggle;
        // bodyData['survey'] = state
        // bodyData['surveyUuid'] = splitURL[3]
        let fetch_link = `${rootUrl}/fleet/create`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        let res_json = await response.json();

        if (response.status === 200) {
            toast.success('Fleet saved successfully', {
                position: 'bottom-center',
                duration: 5000,
            });
        } else if (response.status === 400) {
            console.log(JSON.stringify(res_json.code));
            switch (res_json.code) {
                default:
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst saving this business.");
                    break;
            }
        }

        if (toggle) {
            setUserInfo((prev: any) => ({
                ...prev,
                businessUuid: state.businessUuid,
                fleetUuid: res_json.fleet.uuid,
                currentFleetName: res_json.fleet.name,
                businessName: res_json.fleet.businessName,
            }));
            navigate("/plan");
        }
        dispatch({
            input: "fleetName",
            value: "",
        });
        setLoading(false);
        openModal();
    };

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);

    return (
        <div
            className="col-xs-12 col-md-6 col-xl-4 "
            style={{ width: "70%" }}>
            {loading && <LoaderModal />}
            <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                <>
                    <div
                        onClick={() => {
                            closeModal();
                        }}
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IoMdClose size={32} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            margin: 10,
                            padding: 50,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 30,
                        }}>
                        <h1>Thank you for creating new fleet with us!</h1>
                    </div>
                </>
            </ModalComponent>

            <span>Choose Business:</span>
            <br />
            <br />
            <CreatableSelect
                defaultValue={[initialChoice]}
                onChange={onChange}
                name="chargingFacility"
                menuPosition="fixed"
                options={options}
            // styles={colourStyles}
            />
            <br />

            <div className="inputGroup">
                <input
                    type="text"
                    required={true}
                    onChange={onChange}
                    autoComplete="off"
                    name="fleetName"
                    id="fleetName"
                />
                <label>Fleet Name</label>
            </div>
            <br />
            <label
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <span>Mark as active</span>
                <Switch
                    onChange={toggleButton}
                    checked={toggle}
                    name={"markActive"}
                />
            </label>
            <br />
            <button
                className="primary-btn"
                onClick={saveFleet}>
                Save
            </button>
        </div>
    );
}

export default AddFleetTabScreen;
