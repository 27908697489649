import "./RegisterBusinessName.scss";
export default function RegisterBusinessName(props: any) {


    const paragraph =
        <p>
            <br />
            Thank you for choosing Evata.
            <br />
            <br />
            We have registered your details and your account is being set up.
            <br />
            <br />
            We'll be in touch soon to complete the process.
        </p>
    return (

        <>
            <h2 className="h2">Registration</h2>
            {paragraph}
        </>
    );
}
