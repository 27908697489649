import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import "./DashboardPage.scss";

import AccountDashboard from "components/dashboard/AccountDashboard";
import BusinessDashboard from "components/dashboard/BusinessDashboard";

function DashboardPage(props: any) {
    const { userInfo } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [dashboardInfo, setDashboardInfo] = useState() as any;



    const fetchDashboardInfo = async () => {

        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/account/dashboard/get`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            return;
        }
        let res_json = await response.json();
        let dashboardInfo = {
            ...res_json,
            creationDate: new Date(Date.parse(res_json.creationDate)).toLocaleDateString(),
            lastAccessedDate: new Date(Date.parse(res_json.lastAccessedDate)).toLocaleDateString(),
        };

        console.log(dashboardInfo);
        setDashboardInfo(dashboardInfo);
        setLoading(false);
    };


    useEffect(() => {
        fetchDashboardInfo();
    }, []);

    useEffect(() => {
        fetchDashboardInfo();
    }, [userInfo.fleetUuid]);

    return (
        <>
            {loading ? (
                <>Loading...</>
            ) : (

                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <AccountDashboard dashboardInfo={dashboardInfo} />
                        <BusinessDashboard dashboardInfo={dashboardInfo} />
                    </div>
                </div>
            )
            }
        </>
    );
}

export default DashboardPage;
