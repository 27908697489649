import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slider, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

interface TradePopupProps {
    open: boolean;
    handleClose: () => void;
    handleContinue: (amountKWh: number) => void;
    currentBalanceInKWh: number;
    endDate: string;
}

const StyledDialogTitle = styled(DialogTitle)({
    backgroundColor: 'black',
    color: 'white',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',  // Vertical centering
    padding: '0 16px',  // Ensure padding inside the title
});

const FlexContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
});

const TradePopup: React.FC<TradePopupProps> = ({ open, handleClose, handleContinue, currentBalanceInKWh, endDate }) => {
    const [sliderValue, setSliderValue] = React.useState(0);  // Slider value (percentage)

    // Handle slider change (in 10% increments)
    const handleSliderChange = (event: any, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    minWidth: '400px',  // Set minimum width for the popup
                },
            }}
        >
            {/* Styled title */}
            <StyledDialogTitle>Request a Trade</StyledDialogTitle>

            <DialogContent>
                {/* Remaining trade amount */}
                <FlexContainer>
                    <Typography>Remaining Energy :</Typography>
                    <Typography><b>{currentBalanceInKWh.toFixed(2)} kW/h</b></Typography>
                </FlexContainer>

                {/* End date */}
                <FlexContainer>
                    <Typography>Agreement end date :</Typography>
                    <Typography><b>{endDate}</b></Typography>
                </FlexContainer>

                {/* Slider for 0-100% in 10% increments */}
                <Typography mt={2}>Select percentage of remaining kW/h to trade:</Typography>
                <Slider
                    value={sliderValue}
                    onChange={handleSliderChange}
                    aria-labelledby="discrete-slider"
                    step={10}
                    marks
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                />

                {/* Amount based on slider value */}
                <FlexContainer>
                    <Typography>Trade Amount :</Typography>
                    <Typography><b>{((sliderValue / 100) * currentBalanceInKWh).toFixed(2)} kW/h</b></Typography>
                </FlexContainer>
            </DialogContent>

            {/* Submit button */}
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleContinue((sliderValue / 100) * currentBalanceInKWh)} color="primary">
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TradePopup;
