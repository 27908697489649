import webAuth from "./WebAuth";

export default class auth0Service {
  public static handleAuthentication(result: any, rememberMe: boolean): void {
    if (result.idToken || result.id_token) {
      this.setSession(result, rememberMe);
    } else {
      //   History.push('/');
      window.location.reload();
    }
  }
  private static setSession(result: any, rememberMe: boolean) {
    const expiresAt = result.expiresIn ? JSON.stringify(result.expiresIn * 1000 + new Date().getTime()) : JSON.stringify(result.expires_in * 1000 + new Date().getTime());
    const accessToken = result.accessToken;

    webAuth.client.userInfo(accessToken, (err, user) => {
      if (err) {
        console.log("this is an error", err);
        window.location.reload();
        return;
      }
      if (rememberMe) {
        this.setLocalStorage(result, expiresAt, user);
      } else {
        this.setSessionStorage(result, expiresAt, user);
      }
    });
  }
  private static setSessionStorage(result: any, expiresAt: any, user: any): void {
    sessionStorage.setItem("refresh_token", result.refreshToken ? result.refreshToken : result.refresh_token);
    sessionStorage.setItem("access_token", result.accessToken);
    sessionStorage.setItem("expires_at", expiresAt);
    sessionStorage.setItem("user", JSON.stringify(user));
    window.location.href = `${window.location.origin + "/plan"}`;
  }

  private static setLocalStorage(result: any, expiresAt: any, user: any): void {
    localStorage.setItem("refresh_token", result.refreshToken ? result.refreshToken : result.refresh_token);
    localStorage.setItem("access_token", result.accessToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("user", JSON.stringify(user));
    window.location.href = `${window.location.origin + "/plan"}`;
  }
}
