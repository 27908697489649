import UserContext from "Context/UserProvider";
import { useContext, useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom";
import { isChargePointOperator } from "utils/categoryUtils";

function ChargingPartnerRateCardEditPage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);
    const [parameter, setParameter] = useState({}) as any;
    const location = useLocation();
    const params = location.state as any;
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);

   const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {

        console.log("rcuuid " + params.chargingPartnerRateCardUuid);

        if (params.chargingPartnerRateCardUuid == undefined || params.chargingPartnerRateCardUuid == null) {
            setLoading(false);
        } else {
            fetchChargingPartnerRateCard();
        }
    }, []);

    function onChange(e: any) {

        console.log(e.name);
        // if (e.name === "initialChargingPartnerRateCard") {
        //     setInitialChargingPartnerRateCard(initialChargingPartnerRateCardValues.filter((i: any) => i.value === e.value)[0]);
        // } else 

        if (e.target.name === "name") {
            parameter.name = e.target.value;
        } else if (e.target.name === "energyCostAcSinglePhaseGbpPerKWhOffPeak") {
            parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak = e.target.value;
        } else if (e.target.name === "energyCostAcThreePhaseGbpPerKWhOffPeak") {
            parameter.energyCostAcThreePhaseGbpPerKWhOffPeak = e.target.value;
        } else if (e.target.name === "energyCostDcGbpPerKWhOffPeak") {
            parameter.energyCostDcGbpPerKWhOffPeak = e.target.value;
        } else if (e.target.name === "energyCostAcSinglePhaseGbpPerKWhPeak") {
            parameter.energyCostAcSinglePhaseGbpPerKWhPeak = e.target.value;
        } else if (e.target.name === "energyCostAcThreePhaseGbpPerKWhPeak") {
            parameter.energyCostAcThreePhaseGbpPerKWhPeak = e.target.value;
        } else if (e.target.name === "energyCostDcGbpPerKWhPeak") {
            parameter.energyCostDcGbpPerKWhPeak = e.target.value;
        } else if (e.target.name === "allowanceKWh") {
            parameter.allowanceKWh = e.target.value;
        }
    }

    async function fetchChargingPartnerRateCard() {
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["chargingPartnerRateCardUuid"] = params.chargingPartnerRateCardUuid;


        let fetch_link = `${rootUrl}/charging-partner/rate-card/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();
        setParameter(res_json.chargingPartnerRateCard);
        setLoading(false);

    }

    async function saveChargingPartnerRateCard() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        let chargingPartnerRateCard: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["chargingPartnerRateCard"] = chargingPartnerRateCard;
        bodyData["chargingPartnerRateCardUuid"] = parameter.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        chargingPartnerRateCard["energyCostAcSinglePhaseGbpPerKWhOffPeak"] = Number(parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak);
        chargingPartnerRateCard["energyCostAcThreePhaseGbpPerKWhOffPeak"] = Number(parameter.energyCostAcThreePhaseGbpPerKWhOffPeak);
        chargingPartnerRateCard["energyCostDcGbpPerKWhOffPeak"] = Number(parameter.energyCostDcGbpPerKWhOffPeak);
        chargingPartnerRateCard["energyCostAcSinglePhaseGbpPerKWhPeak"] = Number(parameter.energyCostAcSinglePhaseGbpPerKWhPeak);
        chargingPartnerRateCard["energyCostAcThreePhaseGbpPerKWhPeak"] = Number(parameter.energyCostAcThreePhaseGbpPerKWhPeak);
        chargingPartnerRateCard["energyCostDcGbpPerKWhPeak"] = Number(parameter.energyCostDcGbpPerKWhPeak);
        chargingPartnerRateCard["name"] = parameter.name;
        chargingPartnerRateCard["allowanceKWh"] = parameter.allowanceKWh;

        let fetch_link = `${rootUrl}/charging-partner/rate-card/save`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        let res_json = await response.json();

        if (response.status === 200) {
            toast.success('Rate Card Save Successful', {
                position: 'bottom-center',
                duration: 5000,
            });
            setIsDisabled(false);
        } else if (response.status === 400) {
            setIsDisabled(false);


            switch (res_json.code) {
                // case "CHARGING_PARTNER_RATE_CARD_UUID_IS_MANDATORY":
                //     errorMessage = ("Please select a Rate Card from the list");
                //     break;
                default:
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst saving this rate card.");
                    break;
            }

        }

    }

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);


    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                <div>
                    <div className="container-fluid g-0">
                        <div className="row gy-2">
                            {isChargePointOperator(userInfo.businessCategory) ? (

                                <div className="col-xs-12 col-md-6 col-xl-4">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Details                              </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Name :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 300 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={parameter.name}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="name"
                                                        id="parameter.name"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Allowance kW/h :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={parameter.allowanceKWh}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="allowanceKWh"
                                                        id="parameter.allowanceKWh"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Price :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={parameter.energyCostDcGbpPerKWhPeak}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="energyCostDcGbpPerKWhPeak"
                                                        id="parameter.energyCostDcGbpPerKWhPeak"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            ) : (
                                <>

                                    <div className="col-xs-12 col-md-6 col-xl-4">
                                        <div className="dashboard-card">
                                            <div className="dashboard-card-title">
                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                Details                              </div>
                                            <div
                                                className="dashboard-card-content"
                                                style={{ gap: 20 }}>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        Name :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={parameter.name}
                                                            type="text"
                                                            style={{ textAlign: "left" }}
                                                            className="textbox"
                                                            name="name"
                                                            id="parameter.name"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div
                                                className="dashboard-card-content"
                                                style={{ gap: 20 }}>
                                                {initialValue.value ? (
                                                    <div className="box-info">

                                                        <span
                                                            className="item"
                                                            style={{ width: 300 }}>
                                                            This rate card will be assigned to new Charging Partners.  Please update another Rate Card to make it the default.
                                                        </span>

                                                    </div>
                                                ) : (
                                                    <div className="box-info">

                                                        <span
                                                            className="item"
                                                            style={{ width: 300 }}>
                                                            Associate this rate card by default to new Charging Partners :
                                                        </span>
                                                        <div style={{ display: "flex", alignItems: "right", textAlign: "right" }}>
                                                            <Select
                                                                value={initialValue}
                                                                name="initialValue"
                                                                onChange={onChange}
                                                                options={initialValues}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>*/}
                                        </div> 
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-xl-4">
                                        <div className="dashboard-card">
                                            <div className="dashboard-card-title">
                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                Charging Fee for partners charging here per kW/h (Off Peak)                                </div>
                                            <div
                                                className="dashboard-card-content"
                                                style={{ gap: 20 }}>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        AC Single Phase :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={typeof parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak == "number" ? parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak.toFixed(2) : 0.00}
                                                            type="text"
                                                            style={{ textAlign: "right" }}
                                                            className="textbox"
                                                            name="energyCostAcSinglePhaseGbpPerKWhOffPeak"
                                                            id="energyCostAcSinglePhaseGbpPerKWhOffPeak"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        AC Three Phase :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={typeof parameter.energyCostAcThreePhaseGbpPerKWhOffPeak == "number" ? parameter.energyCostAcThreePhaseGbpPerKWhOffPeak.toFixed(2) : 0.00}
                                                            type="text"
                                                            style={{ textAlign: "right" }}
                                                            className="textbox"
                                                            name="energyCostAcThreePhaseGbpPerKWhOffPeak"
                                                            id="energyCostAcThreePhaseGbpPerKWhOffPeak"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        DC :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={typeof parameter.energyCostDcGbpPerKWhOffPeak == "number" ? parameter.energyCostDcGbpPerKWhOffPeak.toFixed(2) : 0.00}
                                                            type="text"
                                                            style={{ textAlign: "right" }}
                                                            className="textbox"
                                                            name="energyCostDcGbpPerKWhOffPeak"
                                                            id="energyCostDcGbpPerKWhOffPeak"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-xl-4">
                                        <div className="dashboard-card">
                                            <div className="dashboard-card-title">
                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                Charging Fee for partners charging here per kW/h (Peak)
                                            </div>
                                            <div
                                                className="dashboard-card-content"
                                                style={{ gap: 20 }}>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        AC Single Phase :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={typeof parameter.energyCostAcSinglePhaseGbpPerKWhPeak == "number" ? parameter.energyCostAcSinglePhaseGbpPerKWhPeak.toFixed(2) : 0.00}
                                                            type="text"
                                                            style={{ textAlign: "right" }}
                                                            className="textbox"
                                                            name="energyCostAcSinglePhaseGbpPerKWhPeak"
                                                            id="energyCostAcSinglePhaseGbpPerKWhPeak"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        AC Three Phase :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={typeof parameter.energyCostAcThreePhaseGbpPerKWhPeak == "number" ? parameter.energyCostAcThreePhaseGbpPerKWhPeak.toFixed(2) : 0.00}
                                                            type="text"
                                                            style={{ textAlign: "right" }}
                                                            className="textbox"
                                                            name="energyCostAcThreePhaseGbpPerKWhPeak"
                                                            id="energyCostAcThreePhaseGbpPerKWhPeak"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="box-info">
                                                    <span
                                                        className="item"
                                                        style={{ width: 300 }}>
                                                        DC :
                                                    </span>
                                                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                                        <input
                                                            ref={currentInput}
                                                            defaultValue={typeof parameter.energyCostDcGbpPerKWhPeak == "number" ? parameter.energyCostDcGbpPerKWhPeak.toFixed(2) : 0.00}
                                                            type="text"
                                                            style={{ textAlign: "right" }}
                                                            className="textbox"
                                                            name="energyCostDcGbpPerKWhPeak"
                                                            id="energyCostDcGbpPerKWhPeak"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>





                            )}
                            <div className="col-xs-12 col-md-12 col-xl-12">
                                <div>

                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                        <button
                                            className="primary-btn"
                                            disabled={isDisabled}
                                            onClick={saveChargingPartnerRateCard}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            )}
        </>
    );
}

export default ChargingPartnerRateCardEditPage;