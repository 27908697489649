import { Backdrop, CircularProgress } from '@mui/material';
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Tooltip,
} from 'chart.js';
import Breadcrumbs from "components/ppa-quote/Breadcrumbs";
import ElectricVehicleTable from "components/ppa-quote/ElectricVehicleTable";
import QuickQuotePanel from 'components/ppa-quote/QuickQuotePanel';
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checking_null_undefined } from "utils";


// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);
interface Vehicle {
    vrn: string;
    make: string;
    model: string;
    journeys: number;
    totalDistance: string;
    avgCharge: string;
    avgDwellTime: string;
}

function BuildQuotePageVehicles(props: any) {
    const [loading, setLoading] = useState<boolean>(true);
    const { userInfo } = useContext(UserContext);
    const [isDisabled] = useState<boolean>(false);
    const navigate = useNavigate();

    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false, false]);
    const [activeMenu, setActiveMenu] = useState("Quick Quote");
    const [isError, setIsError] = useState(false);

    const [plan, setPlan] = useState() as any;
    const [connectedFleet, setConnectedFleet] = useState() as any;

    const [quickQuote, setQuickQuote] = useState({
        numberOfVehicles: 0,
        chargesPerWeek: 0,
        averageChargeSize: 0,
        monthlyRequirementKWh: 0,
    });

    const data1 = {
        datasets: [{
            data: [48, 52],
            backgroundColor: ['#FF6384', '#CCCCCC'],
        }],
    };

    const data2 = {
        datasets: [{
            data: [12, 88],
            backgroundColor: ['#36A2EB', '#CCCCCC'],
        }],
    };

    const handleNext = () => {

        navigate("/agree/ppa/quote/build/locations", {
            state: {
                quickQuote: quickQuote,
            },
        })

    }

    const fetchVehicleData = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/fleet/electric-vehicle/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        console.log(JSON.stringify(res_json));

        setConnectedFleet(checking_null_undefined(res_json.connectedFleet).map((e: any) => ({ ...e, id: e.uuid, registrationNumber: e.registrationNumber, vin: e.vin, make: e.make, model: e.model, chargeCount: e.chargeCount, countDaysOfData: e.countDaysOfData })));
        setPlan(checking_null_undefined(res_json.plan).map((e: any) => ({ ...e, id: e.uuid, registrationNumber: e.registrationNumber, vin: e.vin, make: e.make, model: e.model, countDaysOfData: e.countDaysOfData })));
        setLoading(false);
    };



    const handleQuoteChange = (updatedValues: any) => {
        setQuickQuote((prevState) => ({
            ...prevState,
            ...updatedValues
        }));
    };

    useEffect(() => {
        fetchVehicleData();
    }, []);
    const breadcrumbs = ['Vehicles', 'Home Locations', 'Summary', 'Quotes'];
    const activeIndex = 0; // Change this index to set the active step

    return (
        <div>
            {/* Breadcrumbs always displayed */}
            <Breadcrumbs currentStep={"Vehicles"} />

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (
                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">

                        {showCategory[0] && (
                            <>
                                <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                    <div className="vl"></div>
                                    <button
                                        className={`tab-btn ${activeMenu === "Quick Quote" ? "active" : ""}`}
                                        onClick={() => {
                                            setShowSecondaryCategory([true, false, false]);
                                            setActiveMenu("Quick Quote");
                                        }}>
                                        Quick Quote
                                    </button>
                                    <div className="vl"></div>
                                    <button
                                        className={`tab-btn ${activeMenu === "Plan" ? "active" : ""}`}
                                        onClick={() => {
                                            setShowSecondaryCategory([false, true, false]);
                                            setActiveMenu("Plan");
                                        }}>
                                        PLAN - Simulated EV
                                    </button>
                                    <div className="vl" />
                                    <button
                                        className={`tab-btn ${activeMenu === "EV Connected Car Data" ? "active" : ""}`}
                                        onClick={() => {
                                            setShowSecondaryCategory([false, false, true]);
                                            setActiveMenu("EV Connected Car Data");
                                        }}>
                                        EV Connected Car Data
                                    </button>
                                    <div className="vl" />
                                </div>
                                <br />
                                {showSecondaryCategory[0] && (
                                    <QuickQuotePanel
                                        numberOfVehicles={quickQuote.numberOfVehicles}
                                        chargesPerWeek={quickQuote.chargesPerWeek}
                                        averageChargeSize={quickQuote.averageChargeSize}
                                        monthlyRequirementKWh={quickQuote.monthlyRequirementKWh}
                                        onQuoteChange={handleQuoteChange} // Pass the callback
                                    />)}
                                {showSecondaryCategory[1] && (
                                    <ElectricVehicleTable electricVehicles={plan} displayVin={false} displayRegLink={true} makeModel={false} />
                                )}
                                {showSecondaryCategory[2] && (
                                    <ElectricVehicleTable electricVehicles={connectedFleet} displayVin={true} displayRegLink={false} makeModel={true} />
                                )}
                            </>
                        )}
                        <br />
                        <br />
                        <br />
                        <div className="col-xs-12 col-md-12 col-xl-12">
                            <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                <button className="primary-btn" disabled={isDisabled} onClick={handleNext}>
                                    Next
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            )}
        </div>
    );
}

export default BuildQuotePageVehicles;
