import { useContext } from "react";

import UserContext from "Context/UserProvider";
import { hasSharedInfrastructure } from "utils/categoryUtils";
import { isPlanProduct, isShareProduct } from "utils/productUtils";
import { isAccountRole } from "utils/roleUtils";
import AccountInformation from "./account/AccountInformation";
import ChargingNetwork from "./account/ChargingNetwork";
import GettingStarted from "./account/GettingStarted";
import RecentFleets from "./account/RecentFleets";
import Scenarios from "./account/Scenarios";
import Bookings from "./business/Bookings";
import ChargingSessions from "./business/ChargingSessions";
import PartnerChargingNetwork from "./business/PartnerChargingNetwork";
import ChargingSummaryBarChart from "./business/savings/ChargingSummaryBarChart";
import ChargingSummaryPieChart from "./business/savings/ChargingSummaryPieChart";
import Billing from "./common/Billing";
import News from "./common/News";
import UserManagement from "./common/UserManagement";

type AccountDashboardProps = {
    dashboardInfo: any;
};



function AccountDashboard({ dashboardInfo }: AccountDashboardProps) {

    const { userInfo, selectedProduct } = useContext(UserContext);
    const isAccountOnly = isAccountRole(userInfo.userType);
    const pcn = userInfo.privateChargingNetworkSubscription

    const inboundTitle = "Recent Inbound Charging Sessions";
    const outboundTitle = "Recent Outbound Charging Sessions";

    if (!isAccountOnly) {
        return null;
    }

    return (
        <>
            {selectedProduct !== null && isPlanProduct(selectedProduct) && (
                <>
                    <AccountInformation dashboardInfo={dashboardInfo} />
                    <RecentFleets dashboardInfo={dashboardInfo} />
                    <Scenarios dashboardInfo={dashboardInfo} />
                    <UserManagement dashboardInfo={dashboardInfo} />
                    <GettingStarted />
                    <ChargingNetwork pcn={pcn} dashboardInfo={dashboardInfo} />
                    <News />
                    <Billing />
                </>
            )}
            {selectedProduct !== null && isShareProduct(selectedProduct) && (
                <>
                    <PartnerChargingNetwork dashboardInfo={dashboardInfo} />
                    <Bookings dashboardInfo={dashboardInfo} />

                    {hasSharedInfrastructure(userInfo.businessCategory) ? (
                        <ChargingSessions title={inboundTitle} chargingSessions={dashboardInfo.inboundChargingSessions} incomeCostTitle="Income" />
                    ) : (<></>)}
                    <ChargingSessions title={outboundTitle} chargingSessions={dashboardInfo.outboundChargingSessions} incomeCostTitle="Cost" />
                    <div className="col-xs-12 col-md-6 col-xl-4">
                        <div className="dashboard-card">
                            <span className="dashboard-card-title">
                                <span className="icon material-symbols-outlined">electric_car</span>
                                Percentage of Network Charge Points used{" "}
                            </span>
                            <div className="dashboard-card-content">

                                <ChargingSummaryPieChart
                                    total={dashboardInfo.countChargePointsUsedByYourBusiness + "/" + dashboardInfo.countChargePointsInNetwork}
                                    unit={"Charge Points"}
                                    fleetScenarioUuid={""}
                                    fleetScenarioNumber={""}
                                    title={"Percentage of Network Charge Points used"}
                                    height={400}
                                    width={400}
                                    data={dashboardInfo.outboundChargePointUsage}
                                />
                            </div>
                        </div>
                    </div>
                    {hasSharedInfrastructure(userInfo.businessCategory) ? (

                    <div className="col-xs-12 col-md-6 col-xl-4">
                        <div className="dashboard-card">
                            <span className="dashboard-card-title">
                                <span className="icon material-symbols-outlined">electric_car</span>
                                Percentage of Businesses using your charge points{" "}
                            </span>
                            <div className="dashboard-card-content">

                                <ChargingSummaryPieChart
                                    total={dashboardInfo.countBusinessesServedByYourChargePoints + "/" + dashboardInfo.businessesInPCN}
                                    unit={"Businesses"}
                                    fleetScenarioUuid={""}
                                    fleetScenarioNumber={""}
                                    title={"Percentage of Businesses using your charge points"}
                                    height={400}
                                    width={400}
                                    data={dashboardInfo.inboundBusinessUsage}
                                />
                            </div>
                        </div>
                    </div>
                    ) : (<></>)}
                    <div className="col-xs-12 col-md-6 col-xl-4">
                        <div className="dashboard-card">
                            <span className="dashboard-card-title">
                                <span className="icon material-symbols-outlined">electric_car</span>
                                Public Charging Cost Comparison (£){" "}
                            </span>
                            <div className="dashboard-card-content">
                                <ChargingSummaryBarChart
                                    fleetScenarioUuid=""
                                    data={[
                                        { name: "Partner Charging Cost", price: Math.round(dashboardInfo.currentMonth?.partnerChargingCost) },
                                        { name: "Est. Public Charging Cost", price: Math.round(dashboardInfo.currentMonth?.publicChargingCost) },
                                    ]}
                                    dashboardInfo={dashboardInfo}
                                />
                            </div>
                        </div>
                    </div>

                </>
            )}

        </>


    )

}
export default AccountDashboard;
