import { LoadingIcon } from "components/common/Icons";
import UserContext from "Context/UserProvider";
import ProtectedRouteLayout from "layout/ProtectedRouteLayout";
import UnProtectedRouteLayout from "layout/UnProtectedRouteLayout";
import LoginPage from "pages/LoginPage/LoginPage";
import RegisterBusinessName from "pages/RegisterBusinessNamePage/RegisterBusinessName";
import { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import AuthService from "service/AuthService/AuthService";

function RequiredAuth(props: any) {

    const { userInfo, setUserInfo, selectedProduct, setSelectedProduct, theme, setTheme } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = props.error;
    const navigate = useNavigate();

    //CHECK IF USER LOGIN WITH REMEMBER OR JUST LOGIN FOR ONE SESSION ONLY
    const rememberMe = localStorage.getItem("refresh_token") !== null;

    const getClientId = () => {
        // const storage: any = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::api-dev.evata.co.uk::openid profile email`) as string);
        let access_token;
        let user;
        if (rememberMe) {
            access_token = localStorage.getItem("access_token") as string;
            user = JSON.parse(localStorage.getItem("user") as string);
        } else {
            access_token = sessionStorage.getItem("access_token") as string;
            user = JSON.parse(sessionStorage.getItem("user") as string);
        }
        return [access_token, user];
    };
    async function requestInfo() {
        try {


            if (isAuthenticated) {
                setLoading(true);
                const [access_token, user] = getClientId();

                let bodyData: { [name: string]: string } = {};
                bodyData["uuid"] = user.sub;
                const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
                let fetch_link = `${rootUrl}/user_auth/authenticate`;
                let response = await fetch(fetch_link, {
                    method: "POST",
                    body: JSON.stringify(bodyData),
                    headers: {
                        cache: "no-cache",
                        pragma: "no-cache",
                        "Cache-Control": "no-cache",
                        "Content-Type": "application/json",
                    },
                });
                let res_json = await response.json();

                if (response.status !== 200) {

                    if (response.status === 400) {

                        console.log('got 400');
                        if (res_json.code === "USER_NOT_FOUND") {
                            setLoading(false);
                            return;
                        } else if (res_json.code === "REGISTERED_USER_ONLY") {
                            setUserInfo((prev: any) => ({
                                ...prev,
                                completedRegistration: false
                            }));


                        }
                    }

                    setError("There was an error during request to api service, please try again later");
                    setUserInfo((prev: any) => ({ ...prev, uuid: user.sub, token: access_token }));
                    setLoading(false);
                    return;
                } else {
                    setUserInfo({
                        businessName: res_json.businesses[0]?.name,
                        businessUuid: res_json.businesses[0]?.uuid,
                        businessProducts: res_json.businesses[0]?.products,
                        businessCategory: res_json.businesses[0]?.chargingCategory,
                        accountType: res_json.account?.accountType,
                        userType: res_json.user.role,
                        fleetUuid: res_json.businesses[0]?.fleets?.[0]?.uuid,
                        uuid: user.sub,
                        overnightVehicleLocation: res_json.businesses[0].fleets?.[0]?.overnightVehicleLocation,
                        completedRegistration: res_json.businesses.length > 0,
                        accountUuid: res_json.account?.accountUuid,
                        currentFleetName: res_json.businesses[0]?.fleets?.[0]?.name,
                        userProfile: res_json.userProfile,
                        authUser: user,
                        authenticated: true,
                        privateChargingNetworkSubscription: res_json.account?.privateChargingNetworkSubscription,

                    });
                    setSelectedProduct(res_json.user.selectedProduct);
                    setLoading(false);
                }
            }
            return;
        } catch (e: any) {
            console.log(e);
            return;
        }
    }
    const isAuthenticated = AuthService.isAuthenticated(rememberMe);
    if (!isAuthenticated) {
        document.location.href = `${document.location.origin + "/login"}`;
    }

    useEffect(() => {
        if (userInfo == null || !userInfo.authenticated) {
            requestInfo();
        }

    }, []);

    // Keep track of the previous selected product using a ref
    const previousProduct = useRef<string | null>(null);


    useEffect(() => {
        console.log(JSON.stringify(previousProduct.current) + " --> " + JSON.stringify(selectedProduct) + " : " + window.location.pathname);

        if (previousProduct.current == null) {
            setSelectedProduct("SHARE");
            previousProduct.current = selectedProduct;
        } else {

            // Check if the selectedProduct has changed
            if (selectedProduct && selectedProduct !== previousProduct.current) {
                previousProduct.current = selectedProduct;
                const currentPath = window.location.pathname;

                // Navigate to the base route of the selected product when it changes
                switch (selectedProduct) {
                    case "PLAN":
                        if (!currentPath.startsWith("/plan")) {
                            navigate("/plan");
                            console.log("Navigating to /plan");
                        }
                        break;
                    case "SHARE":
                        if (!currentPath.startsWith("/share")) {
                            navigate("/share");
                            console.log("Navigating to /share");
                        }
                        break;
                    case "AGREE":
                        if (!currentPath.startsWith("/agree")) {
                            navigate("/agree");
                            console.log("Navigating to /agree");
                        }
                        break;
                    case "CHARGE":
                        if (!currentPath.startsWith("/charge")) {
                            navigate("/charge");
                            console.log("Navigating to /charge");
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }, [selectedProduct]);

    const changeSelectedProduct = () => {

        alert("in changeSelectedProduct");

        const handleProductChange = async () => {
            let bodyData = {
                uuid: userInfo.uuid,
                accountUuid: userInfo.accountUuid,
                businessUuid: userInfo.businessUuid,
                productType: selectedProduct,
            };

            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            const fetch_link = `${rootUrl}/user_auth/product/selected/change`;

            try {
                let response = await fetch(fetch_link, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(bodyData),
                });

                if (response.status !== 200) {
                    setError(true);
                    return;
                }

                let res_json = await response.json();
                // Additional logic to handle response
            } catch (error) {
                setError('Error changing selected product:', error);
                // setIsError(true);
            }
        };

        handleProductChange();
    };



    return (
        <>
            {isAuthenticated ? (
                <>
                    {loading ? (
                        <div
                            style={{
                                width: "100%",
                                position: "absolute",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <LoadingIcon />
                        </div>
                    ) : (
                        // <UserContext.Provider value={[userInfo, setUserInfo]}>
                        <ProtectedRouteLayout>
                            {userInfo.completedRegistration ? (

                                <Outlet />
                            ) : (
                                <RegisterBusinessName
                                    userInfo={userInfo}
                                // siteLoaded={siteLoaded}
                                // isSidebarOpen={props.isSidebarOpen}
                                />
                            )}
                        </ProtectedRouteLayout>
                        // </UserContext.Provider>
                    )}
                </>
            ) : (
                <UnProtectedRouteLayout>
                    <LoginPage />
                </UnProtectedRouteLayout>
            )}
        </>
    );
}

export default RequiredAuth;
