import AddBusinessTabScreen from "components/business/AddBusinessFleetPageComponent/AddBusinessTabScreen";
import AddFleetTabScreen from "components/business/AddBusinessFleetPageComponent/AddFleetTabScreen";
import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

function AddBusinessFleetPage(props: any) {
    const [businesses, setBusinesses] = useState([]) as any;
    const [categories, setCategories] = useState([]) as any;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    let location = useLocation() as any;
    let optionCategory = location.state?.optionCategory;
    const [activeMenu, setActiveMenu] = useState(optionCategory ? (optionCategory[0] === true ? "Add Business" : "Add Fleet") : "Add Business");

    async function fetchBusinessInfo() {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/business/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();
        setBusinesses(res_json.businesses);
        setCategories(res_json.categories);
        setIsLoading(false);
    }
    const [showCategory, setShowCategory] = useState(optionCategory);

    useEffect(() => {
        fetchBusinessInfo();
    }, []);

    return (
        <>
            {isLoading ? (
                <>loading...</>
            ) : (
                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Add Business" ? "active" : ""}`}
                                    onClick={() => (setShowCategory([true, false]), setActiveMenu("Add Business"))}>
                                    Add Business
                                </button>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Add Fleet" ? "active" : ""}`}
                                    onClick={() => (setShowCategory([false, true]), setActiveMenu("Add Fleet"))}>
                                    Add Fleet
                                </button>
                                <div className="vl"></div>
                            </div>

                            {showCategory[0] && (
                                <>
                                    <br />
                                    <AddBusinessTabScreen fetchBusinessInfo={fetchBusinessInfo} categories={categories} />
                                    {/* <ConfigureTabScreen fleetScenarioUuid={fleetScenarioUuid} /> */}
                                </>
                            )}
                            {showCategory[1] && (
                                <>
                                    <br />
                                    <AddFleetTabScreen businesses={businesses} />
                                    {/* <ConfigureTabScreen fleetScenarioUuid={fleetScenarioUuid} /> */}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddBusinessFleetPage;
