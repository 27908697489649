import { Button } from '@mui/material';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import UserContext from "Context/UserProvider";
import React, { useContext, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from "react-router";

interface DataRow {
    id: string; // or string, depending on your data
    registrationNumber: string;
    make: string;
    model: string;
    combinedRange: string;
    uuid: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    trades: DataRow[];
    adoptTrade: (tradeUuid: string ) => void;  // Add the openTrade method

}

const TradesTable: React.FC<DataGridChildProps> = ({ trades, adoptTrade }) => {

    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const location = useLocation() as any;
    const [enableButton, setEnableButton] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [totalMonthlyPublicKWh, setTotalMonthlyPublicKWh] = useState<number>();
    const breadcrumbs = ['Vehicles', 'Home Locations', 'Summary', 'Quotes'];
    const activeIndex = 3; // Change this index to set the active step
    const { userInfo, setUserInfo } = useContext(UserContext);

    const formatToGBP = (value: number) => {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
    };

    const columns: GridColDef[] = [

        {
            field: 'logo',
            headerName: 'Charge Point Operator',
            flex: 1, // Dynamically allocate width
            minWidth: 150, // Ensure a minimum width
            renderCell: (params) => (
                <img
                    src={params.row.chargePointOperator.logoUrl}
                    alt="Operator Logo"
                    style={{ width: 100, height: 40, objectFit: 'contain' }}
                />
            ),
        },
        {
            field: "rateCardName",
            headerName: "Rate Card",
            sortable: false,
            width: 140,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "priceKWh",
            headerName: "Price per KW/h",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "left",
            valueFormatter: (params) => formatToGBP(params as number),
        },
        {
            field: "availableSinceDate",
            headerName: "Available Since",
            sortable: false,
            width: 140,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "originalEndDate",
            headerName: "Original End Date",
            sortable: false,
            width: 140,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "status",
            headerName: "Status",
            sortable: false,
            width: 88,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => (
                (params.row.buyer == true && params.row.status === 'COMPLETE') ? (
                    <span>ADOPTED</span>
                    ) :
                    <span>{params.row.status}</span>
            ),
        },
        {
            field: "monthlyCommitmentKWh",
            headerName: "Monthly Commitment kW/h",
            sortable: false,
            width: 160,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params: number) => {
                const value = params;  // Directly use params since it holds the value
                return value !== undefined && value !== null
                    ? `${value.toFixed(2)} kW/h`  // Format to 2 decimal places
                    : "0.00 kW/h";  // Default if value is null or undefined
            }
        },
        {
            field: "commitmentKWh",
            headerName: "Commitment kW/h",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "left",
            valueFormatter: (params: number) => {
                const value = params;  // Directly use params since it holds the value
                return value !== undefined && value !== null
                    ? `${value.toFixed(2)} kW/h`  // Format to 2 decimal places
                    : "0.00 kW/h";  // Default if value is null or undefined
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                params.row.status === "AVAILABLE" ? (
                    <Button variant="contained" color="primary" onClick={() => { adoptTrade(params.row.uuid) }}>
                        Adopt
                    </Button>
                ) : (
                    <></>
                )
            ),
        },

    ];

    return (
        <>
            <div className="table-box">
                <DataGrid
                    rows={trades}
                    autoHeight
                    {...trades}
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 15} } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={false}></DataGrid>
            </div>
        </>
    );
}

export default TradesTable;
