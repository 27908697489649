import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router";

function BusinessLocationsWithChargingTable(props: any) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "addressLine",
      headerName: "Location",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
      renderCell: (cell) => {
        return (
          <span
            className="hyper-link"
            onClick={() =>
              navigate("/share/business/charging-infrastructure/dwellings/charge-points/list", {
                state: {
                  dwellingUuid: cell.row.uuid,
                  fleetDwellingName: cell.row.dwellingName,
                  businessUuid: cell.row.businessUuid,
                },
              })
            }>
            {cell.row.addressLine}
          </span>
        );
      },
    },

    {
      field: "countFastChargers",
      headerName: "Fast Charging Units",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
    },
    


    {
      field: "countRapidChargers",
      headerName: "Rapid Charging Units",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
    },
    // {
    //   field: "priceRapidCharger",
    //   headerName: "Rapid Charging Cost",
    //   sortable: false,
    //   width: 150,
    //   align: "right",
    //   headerAlign: "right",
    //   valueFormatter: (params) => {
    //     // Assuming the price is in a format that can be converted to a number
    //     const price = params;
    //     // Format the price as currency, e.g., '$123.45'
    //     // Adjust 'en-US' and 'USD' to fit the locale and currency you need
    //     return new Intl.NumberFormat('en-UK', {
    //       style: 'currency',
    //       currency: 'GBP',
    //     }).format(price);
    // }
    // },
  ];

  return (
    <div className="table-box">
      <br />
      <DataGrid
        rows={props.data}
        width={"100%"}
        autoHeight
        {...props.data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>No Infrastructure Locations exist.</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Selected filter returns no result
            </Stack>
          ),
        }}
       fontFamily={"Arvo"}></DataGrid>
    </div>
  );
}

export default BusinessLocationsWithChargingTable;
