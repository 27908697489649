import BusinessContext from "Context/BusinessContext";
import AdvancedBookingMap from "components/charging/AdvancedBookingMap/AdvancedBookingMap";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../../Context/UserProvider";
import "./ChargingAdvancedBookingsPage.scss";

function ChargingAdvancedBookingsPage(props: any) {
    const [selectWeekData] = useContext(BusinessContext) as any;
    const [loading, setLoading] = useState(true);
    const [daysLabels, setDayLabels] = useState([]) as any;
    const location = useLocation();
    const { userInfo } = useContext(UserContext);
    const [chargePoints, setChargePoints] = useState([]) as any;
    const [reservationDate, setReservationDate] = useState('');
    const dwellingInfo = location.state as any; // This is the state passed from the Partner component


    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }


    const handleCellClick = (cell: any, chargePointConnectorUuid: string) => {
        return;
    };

    const isCellSelected = (cell: any) => {
        return false;
    };


    async function search(date: string) {
        setLoading(true);

        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;

        bodyData["businessUuid"] = dwellingInfo.businessUuid;
        bodyData["dwellingUuid"] = dwellingInfo.dwellingUuid;
        bodyData["reservationDate"] = date;

        console.log("DWELLING " + JSON.stringify(dwellingInfo));

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/cpo/charge-points/availability/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();


        res_json.chargePoints = checking_null_undefined(res_json.chargePoints);

        setChargePoints(res_json.chargePoints.map((e: any) => ({ ...e, id: e.uuid })));

        console.log("CHARGE POINTS " + JSON.stringify(chargePoints));


        setLoading(false);
    }

    useEffect(() => {
        const initializeData = async () => {
            // Set the current date as the reservation date if it's not already set
            const today = new Date();
            const formattedDate = today.toISOString().substring(0, 10); // Formats the date to yyyy-MM-dd
            if (!reservationDate) {
                setReservationDate(formattedDate);
            }

            search(formattedDate); // Initiate search with the first driver's uuid and current date


            setLoading(false);
        };

        initializeData();
    }, []); // Dependency array is empty, indicating this effect runs only once on mount

    useEffect(() => {
    }, [selectWeekData]);

    return (
        <div className="d-flex flex-column gap-5">
            {loading ? (
                <>Loading ...</>
            ) : (
                <>
                   {
                        chargePoints.length > 0 && chargePoints.map((chargePoint: any, index: number) => (
                            <AdvancedBookingMap
                                key={index} // Assuming chargePoint has no unique identifier; if it does, use that instead
                                data={chargePoint}
                                labels={daysLabels}
                                onCellClick={handleCellClick}
                                isCellSelected={isCellSelected}
                            />
                        ))
                    }
</>
            )}
        </div>
    );
}

export default ChargingAdvancedBookingsPage;
