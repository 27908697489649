import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router";

function VirtualChargingHubsTable(props: any) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "addressLine",
      headerName: "Location",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
      renderCell: (cell) => {
        return (
          <span
            className="hyper-link"
            onClick={() =>
              navigate("/plan/business/location/detail/location-insight", {
                state: {
                  fleetDwellingUuid: cell.row.dwellingUuid,
                  fleetDwellingName: cell.row.dwellingName,
                },
              })
            }>
            {cell.row.addressLine}
          </span>
        );
      },
    },

    {
      field: "countFastChargers",
      headerName: "Fast Charging Units",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "substationName",
      headerName: "Fast Charging Cost",
      sortable: false,
      width: 150,
      align: "left",
      headerAlign: "center",
    },

    {
      field: "countRapidChargers",
      headerName: "Rapid Charging Units",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "substationRange",
      headerName: "Rapid Charging Cost",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "center",
    },

    // {
    //     field: 'type',
    //     headerName: 'Type',
    //     sortable: false,
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 300
    // },
    // {
    //     field: 'owner',
    //     headerName: 'Owner',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100
    // },
    // {
    //     field: 'visitCount',
    //     headerName: 'Visits',
    //     sortable: false,
    //     headerAlign: 'center',
    //     align: 'center',
    //     width: 100
    // },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     sortable: false,
    //     headerAlign: 'center',
    //     align: 'right',
    //     width: 104
    // },
    // {
    //   field: "action",
    //   headerName: "Actions",
    //   sortable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   width: 200,
    // },
  ];

  return (
    <div className="table-box">
      <h1>Virtual Charging Hubs</h1>
      <br />
      <DataGrid
        width={"100%"}
        rows={props.data}
        autoHeight
        {...props.data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>No Infrastructure Locations exist.</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Selected filter returns no result
            </Stack>
          ),
        }}
        // selectionModel={selectionModel}
        // onSelectionModelChange={(e) => {
        //     setSelectionModel(e);
        //     const selectedIDs = new Set(e);
        //     const selectedRowData = locations.filter((row: any) =>
        //         (selectedIDs.has(row.id.toString()))
        //     )

        //     const rowChanges = [...difference(selectedRow, selectedRowData), ...difference(selectedRowData, selectedRow)]
        //     for (let i = 0; i < rowChanges.length; i++) {
        //         toggleDwelling(rowChanges[i].id)
        //     }
        //     setSelectedRow(selectedRowData)
        // }}
        // checkboxSelection={true}
        fontFamily={"Arvo"}></DataGrid>
    </div>
  );
}

export default VirtualChargingHubsTable;
