import { Box, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { GiCancel } from "react-icons/gi";
import { MdDeleteForever } from "react-icons/md";
import UserContext from "../../../../../Context/UserProvider";

function UploadProgressTable(props: any) {
  const [incompleteFiles, setIncompleteFiles] = props.incompleteFiles;
  // const [previousFile, setPreviousFile] = props.previousFile
  const [statusPolling, setStatusPolling] = props.statusPolling;

  const [isError, setIsError] = useState(false);

  function deleteVehicle(iceVehicle: any) {
    let bodyData: { [name: string]: any } = {};
    let uuid = props.userInfo.uuid;
    bodyData["uuid"] = uuid;
    bodyData["fleetUuid"] = props.userInfo.fleetUuid;
    bodyData["iceVehicle"] = iceVehicle;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/icevehicle/delete`;
    try {
      fetch(fetch_link, requestOptions).then((res) =>
        res.json().then((res_obj) => {
          // setPreviousFile((prev: any) => prev.filter((e: any) => e.fileName.substring(0, e.fileName.indexOf('.csv')).toUpperCase() !== iceVehicle.registrationNumber.toUpperCase()))
          setIncompleteFiles((prev: any) => prev.filter((e: any) => e.id !== iceVehicle.id));
        })
      );
    } catch {
    }
  }
  function removeRows(value: any) {
    deleteVehicle(value.row);
    // closeModal()
  }
  const columns: GridColDef[] = [
    {
      field: "originalFilename",
      headerName: "File Name",
      sortable: false,
      editable: true,
      width: 340,
    },
    {
      field: "statusDisplayName",
      headerName: "Status",
      sortable: false,
      editable: true,
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      renderCell: (cellValue) => {
        return (
          <>
            {/* {true ? */}
            {cellValue.row.status === "CATEGORY_NOT_N1" || cellValue.row.status === "VEHICLE_NOT_FOUND" ? (
              <div
                className="action-button"
                onClick={(event) => {
                  removeRows(cellValue);
                }}>
                <MdDeleteForever />
                <span>DELETE</span>
              </div>
            ) : (
              <div
                className="action-button"
                onClick={(event) => {
                  removeRows(cellValue);
                }}>
                <GiCancel />
                <span>CANCEL</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "vehiclesLoaded",
      headerName: "Vehicles Loaded",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (cellValue) => {
        return (
          <>
              <div>
                <span>{cellValue.row.loadedVehicles}/{cellValue.row.totalVehicles}</span>
              </div>
          </>
        );
      },
    },
  ];

  // useEffect(() => {
  //   setStatusPolling(true);
  // }, []);

  return (
    <div className="table-box">
      <DataGrid
        rows={incompleteFiles}
        autoHeight
        {...props.inVehicle}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>Uploads successful, please check the Current Fleet tab</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Selected filter returns no results
            </Stack>
          ),
        }}
        rowsPerPageOptions={[15]}
      />
      {/* <Button onClick={()=>compareVehicle(seletedRow)}>Compare {seletedRow.length} file</Button> */}
    </div>
  );
}

export default UploadProgressTable;
