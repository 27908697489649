import { Backdrop, CircularProgress } from '@mui/material'; // Import Backdrop and CircularProgress from Material UI
import Breadcrumbs from "components/ppa-quote/Breadcrumbs";
import QuotesTable from "components/ppa-quote/QuotesTable";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";


function BuildQuotePageResults(props: any) {
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const location = useLocation() as any;
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [totalMonthlyPublicKWh, setTotalMonthlyPublicKWh] = useState<number>();
    const quickQuote = location?.state?.quickQuote; // Receiving quickQuote from the previous page

    const [quotes, setQuotes] = useState([]) as any


    async function fetchQuotes() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        bodyData["fleetScenarioUuidList"] = location.state.fleetScenarioUuidList;
        bodyData["electricVehicleFleetUuidList"] = location.state.electricVehicleFleetUuidList;
        bodyData["quickQuote"] = quickQuote;

        console.log("FETCH QUOTE REQ " + JSON.stringify(bodyData));

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/ppa/advanced/build/quotes/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        setQuotes(res_json.quotes);
        setTotalMonthlyPublicKWh(res_json.totalMonthlyPublicKWh);

        setLoading(false);
    }

    const acceptQuote = async (chargePointOperatorUuid: string, chargingPartnerRateCardUuid: string, rowId: any) => {
        setIsDisabled(true);

        try {
            let bodyData: { [name: string]: any } = {};
            bodyData["uuid"] = userInfo.uuid;
            bodyData["businessUuid"] = userInfo.businessUuid;
            bodyData["accountUuid"] = userInfo.accountUuid;
            bodyData["chargePointOperatorUuid"] = chargePointOperatorUuid;
            bodyData["chargingPartnerRateCardUuid"] = chargingPartnerRateCardUuid;
            bodyData["fleetScenarioUuidList"] = location.state.fleetScenarioUuidList;
            bodyData["electricVehicleFleetUuidList"] = location.state.electricVehicleFleetUuidList;
            bodyData["totalMonthlyPublicKWh"] = totalMonthlyPublicKWh;
            bodyData["quickQuote"] = quickQuote;

            console.log("ACCEPT QUOTE REQ " + JSON.stringify(bodyData));

            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/ppa/advanced/build/agreement/create`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            console.log("ACCEPT QUOTE RESP" + JSON.stringify(res_json));

            if (response.ok) {
                const updatedData = quotes.map((row: { id: any; }) =>
                    row.id === rowId ? { ...row, workflowState: res_json.workflowState } : row
                );
                setQuotes(updatedData);
                setIsDisabled(false);
                toast.success('Power Purchase Agreement setup Successful', {
                    position: 'bottom-center',
                    duration: 5000,
                });
                navigate("/agree/ppa/list");

            } else {
                setIsDisabled(false);
                throw new Error(res_json.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {
        console.log("use " + JSON.stringify(quickQuote));
        fetchQuotes();
    }, []);

    return (
        <div>
            {/* Breadcrumbs are always displayed */}
            <Breadcrumbs currentStep={"Quotes"} />

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (
                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">
                        <QuotesTable businessChargingCategory={userInfo.businessCategory} quotes={quotes} acceptQuote={acceptQuote} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default BuildQuotePageResults;
