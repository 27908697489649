import BusinessContext from "Context/BusinessContext";
import { useState } from "react";
import { Outlet } from "react-router";

function BusinessContextProvider(props: any) {
  interface WeekData {
    dataOfWeek: string;
    weekNumber: number;
    dwellingUuid: string;
  }
  const [selectWeekData, setSelectWeekData] = useState<WeekData>();
  return (
    <BusinessContext.Provider value={[selectWeekData, setSelectWeekData]}>
      <Outlet />
    </BusinessContext.Provider>
  );
}

export default BusinessContextProvider;
