
import { useNavigate } from 'react-router';
import './PageMenu.scss';


function PageMenu(props: any) {
    const navigate = useNavigate()
    const [activeMenu, setActiveMenu] = props.activeMenu
    return (
        <div className="page-menu">
            <nav className="nav">
                <ul>
                    {props.menu.map((e: any, index: any) => {
                        if ((props.privateChargingNetworkSubscription && e.pcn) || !e.pcn) {
                            console.log()
                            return (
                                <li
                                    key={index}
                                    className={`item ${(activeMenu === e.title) ? "active" : ""}`}
                                    onClick={() => (setActiveMenu(e.title), navigate(e.url, { state: e.state ? e.state : {} }))} >
                                    <span className="material-symbols-outlined icon">{e.icon || "data_thresholding"}</span>
                                    <span id={`menuOption_${e.title.replace(/\s+/g, '')}`}>{e.title}</span>
                                    </li>)
                        } else {
                            return (<li />);
                        }

                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PageMenu;