import { useEffect, useRef, useState } from 'react'

export default function UseInterval(callback: any, delay: any, command: any) {
    let id: any



    const savedCallback = useRef() as any

    // Remember the lastest callback 
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Invoke the the interval 
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }

        if (delay !== null && command) {
            id = setInterval(tick, delay)
            return () => {
                clearInterval(id);
            }
        };
    }, [callback, delay])
}


