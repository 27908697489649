// src/utils/withSuspense.tsx

import React, { Suspense } from 'react';

/**
 * A higher-order component to wrap lazy-loaded components with React.Suspense.
 * @param LazyComponent - A React.lazy-loaded component.
 * @returns A component wrapped in Suspense with a fallback.
 */
const withSuspense = (LazyComponent: React.LazyExoticComponent<any>) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent />
    </Suspense>
  );
};

export default withSuspense;
