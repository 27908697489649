import { createContext, useReducer } from 'react';
import './Notification.scss';
export const NotificationContext = createContext({} as any);
const NotificationProvider = (props: any) => {
    const initialState = [[], [], [0]]

    const notificationReducer = (state: any, action: any) => {
        switch (action.type) {
            case 'ADD_NOTIFICATION':
                // setNotifications((prev: any) => [{ message: 'hello' }, ...prev])
                return [[{ ...action.payload }, ...state[0]], [{ ...action.payload }, ...state[1]], [state[2][0] + 1]]
            case 'REMOVE_NOTIFICATION':
                return [state[0].filter((noti: any) => noti.id !== action.id), state[1], state[2]]
            case 'REMOVE_NOTIFICATION_COUNT':
                return [state[0], state[1], [0]]
            default:
                return state
        }
    };
    const [state, dispatch] = useReducer(notificationReducer, initialState)
    return (
        <NotificationContext.Provider value={{ dispatch: dispatch, state: state }}>
            {/* <div className={'notification-wrapper'}>
                {state[0]?.map((note: any) => {
                    return <Notification dispatch={dispatch} key={note.id} {...note} />
                })}
            </div> */}
            {props.children}
        </NotificationContext.Provider>
    )
}
export default NotificationProvider;
