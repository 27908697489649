import ChargePointListTable from "components/charging/ChargePointList/ChargePointListTable";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

function isNull(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function LocationChargePointsListPage(props: any) {
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [chargePoints, setChargePoints] = useState([]) as any;
   const [showCategory, setShowCategory] = useState([true, false, false, false]);
    const [activeMenu, setActiveMenu] = useState("All");
    const location = useLocation() as any;
    const dwellingUuid = location.state.dwellingUuid;


    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }


    async function fetchChargePoints() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["dwellingUuid"] = dwellingUuid;

        console.log(userInfo.uuid);
        console.log(userInfo.businessUuid);
        console.log(dwellingUuid);

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/cpo/charge-points/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();


        res_json.chargePoints = checking_null_undefined(res_json.chargePoints);

        res_json.virtualChargingHubs = checking_null_undefined(res_json.virtualChargingHubs);
        res_json.homeLocations = checking_null_undefined(res_json.homeLocations);
        res_json.opportunityLocations = checking_null_undefined(res_json.opportunityLocations);
        res_json.companyDwellings = isNull(res_json.companyDwellings);

        setChargePoints(res_json.chargePoints.map((e: any) => ({ ...e, id: e.uuid })));
        setLoading(false);
    }


    useEffect(() => {
        console.log("DWE " + dwellingUuid);
        fetchChargePoints();
    }, []);

    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                    onClick={() => (setShowCategory([true, false, false, false, false, false]), setActiveMenu("All"))}>
                    All
                </button>
                <div className="vl"></div>
            </div>
            {showCategory[0] && (
                <>
                    { }
                    {/*  */}
                    {chargePoints.map((chargePoint: any, index: any) => (
                        <ChargePointListTable key={index} chargePointData={chargePoint} />
                        // <ChargePointListTable2 dwellingUuid={dwellingUuid} selectedChargers={chargePoints}></ChargePointListTable2>
                    ))}

                </>
            )}

        </div>
    );
}

export default LocationChargePointsListPage;
