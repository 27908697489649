import { Button, DialogActions, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import ModalComponent from "components/common/Modal/Modal";
import { useEffect, useState } from "react";
import BusinessInfoDropList from "./BusinessInfoDropList";
import "./SelectedFleetModal.css";

const StyledDialogTitle = styled(DialogTitle)({
    backgroundColor: 'black',
    color: 'white',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',  // Vertical centering
    padding: '0 16px',  // Ensure padding inside the title
});


function checking_null_undefined(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function SelectedFleetModal(props: any) {
    const [modalIsOpen, setIsOpen] = props.modalIsOpen;
    const [userInfo, setUserInfo] = props.userInfo;
    const [isError, setIsError] = useState(false);
    const [businessInfo, setBusinessInfo] = useState([]) as any;
    const [loading, setLoading] = useState(true);

    function closeModal() {
        setIsOpen(false);
    }
    async function fetchBusinessInfo() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/business/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();
        setBusinessInfo(checking_null_undefined(res_json.businesses));
        setLoading(false);
        console.log(JSON.stringify(res_json.businesses));
    }


    useEffect(() => {
        fetchBusinessInfo();
    }, []);
    return (
        <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
            {

                <div style={{ overflow: "hidden"}}>
                    <div >
                        <StyledDialogTitle>Choose Fleet</StyledDialogTitle>
                        {loading ? (
                            <>Loading...</>
                        ) : (
                            <div className="mid-modal-content">
                                {businessInfo.map((item: any, index: any) => {
                                    return (
                                        <BusinessInfoDropList
                                            closeModal={closeModal}
                                            userInfo={props.userInfo}
                                            key={index}
                                            title={item.name}
                                            businessUuid={item.uuid}
                                            chargingCategory={item.chargingCategory}
                                            businessProducts={item.products}
                                            list={checking_null_undefined(item.fleets)}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        <DialogActions>
                            <Button onClick={closeModal} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                        {/* <button onClick={() => goHome()} className='primary-btn' >Home</button> */}
                    </div>
                </div>
            }
        </ModalComponent>
    );
}

export default SelectedFleetModal;
