import { useNavigate } from "react-router";

function Scenarios(props: any) {

    const dashboardInfo = props.dashboardInfo;

    const navigate = useNavigate();

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">format_list_numbered_rtl</span>
                    Scenarios
                </div>
                <div className="dashboard-card-content">
                    {dashboardInfo.lastRunScenario && (
                        <div className="box-info">
                            <span className="item">Last Run Scenario :</span>
                            <span className="data">
                                {dashboardInfo.lastRunScenario.fleetName} / Scenario {dashboardInfo.lastRunScenario.fleetScenarioNumber}
                            </span>
                        </div>
                    )}

                    <div className="box-info">
                        <span className="item">Latest Draft Scenario :</span>
                        <span className="data">
                            {dashboardInfo.lastDraftScenario?.fleetName} / Scenario {dashboardInfo.lastDraftScenario?.fleetScenarioNumber}
                        </span>
                    </div>
                </div>
                <div className="dashboard-card-actions">
                    {dashboardInfo.lastDraftScenario ? (
                        <div
                            className="icon-and-text"
                            onClick={() => {
                                navigate("/plan/scenario/configuration", {
                                    state: {
                                        fleetScenarioUuid: dashboardInfo.lastDraftScenario?.uuid,
                                    },
                                });
                            }}>
                            <span className="icon material-symbols-outlined">settings</span>
                            Configure
                        </div>
                    ) : null}
                    <div
                        className="icon-and-text has-left-border"
                        onClick={() => navigate("/plan/insight/fleet")}>
                        <span className="icon material-symbols-outlined">view_list</span>
                        View All Scenarios
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Scenarios;
