export const PLAN = "PLAN";
export const SHARE = "SHARE";
export const AGREE = "AGREE";
export const CHARGE = "CHARGE";

export const isMultiProduct = (products: string[]): boolean => {
    return products?.length > 1;
};

export const isPlanProduct = (product: string): boolean => {
    return PLAN === product;
};

export const isShareProduct = (product: string): boolean => {
    return SHARE === product;
};

export const isAgreeProduct = (product: string): boolean => {
    return AGREE === product;
};

export const isChargeProduct = (product: string): boolean => {
    return CHARGE === product;
};

export const displayProductButton = (product: string, businessProducts: string[]): boolean => {

    return businessProducts.includes(product);

    return false;
}

