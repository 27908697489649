import BalanceGraph from "components/charging/TakeOrPay/BalanceGraph";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function PPADetailPage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const location = useLocation();
    const params = location.state as any;
    const [agreement, setAgreement] = useState([]) as any

    useEffect(() => {
        fetchPPA();

    }, []);

    async function fetchPPA() {
        setLoading(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["ppaUuid"] = params.ppaUuid;

        console.log("FETCH PPA " + JSON.stringify(bodyData));

        let fetch_link = `${rootUrl}/ppa/advanced/agreement/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();

        setAgreement(res_json.agreement);
        setLoading(false);

    }

    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (


                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-6 col-xl-6">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Power Purchase Agreement                              </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Charge Point Operator :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                {agreement.chargePointOperator.name}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Product :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                {agreement.rateCardName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Price per kW/h :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                £{agreement.ppaPricePerKWh.toFixed(2)}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Start Date :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                {agreement.startDate}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            End Date :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                {agreement.endDate}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Days Remaining :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                {agreement.daysRemaining} days
                                            </span>
                                        </div>
                                    </div>                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Monthly kW/h commitment :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                {agreement.monthlyCommitmentInKWh.toFixed(2)} kW/h
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            Monthly Purchase commitment :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                £{agreement.monthlyCommitmentInGbp?.toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-6">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Provider
                                </div>
                                <div className="box-info">
                                    <BalanceGraph data={agreement.statements} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

            }
        </>
    );
}

export default PPADetailPage;