import UserContext from "Context/UserProvider";
import { useContext, useState } from "react";
import { displayProductButton } from "utils/productUtils";

import "../Nav.scss";

interface ProductSelectorProps {

    businessProducts: string[];
    product: string;
    title: string;
    icon: string;

}

const ProductSelector: React.FC<ProductSelectorProps> = ({ businessProducts, product, title, icon }) => {
    const [isError] = useState(false);

    const { userInfo, selectedProduct, setSelectedProduct, theme } = useContext(UserContext);

    return (

        <li className="item">
            {displayProductButton(product, businessProducts) && (
                <a
                    onClick={() => {

                        setSelectedProduct(product);

                    }}>
                    <span className="material-symbols-outlined icon">{icon}</span>

                    <span
                        className="title"
                        id="nav-drop-item">
                        {title}
                    </span>
                </a>
            )}
        </li>
    );
}
export default ProductSelector;