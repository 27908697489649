import React from "react";
import { useLocation } from "react-router-dom";
import { WeekDay } from "../../charging/BusinessChargingNetworkParameters/weekDays";
import { RateCardOption, RateCardValue } from "../BusinessChargingNetworkParameters/rateCards";

import { ChargingPartner } from "../BusinessChargingNetworkParameters/chargingPartner";
import DriverInstructionsPanel from "./DriverInstructionsPanel";
import EnergyCostPanel from "./EnergyCostPanel";
import PartnershipContractualDocumentsPanel from "./PartnershipContractualDocumentsPanel";
interface ChargingPartnerParams {
    chargingPartnerBusinessUuid: string;
    chargingPartnerBusinessName: string;
    mode: 'VIEW' | 'EDIT';  // Assuming mode has specific string values
}

interface DetailsPanelProps {
    onChange: (e: any) => void;
    weekDays: WeekDay[];
    handleTimeChange: (dayIndex: number, field: string, value: string) => void;
    rateCardOptions: RateCardOption[];
    rateCardValue: RateCardValue;
    updateChargingPartner: () => void;
    isDisabled: boolean;
    params: ChargingPartnerParams;
    chargingPartner: ChargingPartner;
}

const DetailsPanel: React.FC<DetailsPanelProps> = ({ onChange, weekDays, handleTimeChange, rateCardOptions, rateCardValue, updateChargingPartner, isDisabled, params, chargingPartner }) => {

    const location = useLocation();

    return (
        <>
        {chargingPartner.workflowState === "POTENTIAL" ? (
            <div>No partnership has been established</div>
        ) : (

        <div className="container-fluid g-0">
            <div className="row gy-2">


                <div className="col-xs-12 col-md-6 col-xl-6">

                    <EnergyCostPanel panelTitle={`Charging Cost at ${params.chargingPartnerBusinessName} locations per kWh (Off-Peak)`}
                        singlePhase={chargingPartner.energyCostAcSinglePhaseGbpPerKWhOffPeak}
                        threePhase={chargingPartner.energyCostAcThreePhaseGbpPerKWhOffPeak}
                        dc={chargingPartner.energyCostDcGbpPerKWhPeak} />

                </div>
                <div className="col-xs-12 col-md-6 col-xl-6">

                    <EnergyCostPanel panelTitle={`Charging Cost at ${params.chargingPartnerBusinessName} locations per kWh (Peak)`}
                        singlePhase={chargingPartner.energyCostAcSinglePhaseGbpPerKWhPeak}
                        threePhase={chargingPartner.energyCostAcThreePhaseGbpPerKWhPeak}
                        dc={chargingPartner.energyCostDcGbpPerKWhOffPeak} />

                </div>
                <div className="col-xs-12">
                    <PartnershipContractualDocumentsPanel chargingPartnerOffer={chargingPartner} panelTitle=" Partnership / Contractual Documents" type="DOCUMENT" />
                </div>

                <div className="col-xs-12">
                    <PartnershipContractualDocumentsPanel chargingPartnerOffer={chargingPartner} panelTitle="Instructional Videos" type="VIDEO" />
                </div>

                <div className="col-xs-12">
                    <DriverInstructionsPanel chargingPartnerOffer={chargingPartner} />
                </div>

            </div>
        </div>
        )}
        </>
    )
}
export default DetailsPanel;